import React, { useEffect, useState } from 'react'
import { Typography, Grid, Box, Slider } from '@mui/material';
import CustomLightButton from '../components/CustomButton/CustomButton';
import Header from '../components/Header/Header';
import CustomChip from '../components/CustomChip';
import CustomInfoBox from '../components/CustomInfoBox/CustomInfoBox';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetVariantModelById } from '../shared/query-hooks/VariantModelService';
import CustomModelViewer from '../components/CustomModelViewer';
import { ModelViewerConfig } from '../shared/interfaces/VariantModel';
import LensList from './Lens/LensList';
import ModelViewerConfigForm from '../components/forms/ModelViewerConfigForm/ModelViewerConfigForm';
import { useGetEnvironmentImageById } from '../shared/query-hooks/EnvironmentImageService';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UpdateIcon from '@mui/icons-material/Update';
import LocalOfferOutlinedIcon from '@mui/icons-material/LocalOfferOutlined';
import moment from "moment";

function ViewVariantModel() {
  let navigate = useNavigate();
  let params = useParams();

  const { data: variantModel, status: variantModelStatus } = useGetVariantModelById(params.variantModelId!);

  const [variantModelId, setVariantModelId] = useState("")
  const [variantModelName, setVariantModelName] = useState("")
  const [baseModelId, setBaseModelId] = useState("")
  const [baseModelName, setBaseModelName] = useState("")
  const [brandId, setBrandId] = useState("")
  const [brandName, setBrandName] = useState("")
  const [hdrFile, setHdrFile] = useState("")
  const [updateDate, setUpdateDate] = useState("")
  const [glbFile, setGlbFile] = useState("")
  const [usdzFile, setUsdzFile] = useState("")
  const [tags, setTags] = useState([""])
  const [modelViewerConfig, setModelViewerConfig] = useState<ModelViewerConfig>()
  const [isTrending, setIsTrending] = useState(false)

  const [exposure, setExposure] = useState (0);
  const [shadowIntensity, setShadowIntensity] = useState(0);
  const [shadowSoftness, setShadowSoftness] = useState(0);
  const [envImgId, setEnvImgId] = useState("");

  const {data} = useGetEnvironmentImageById(envImgId)

  useEffect(() => {
    if (variantModelStatus === "success") {
      setVariantModelId(variantModel.data[0]._id)
      setVariantModelName(variantModel.data[0].variant_model_name)
      setBaseModelId(variantModel.data[0].base_model_detail[0]._id)
      setBaseModelName(variantModel.data[0].base_model_detail[0].model_name)
      setBrandId(variantModel.data[0].brand_detail[0]._id)
      setBrandName(variantModel.data[0].brand_detail[0].brand_name)
      if (variantModel.data[0].environment_detail.length > 0) {
        setHdrFile(variantModel.data[0].environment_detail[0].hdr_file[0].url)
      }
      setUpdateDate(variantModel.data[0].updated_at)
      setGlbFile(variantModel.data[0].glb_file[0].url)
      setUsdzFile(variantModel.data[0].usdz_file[0].url)
      setTags(variantModel.data[0].tags)
      setModelViewerConfig(variantModel.data[0].model_viewer_config[0])
      setIsTrending(variantModel.data[0].is_trending)

      setExposure(parseFloat(variantModel.data[0].model_viewer_config[0].exposure!));
      setShadowIntensity(parseFloat(variantModel.data[0].model_viewer_config[0]['shadow-intensity']!))
      setShadowSoftness(parseFloat(variantModel.data[0].model_viewer_config[0]['shadow-softness']!))
      setEnvImgId(variantModel.data[0].environment_detail[0]._id);
    }
  }, [variantModelStatus])

  useEffect(() => {
    console.log(envImgId)
  }, [envImgId])
  

  const handleEditItemClick = () => {
    navigate(`/variant-models/${params.variantModelId}/edit`)
  }

  const handleBaseModelClick = () => {
    navigate(`/base-models/${baseModelId}/`)
  }

  function trendingChipCreator() {
    if (isTrending) {
      return (<CustomChip title={"TRENDING"} isFilled={false} />)
    }
  }

  return (
    <>
      <Grid container direction={"column"}>
        <Grid item>
          <Header header={brandName} itemModel={baseModelName} variantModelName={variantModelName} status={variantModel?.data[0].variant_model_status} />
        </Grid>
        <Grid item container>
          <Grid item xs={1}></Grid>
          <Grid item container direction={"column"} xs={10} py={8}>
            <Grid item container direction={"row"}>
                <Grid item container width={"75%"} direction="column">
                      <Grid item width={"100%"} height={"70vh"}>
                      {
                        variantModelStatus === "success" ?
                          <CustomModelViewer
                            selectedVariantModel={variantModel.data[0]}
                            exposure={exposure}
                            shadowIntensity={shadowIntensity}
                            shadowSoftness={shadowSoftness}
                            hdr={data?.data[0].hdr_file[0].url}
                            
                          /> : null
                      }
                      </Grid>
                      <Grid item container direction={'row'} spacing={1}>
                        <Grid item> <CustomLightButton> SHARE </CustomLightButton> </Grid>
                        <Grid item> <CustomLightButton> EMBED </CustomLightButton> </Grid>
                        <Grid item> <CustomLightButton> QR CODE </CustomLightButton> </Grid>
                      </Grid>
                      <Grid item container py={3} direction={"row"} alignItems={"center"} spacing={2}>
                        <Grid item display={"flex"} alignItems="center">
                          <UpdateIcon/>
                        </Grid>
                        <Grid item>
                          <Typography variant='customBody'>Updated at {moment(updateDate).fromNow()}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item container direction={"row"} alignItems={"center"} spacing={2}>
                        <Grid item display={"flex"} alignItems="center">
                          <LocalOfferOutlinedIcon/>
                        </Grid>
                        <Grid item>
                          {tags?.map((tag, index) => (
                            <CustomChip title={tag} key={index} isFilled={true} color='#FFFFFF' bgColor='#000000'/>
                          ))}
                        </Grid>
                      </Grid>

                </Grid>
                <Grid item container direction={"column"} width={"25%"} alignItems={"center"} spacing={2}>
                  <Grid item> <CustomLightButton onClick={handleEditItemClick}> EDIT MODEL </CustomLightButton> </Grid>
                  <Grid item> <CustomLightButton onClick={handleBaseModelClick}> SEE BASE MODEL </CustomLightButton> </Grid>
                  <Grid item>
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography variant='customBodyStrongCondensed'>Modelviewer Config</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                      <ModelViewerConfigForm 
                      envImgId={envImgId} 
                      setEnvImgId={setEnvImgId} 
                      exposure={exposure} 
                      setExposure={setExposure} 
                      shadowIntensity={shadowIntensity} 
                      setShadowIntensity={setShadowIntensity} 
                      shadowSoftness={shadowSoftness} 
                      setShadowSoftness={setShadowSoftness}
                      baseModelId={variantModel?.data[0].base_model_detail[0]._id!}
                      variantModelId={variantModel?.data[0]._id!}
                      />
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
            </Grid>
            <Grid item container direction={'column'} py={4}>
              <Grid item> <Typography variant='customH3'> Variant's lenses </Typography> </Grid>
              <LensList variantModelId={params.variantModelId} />
            </Grid>
          </Grid>
        
      </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
        

      {/* <Grid container marginTop={'84px'} marginBottom={'75px'}>
        <Grid item xs={1.5} />

        <Grid item container direction={'column'} xs={9} spacing={'128px'}>
          <Grid item container direction={'row'}>
            <Grid item container xs={8.8} direction={'column'} spacing={'24px'}>

              <Grid item width={"100%"} height={"100%"}>
                {
                  variantModelStatus === "success" ?
                    <CustomModelViewer
                      // glbUrl={glbFile}
                      // usdzUrl={usdzFile}
                      // hdrUrl={hdrFile}
                      // shadowIntensity={modelViewerConfig?.['shadow-intensity']}
                      // shadowSoftness={modelViewerConfig?.['shadow-softness']}
                      // exposure={modelViewerConfig?.exposure}
                      // autoRotate={"1"}
                      // directAR={"1"}
                      // style="width:100%;height:calc(80vh - 4rem)"
                      selectedVariantModel={variantModel.data[0]}
                    /> : null
                }
              </Grid>

              <Grid item container direction={'row'} spacing={'8px'}>
                <Grid item> <CustomLightButton> SHARE </CustomLightButton> </Grid>
                <Grid item> <CustomLightButton> EMBED </CustomLightButton> </Grid>
                <Grid item> <CustomLightButton> QR CODE </CustomLightButton> </Grid>
              </Grid>
              <Grid item> <Typography variant='customBody'> Updated {updateDate} </Typography> </Grid>
              <Grid item container spacing={'8px'}>
                {
                  tags.map(
                    (tag) => (<Grid item key={tag}> <CustomChip title={tag} color='#FFFFFF' bgColor='#000000' isFilled={true} /> </Grid>)
                  )
                }
                <Grid item></Grid>
              </Grid>
              <Grid item> <CustomLightButton> DOWNLOAD ALL RESOURCES </CustomLightButton> </Grid>
            </Grid>

            <Grid item xs={1} />

            <Grid item container xs={2.2} direction={'column'} spacing={'24px'}>
              <Grid item> <CustomLightButton fullWidth onClick={handleEditItemClick}> EDIT MODEL </CustomLightButton> </Grid>
              <Grid item> <CustomLightButton fullWidth onClick={handleBaseModelClick}> SEE BASE MODEL </CustomLightButton> </Grid>
              <Grid item> <Typography variant='customBodyStrong'> OTHER VARIANTS </Typography> </Grid>
              <Grid item> <ProductBox /> </Grid>
                <Grid item> <ProductBox /> </Grid>
              <Grid item> <CustomLightButton fullWidth> SEE ALL </CustomLightButton> </Grid>
            </Grid>
          </Grid>

          <Grid item container direction={'column'}>
            <Grid item> <Typography variant='customH3'> Metrics </Typography> </Grid>
            <Grid item> <Typography variant='customBody'> General </Typography> </Grid>
            <Grid item container direction='row' justifyContent={'space-between'}>
              <Grid item> <CustomInfoBox data='5.6s' label='MODEL LOAD COUNT' /> </Grid>
              <Grid item> <CustomInfoBox data='213' label='BUY BUTTON CLICKS' /> </Grid>
              <Grid item> <CustomInfoBox data='134' label='SHARE COUNT' /> </Grid>
              <Grid item> <CustomInfoBox data='6.7%' label='CONVERSION RATE' /> </Grid>
              <Grid item> <CustomInfoBox data='20.6 s' label='MODEL AVG INTERACTION TIME' /> </Grid>
              <Grid item> <CustomInfoBox data='1.8 s' label='TIME TO FIRST INTERACTION' /> </Grid>
            </Grid>
          </Grid>

          <Grid item container direction={'column'}>
            <Grid item> <Typography variant='customH3'> Variant's lenses </Typography> </Grid>
            <LensList variantModelId={params.variantModelId} />
          </Grid>
        </Grid>

        <Grid item xs={1.5} />
      </Grid> */}
    </>
  )
}

export default ViewVariantModel;
