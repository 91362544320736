import * as React from 'react';
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import BrandingWatermarkIcon from '@mui/icons-material/BrandingWatermark';
import LogoutIcon from '@mui/icons-material/Logout';
import LocalSeeIcon from '@mui/icons-material/LocalSee';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAuth } from 'qreal-auth-provider/src';
import { authKeys, checkAuth } from '../../enums/authKeys';

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color:
            theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity,
                ),
            },
        },
    },
}));

export default function MenuList() {
    const { authList, onLogout } = useAuth();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (url?: string) => {
        if (url) window.location.href = url;
        else setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                Menu
            </Button>
            <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={() => { handleClose() }}
            >
                {checkAuth(authList, authKeys.create_model) &&
                    <MenuItem onClick={() => { handleClose('/create-model') }} disableRipple>
                        <AddIcon />
                        Add Item
                    </MenuItem>}
                {checkAuth(authList, authKeys.create_brand) &&
                    <MenuItem onClick={() => { handleClose('/create-brand') }} disableRipple>
                        <BrandingWatermarkIcon />
                        Add Brand
                    </MenuItem>}
                {checkAuth(authList, authKeys.push_notification) &&
                    <MenuItem onClick={() => { handleClose('/push-notification-system') }} disableRipple>
                        <CircleNotificationsIcon />
                        Push Notification
                    </MenuItem>}
                {checkAuth(authList, authKeys.list_lens) &&
                    <MenuItem onClick={() => { handleClose('/lens-list') }} disableRipple>
                        <LocalSeeIcon />
                        Lens List
                    </MenuItem>}
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={() => { onLogout() }} disableRipple>
                    <LogoutIcon />
                    Log Out
                </MenuItem>
            </StyledMenu>
        </div>
    );
}