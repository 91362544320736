
export enum QueryStatuses {
    LOADING = 'loading',
    SUCCESS = 'success',
    ERROR = 'error',
}

export enum ProductStatuses {
    PASSIVE = 'PASSIVE',
    ACTIVE = 'ACTIVE',
}