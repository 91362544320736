import { ModelData, ModelUploadData } from '../shared/interfaces/Model';
import { useCreateModel } from '../shared/query-hooks/ModelService'
import { Option } from '../shared/interfaces/Enums';
import BaseModelTab from '../components/BaseModelTab/BaseModelTab';
import { useUploadFile, useUploadMultipleFiles } from '../shared/query-hooks/FileUploadService';
import { CopyFileData, FileUploadData, MultipleFilesUploadData } from '../shared/interfaces/FileModel';
import { useGetAllBrands } from '../shared/query-hooks/BrandService';
import React, { useEffect, useState } from 'react';
import Header from '../components/Header/Header';
import CustomLightButton from '../components/CustomButton/CustomButton';
import {
    Box,
    Dialog,
    Grid,
    Tab,
    Tabs,
    TextField,
    Typography,
    CircularProgress,
    Autocomplete,
    Stack
} from '@mui/material';
import VariantTab from '../components/VariantTab';
import { ModelViewerConfig, ProductLink, VariantModelData, VariantModelUploadData } from '../shared/interfaces/VariantModel';
import { useCreateSovrnLink, useCreateVariantModel } from '../shared/query-hooks/VariantModelService';
import CustomDropZone from '../components/CustomDropZone/CustomDropZone';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ImportFromMopuModal from "../components/Widgets/ImportFromMopuModal/ImportFromMopuModal";
import { useCopyFile } from "../shared/query-hooks/CopyFileService";
import { MOPUModel } from "../shared/interfaces/MOPUModel";
import { MOPU_BUCKET_NAME } from "../shared/constants";
import { isDevelopment } from '../enums/app';

export default function CreateModel() {
    const defaultMopuModelData: MOPUModel = {
        _id: "",
        model_name: "string",
        model_slug: "string",
        brand_id: "string",
        category: "",
        model_status: "",
        tags: [],
        model_viewer_config: {},
        reference_link: "",
        reference_file: [],
        glb_file: [],
        hdr_file: [],
        usdz_file: [],
        poster_file: []
    }

    const [isMopuModalOpen, setIsMopuModalOpen] = useState(false);
    const [isModelUploadedFromMopu, setIsModelUploadedFromMopu] = useState(false);
    const handleMopuModalOpen = () => setIsMopuModalOpen(true);
    const handleMopuModalClose = () => setIsMopuModalOpen(false);
    const [openCreateModelDialog, setOpenCreateModelDialog] = useState(false);
    const [MOPUModelData, setMOPUModelData] = useState<MOPUModel>(defaultMopuModelData)
    const [tabValue, setTabValue] = useState(1);

    const handleOpenCreateModelDialog = () => {
        setOpenCreateModelDialog(true);
    };

    const handleCloseCreateModelDialog = () => {
        setModelName("");
        setSelectedCategory({ value: "", label: "" });
        setSelectedModelStatus("")
        setTags([])
        setSelectedBrand("")
        setBaseModelId("");
        setIsTrending(false);
        setMetadata([]);
        setProductLinks([]);
        setFacebookLink("")
        setInstagramLink("")
        setSparkProjectLink("")
        setSnapchatLink("")
        setMvDemoLink("")
        setThumbnailFile(undefined);
        setReferenceImages(undefined)
        setCompletedCGModelFile(undefined)
        setSubstancePainterFile(undefined)
        setSourceMaskTextureFile(undefined)
        setPhotogrammetryFile(undefined)
        setUSDZFile(undefined)
        setGLBFile(undefined)
        setOpenCreateModelDialog(false);
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    //model & variant-model states
    const [modelName, setModelName] = useState("")
    const [selectedCategory, setSelectedCategory] = useState<Option>({ value: "", label: "" });
    const [selectedModelStatus, setSelectedModelStatus] = useState("PASSIVE");
    const [tags, setTags] = useState<string[]>([]);
    const [selectedBrand, setSelectedBrand] = useState("");
    const [isVariantDropzoneDisabled, setIsVariantDropzoneDisabled] = useState(false);
    const [isSticky, setIsSticky] = useState(false);

    //brand-select
    const [brands, setBrands] = useState<Option[]>([]);

    //variant-model states
    const [baseModelId, setBaseModelId] = useState("");
    const [isTrending, setIsTrending] = useState(false);
    const [metadata, setMetadata] = useState<string[]>([]);
    const [wannaModelId, setWannaModelId] = useState("");
    const [productLinks, setProductLinks] = useState<ProductLink[]>([{ name: "", url: "" }]);
    const [facebookLink, setFacebookLink] = useState("");
    const [instagramLink, setInstagramLink] = useState("");
    const [sparkProjectLink, setSparkProjectLink] = useState("");
    const [snapchatLink, setSnapchatLink] = useState("");
    const [mvDemoLink, setMvDemoLink] = useState("");
    const [modelViewerConfig, setModelViewerConfig] = useState<ModelViewerConfig[]>([{ exposure: "1", "shadow-intensity": "1", "shadow-softness": "1" }]);
    const [thumbnailFile, setThumbnailFile] = useState();
    const [referenceImages, setReferenceImages] = useState();
    const [completedCGModelFile, setCompletedCGModelFile] = useState(); //.blend
    const [substancePainterFile, setSubstancePainterFile] = useState(); //.spp
    const [sourceMaskTextureFile, setSourceMaskTextureFile] = useState(); //.png
    const [photogrammetryFile, setPhotogrammetryFile] = useState(); //.fbx
    const [USDZFile, setUSDZFile] = useState();
    const [GLBFile, setGLBFile] = useState();
    const [envImgId, setEnvImgId] = useState("")
    const [mvExposureConfig, setMvExposureConfig] = useState<number | number[]>(1)
    const [mvIntensityConfig, setMvIntensityConfig] = useState<number | number[]>(1)
    const [mvSoftnessConfig, setMvSoftnessConfig] = useState<number | number[]>(1)
    const [description, setDescription] = useState("");
    const [price, setPrice] = useState(0);
    const [posterDropzoneDisabled, setPosterDropzoneDisabled] = useState(false);

    //sovrn link generate checkbox
    const [url, setUrl] = useState("")
    const [generateSovrnLink, setGenerateSovrnLink] = useState<boolean>(true);
    //generate sovrn link
    const { data: sovrnLinkData, status: sovrnLinkGenerateStatus, refetch: sovrnLinkGenerate } = useCreateSovrnLink(url);

    const [importMopuSwitch, setImportMopuSwitch] = useState<boolean>(false)
    useEffect(() => {
        setMvExposureConfig(parseFloat(modelViewerConfig[0].exposure))
        setMvIntensityConfig(parseFloat(modelViewerConfig[0]['shadow-intensity']))
        setMvSoftnessConfig(parseFloat(modelViewerConfig[0]['shadow-softness']))
    }, [importMopuSwitch]);

    const {
        mutate: createVariantModelMutate,
        data: variantModelResponseData,
        status: createVariantModelStatus
    } = useCreateVariantModel();

    const { mutate: createModelMutate, data: modelResponseData, status: createModelStatus } = useCreateModel();
    const { mutate: thumbnailFileUploadMutate, status: thumbnailFileUploadStatus } = useUploadFile();
    const { mutate: refImageUploadMutate, status: refImageUploadStatus } = useUploadMultipleFiles();
    const { mutate: completedCGModelFileUploadMutate, status: completedCGModelFileUploadStatus } = useUploadFile();
    const { mutate: substancePainterFileUploadMutate, status: substancePainterFileUploadStatus } = useUploadFile();
    const { mutate: sourceMaskTextureFileUploadMutate, status: sourceMaskTextureFileUploadStatus } = useUploadMultipleFiles();
    const { mutate: photogrammetryFileUploadMutate, status: photogrammetryFileUploadStatus } = useUploadFile();
    const { mutate: USDZUploadMutate, status: USDZUploadStatus } = useUploadFile();
    const { mutate: GLBUploadMutate, status: GLBUploadStatus } = useUploadFile();
    const { mutate: copyGLBFileMutate, status: copyGLBFileStatus } = useCopyFile();
    const { mutate: copyUSDZFileMutate, status: copyUSDZFileStatus } = useCopyFile();
    const { mutate: copyReferenceFileMutate, status: copyReferenceFileStatus } = useCopyFile();
    const { mutate: copyPosterFileMutate, status: copyPosterFileStatus } = useCopyFile();


    //brand-select
    const { data: allBrandData, status: allBrandDataStatus, isSuccess: allBrandDataIsSuccess } = useGetAllBrands();
    useEffect(() => {
        if (allBrandDataStatus === "success") {
            const tempArray: Option[] = []
            allBrandData?.data.forEach((brand, index) => {
                tempArray[index] = { value: brand._id, label: brand.brand_name }
            });
            setBrands(tempArray);
        }
    }, [allBrandDataStatus])


    const handleModelCreate = () => {
        const modelData: ModelUploadData = {
            _id: '',
            model_name: modelName,
            brand_id: selectedBrand,
            category: selectedCategory.value,
            model_status: selectedModelStatus,
            tags: tags,
            thumbnail_file: [],
            photogrammetry_file: [],
            completed_cg_model_file: [],
            substance_painter_file: [],
            source_mask_textures: [],
            reference_images: [],
            is_sticky: isSticky
        };
        createModelMutate(modelData);
        handleOpenCreateModelDialog();
    }

    const handleVariantModelCreate = () => {
        const variantModelData: VariantModelUploadData = {
            _id: '',
            variant_model_name: modelName,
            base_model_id: baseModelId,
            brand_id: selectedBrand,
            category: selectedCategory.value,
            variant_model_status: selectedModelStatus,
            is_trending: isTrending,
            is_checked: false,
            tags: tags,
            metadata: metadata,
            facebook_link: facebookLink,
            instagram_link: instagramLink,
            snapchat_link: snapchatLink,
            spark_project_link: sparkProjectLink,
            mv_demo_link: mvDemoLink,
            product_links: productLinks,
            model_viewer_config: modelViewerConfig,
            environment_id: envImgId,
            wanna_model: wannaModelId,
            description: description,
            price: price
        }
        console.log(variantModelData)
        sovrnLinkGenerate().then(() => {
            if (sovrnLinkGenerateStatus === "success") {

            } else {

            }
        })
        createVariantModelMutate(variantModelData);
        handleOpenCreateModelDialog();
    }

    useEffect(() => {
        if (sovrnLinkGenerateStatus === "success") {
            setProductLinks([...productLinks, { name: "sovrnn", url: sovrnLinkData.data[0].anywhereUrl }])
        }
    }, [sovrnLinkGenerateStatus])

    // Upload Base Model Files
    useEffect(() => {
        if (modelResponseData?.data.data[0].reference_images[0].pre_signed_url) {
            if (referenceImages) {
                const imageUploadData: MultipleFilesUploadData = {
                    files: referenceImages,
                    urls: modelResponseData?.data.data[0].reference_images,
                    content_type: "image/jpeg"
                }
                refImageUploadMutate(imageUploadData)
            }
            if (thumbnailFile) {
                const thumbnailUploadData: FileUploadData = {
                    file: thumbnailFile[0],
                    url: modelResponseData?.data.data[0].thumbnail_file[0].pre_signed_url,
                    content_type: "image/webp"
                }
                thumbnailFileUploadMutate(thumbnailUploadData)
            }
            if (photogrammetryFile) {
                const photogrammetryFileUploadData: FileUploadData = {
                    file: photogrammetryFile,
                    url: modelResponseData?.data.data[0].photogrammetry_file[0].pre_signed_url,
                    content_type: "application/octet-stream"
                }
                photogrammetryFileUploadMutate(photogrammetryFileUploadData)
            }
            if (completedCGModelFile) {
                const completedCGModelFileUploadData: FileUploadData = {
                    file: completedCGModelFile[0],
                    url: modelResponseData?.data.data[0].completed_cg_model_file[0].pre_signed_url,
                    content_type: "application/octet-stream"
                }
                completedCGModelFileUploadMutate(completedCGModelFileUploadData)
            }
            if (substancePainterFile) {
                const substancePainterFileUploadData: FileUploadData = {
                    file: substancePainterFile,
                    url: modelResponseData?.data.data[0].substance_painter_file[0].pre_signed_url,
                    content_type: "application/octet-stream"
                }
                substancePainterFileUploadMutate(substancePainterFileUploadData)
            }
            if (sourceMaskTextureFile) {
                const sourceMaskTextureFileUploadData: MultipleFilesUploadData = {
                    files: sourceMaskTextureFile,
                    urls: modelResponseData?.data.data[0].source_mask_textures,
                    content_type: "image/png"
                }
                sourceMaskTextureFileUploadMutate(sourceMaskTextureFileUploadData)
            }

        }

    }, [modelResponseData])

    // Upload Variant Model Files
    useEffect(() => {
        if (variantModelResponseData?.data.data[0].reference_images[0].pre_signed_url) {
            if (isModelUploadedFromMopu) {
                if (MOPUModelData?.poster_file) {
                    const copyPosterData: CopyFileData = {
                        copy_source: `${MOPU_BUCKET_NAME}/${MOPUModelData?.poster_file[0].split('/').splice(3).join("/")}`,
                        target_source: variantModelResponseData?.data.data[0].thumbnail_file[0].url.split('/').splice(3).join("/"),
                    }
                    copyPosterFileMutate(copyPosterData);
                }

                const copyGLBFileData: CopyFileData = {
                    copy_source: `${MOPU_BUCKET_NAME}/${MOPUModelData?.glb_file[0].split('/').splice(3).join("/")}`,
                    target_source: variantModelResponseData?.data.data[0].glb_file[0].url.split('/').splice(3).join("/"),
                }
                copyGLBFileMutate(copyGLBFileData)

                const copyUSDZFileData: CopyFileData = {
                    copy_source: `${MOPU_BUCKET_NAME}/${MOPUModelData?.usdz_file[0].split('/').splice(3).join("/")}`,
                    target_source: variantModelResponseData?.data.data[0].usdz_file[0].url.split('/').splice(3).join("/"),
                }
                copyUSDZFileMutate(copyUSDZFileData)

                const copyReferenceFileData: CopyFileData = {
                    copy_source: `${MOPU_BUCKET_NAME}/${MOPUModelData?.reference_file[0].split('/').splice(3).join("/")}`,
                    target_source: variantModelResponseData?.data.data[0].reference_images[0].url.split('/').splice(3).join("/"),
                }
                copyReferenceFileMutate(copyReferenceFileData)
            } else {
                if (referenceImages) {
                    const imageUploadData: MultipleFilesUploadData = {
                        files: referenceImages,
                        urls: variantModelResponseData?.data.data[0].reference_images,
                        content_type: "image/jpeg"
                    }
                    refImageUploadMutate(imageUploadData)
                }
                if (GLBFile) {
                    const GLBFileUploadData: FileUploadData = {
                        file: GLBFile[0],
                        url: variantModelResponseData?.data.data[0].glb_file[0].pre_signed_url,
                        content_type: "model/gltf-binary"
                    }
                    GLBUploadMutate(GLBFileUploadData)
                }
                if (USDZFile) {
                    const USDZFileUploadData: FileUploadData = {
                        file: USDZFile[0],
                        url: variantModelResponseData?.data.data[0].usdz_file[0].pre_signed_url,
                        content_type: "model/vnd.usdz+zip"
                    }
                    USDZUploadMutate(USDZFileUploadData)
                }
            }

            if (thumbnailFile) {
                const thumbnailUploadData: FileUploadData = {
                    file: thumbnailFile[0],
                    url: variantModelResponseData?.data.data[0].thumbnail_file[0].pre_signed_url,
                    content_type: "image/webp"
                }
                thumbnailFileUploadMutate(thumbnailUploadData)
            }
            if (photogrammetryFile) {
                const photogrammetryFileUploadData: FileUploadData = {
                    file: photogrammetryFile[0],
                    url: variantModelResponseData?.data.data[0].photogrammetry_file[0].pre_signed_url,
                    content_type: "application/octet-stream"
                }
                photogrammetryFileUploadMutate(photogrammetryFileUploadData)
            }
            if (completedCGModelFile) {
                const completedCGModelFileUploadData: FileUploadData = {
                    file: completedCGModelFile[0],
                    url: variantModelResponseData?.data.data[0].completed_cg_model_file[0].pre_signed_url,
                    content_type: "application/octet-stream"
                }
                completedCGModelFileUploadMutate(completedCGModelFileUploadData)
            }
            if (substancePainterFile) {
                const substancePainterFileUploadData: FileUploadData = {
                    file: substancePainterFile[0],
                    url: variantModelResponseData?.data.data[0].substance_painter_file[0].pre_signed_url,
                    content_type: "application/octet-stream"
                }
                substancePainterFileUploadMutate(substancePainterFileUploadData)
            }
            if (sourceMaskTextureFile) {
                const sourceMaskTextureFileUploadData: MultipleFilesUploadData = {
                    files: sourceMaskTextureFile,
                    urls: variantModelResponseData?.data.data[0].source_mask_textures,
                    content_type: "image/png"
                }
                sourceMaskTextureFileUploadMutate(sourceMaskTextureFileUploadData)
            }
        }
    }, [variantModelResponseData])

    return (
        <>
            <Header header='CREATE NEW ITEM' />

            <Grid container marginTop={8}>
                <Grid item xs={1.5} />

                <Grid item container xs={9} direction='column' spacing={'60px'}>

                    <Grid item> <CustomLightButton onClick={handleMopuModalOpen}> IMPORT A MODEL FROM
                        MOPU </CustomLightButton> </Grid>

                    <ImportFromMopuModal
                        isOpen={isMopuModalOpen}
                        handleClose={handleMopuModalClose}
                        setModelName={setModelName}
                        setSelectedCategory={setSelectedCategory}
                        selectedCategory={selectedCategory}
                        setProductLinks={setProductLinks}
                        setIsModelUploadedFromMopu={setIsModelUploadedFromMopu}
                        setTabValue={setTabValue}
                        setVariantDropzoneDisabled={setIsVariantDropzoneDisabled}
                        setModelData={setMOPUModelData}
                        importMopuSwitch={importMopuSwitch}
                        setImportMopuSwitch={setImportMopuSwitch}
                        setModelViewerConfig={setModelViewerConfig}
                        setPosterDropzoneDisabled={setPosterDropzoneDisabled}
                    />

                    {!isDevelopment && <>
                        <Grid item container direction='column' spacing='4px'>
                            <Grid item> <Typography variant='customH3'> Thumbnail image </Typography> </Grid>
                            <Grid item> {posterDropzoneDisabled ?
                                <Typography variant='customBody'> Your files will be uploaded automatically </Typography>
                                : <Typography variant='customBody'> Supported file types: .webp </Typography>} </Grid>
                            <Grid item>
                                <CustomDropZone
                                    setFile={setThumbnailFile}
                                    fileName={"Thumbnail"}
                                    isMultiple={true}
                                    accept={{ 'image/webp': ['.webp'] }}
                                    maxFiles={10}
                                    isDisabled={isVariantDropzoneDisabled}
                                />
                            </Grid>
                        </Grid>
                    </>}

                    <Grid item container direction='column'>
                        <Grid item> <Typography variant='customH3' mb={1}> Brand </Typography> </Grid>
                        <Grid item width={'417px'}>
                            <Autocomplete size='small' disableClearable={true} onChange={(e, selected) => {
                                setSelectedBrand(selected?.value!)
                            }} renderInput={(params) => <TextField {...params} />} options={brands} />
                        </Grid>
                    </Grid>

                    <Grid item container direction='column' spacing={'16px'} marginBottom={'75px'}>
                        <Grid item> <Typography variant='customH3'> Model type </Typography> </Grid>

                        <Grid item sx={{
                            width: '630px',
                            borderBottom: 1,
                            borderColor: 'divider',
                            paddingBottom: '24px',
                            marginBottom: '40px'
                        }}>
                            <Box>
                                <Tabs value={tabValue} onChange={handleTabChange} indicatorColor='secondary'>
                                    <Tab label="Base Model"
                                        onClick={() => {
                                            setTabValue(0)
                                        }} />
                                    <Tab label="Variant"
                                        onClick={() => {
                                            setTabValue(1)
                                        }} />
                                </Tabs>
                            </Box>
                        </Grid>

                        <Grid item container direction={'column'} spacing={'40px'}>
                            {tabValue == 0 ?
                                <BaseModelTab
                                    modelName={modelName}
                                    setModelName={setModelName}
                                    selectedCategory={selectedCategory}
                                    setSelectedCategory={setSelectedCategory}
                                    selectedModelStatus={selectedModelStatus}
                                    setSelectedModelStatus={setSelectedModelStatus}
                                    tags={tags}
                                    setTags={setTags}
                                    completedCGModelFile={completedCGModelFile}
                                    setCompletedCGModelFile={setCompletedCGModelFile}
                                    referenceImages={referenceImages}
                                    setReferenceImages={setReferenceImages}
                                    substancePainterFile={substancePainterFile}
                                    setSubstancePainterFile={setSubstancePainterFile}
                                    sourceMaskTextureFile={sourceMaskTextureFile}
                                    setSourceMaskTextureFile={setSourceMaskTextureFile}
                                    photogrammetryFile={photogrammetryFile}
                                    setPhotogrammetryFile={setPhotogrammetryFile}
                                    isSticky={isSticky}
                                    setIsSticky={setIsSticky}
                                />
                                : tabValue == 1 ?
                                    <VariantTab
                                        modelName={modelName}
                                        setModelName={setModelName}
                                        selectedCategory={selectedCategory}
                                        setSelectedCategory={setSelectedCategory}
                                        selectedModelStatus={selectedModelStatus}
                                        setSelectedModelStatus={setSelectedModelStatus}
                                        tags={tags}
                                        setTags={setTags}
                                        isTrending={isTrending}
                                        setIsTrending={setIsTrending}
                                        baseModelId={baseModelId}
                                        setBaseModelId={setBaseModelId}
                                        metadata={metadata}
                                        setMetadata={setMetadata}
                                        wannaModelId={wannaModelId}
                                        setWannaModelId={setWannaModelId}
                                        facebookLink={facebookLink}
                                        setFacebookLink={setFacebookLink}
                                        instagramLink={instagramLink}
                                        setInstagramLink={setInstagramLink}
                                        mvDemoLink={mvDemoLink}
                                        setMvDemoLink={setMvDemoLink}
                                        modelViewerConfig={modelViewerConfig}
                                        setModelViewerConfig={setModelViewerConfig}
                                        mvExposureConfig={mvExposureConfig}
                                        setMvExposureConfig={setMvExposureConfig}
                                        mvIntensityConfig={mvIntensityConfig}
                                        setMvIntensityConfig={setMvIntensityConfig}
                                        mvSoftnessConfig={mvSoftnessConfig}
                                        setMvSoftnessConfig={setMvSoftnessConfig}
                                        snapchatLink={snapchatLink}
                                        setSnapchatLink={setSnapchatLink}
                                        sparkProjectLink={sparkProjectLink}
                                        setSparkProjectLink={setSparkProjectLink}
                                        productLinks={productLinks}
                                        setProductLinks={setProductLinks}
                                        completedCGModelFile={completedCGModelFile}
                                        setCompletedCGModelFile={setCompletedCGModelFile}
                                        referenceImages={referenceImages}
                                        setReferenceImages={setReferenceImages}
                                        substancePainterFile={substancePainterFile}
                                        setSubstancePainterFile={setSubstancePainterFile}
                                        sourceMaskTextureFile={sourceMaskTextureFile}
                                        setSourceMaskTextureFile={setSourceMaskTextureFile}
                                        photogrammetryFile={photogrammetryFile}
                                        setPhotogrammetryFile={setPhotogrammetryFile}
                                        USDZFile={USDZFile}
                                        setUSDZFile={setUSDZFile}
                                        GLBFile={GLBFile}
                                        setGLBFile={setGLBFile}
                                        isDropzoneDisabled={isVariantDropzoneDisabled}
                                        envImgId={envImgId}
                                        setEnvImgId={setEnvImgId}
                                        price={price}
                                        setPrice={setPrice}
                                        description={description}
                                        setDescription={setDescription}
                                    />
                                    : null}
                        </Grid>

                        <Grid item container direction={'row'} spacing={'20px'} marginTop={'10px'}>
                            <Grid item> <CustomLightButton
                                onClick={tabValue === 1 ? handleVariantModelCreate : handleModelCreate}> <Typography
                                    variant='customBodyStrong'> COMPLETE ITEM </Typography> </CustomLightButton> </Grid>
                            {/* <Grid item> <CustomLightButton disabled> <Typography variant='customBodyStrong'> SAVE FOR LATER </Typography> </CustomLightButton> </Grid> */}
                        </Grid>
                    </Grid>

                </Grid>

                <Grid item xs={1.5} />
            </Grid>
            <Dialog open={openCreateModelDialog}>
                <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"} p={3} spacing={2}>
                    <Grid item container justifyContent={"center"} alignItems={"center"}>
                        {createModelStatus === "loading" ? (
                            <Stack direction={"row"} alignItems={"center"}>
                                <Typography variant='customBody' mr={2}>creating {modelName}</Typography>
                                <CircularProgress color='secondary' />
                            </Stack>
                        ) : createModelStatus === "error" ? (
                            <Stack direction={"row"} alignItems={"center"}>
                                <Typography variant='customBody' mr={2}>could not create {modelName}</Typography>
                                <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                            </Stack>
                        ) : createModelStatus === "success" ? (
                            <Stack direction={"row"} alignItems={"center"}>
                                <Typography variant='customBody' mr={2}>{modelName} creation successful</Typography>
                                <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                            </Stack>) : null}
                    </Grid>
                    <Grid item container justifyContent={"center"} alignItems={"center"}>
                        {createVariantModelStatus === "loading" ? (
                            <Stack direction={"row"} alignItems={"center"}>
                                <Typography variant='customBody' mr={2}>creating {modelName}</Typography>
                                <CircularProgress color='secondary' />
                            </Stack>
                        ) : createVariantModelStatus === "error" ? (
                            <Stack direction={"row"} alignItems={"center"}>
                                <Typography variant='customBody' mr={2}>could not create {modelName}</Typography>
                                <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                            </Stack>
                        ) : createVariantModelStatus === "success" ? (
                            <Stack direction={"row"} alignItems={"center"}>
                                <Typography variant='customBody' mr={2}>{modelName} creation successful</Typography>
                                <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                            </Stack>) : null}
                    </Grid>
                    {GLBFile ? (
                        <Grid item container justifyContent={"center"} alignItems={"center"}>
                            {GLBUploadStatus === "loading" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Uploading GLB File</Typography>
                                    <CircularProgress color="secondary" />
                                </Stack>
                            ) : GLBUploadStatus === "error" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Could not upload GLB File</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                                </Stack>
                            ) : GLBUploadStatus === "success" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>GLB File uploaded successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </Stack>
                            ) : (
                                <Typography variant="customBody" mr={2}>Waiting for GLB File upload...</Typography>
                            )}
                        </Grid>
                    ) : null}
                    {USDZFile ? (
                        <Grid item container justifyContent={"center"} alignItems={"center"}>
                            {USDZUploadStatus === "loading" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Uploading USDZ File</Typography>
                                    <CircularProgress color="secondary" />
                                </Stack>
                            ) : USDZUploadStatus === "error" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Could not upload USDZ File</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                                </Stack>
                            ) : USDZUploadStatus === "success" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>USDZ File uploaded successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </Stack>
                            ) : (
                                <Typography variant="customBody" mr={2}>Waiting for USDZ File upload...</Typography>
                            )}
                        </Grid>
                    ) : null}
                    {thumbnailFile ? (
                        <Grid item container justifyContent={"center"} alignItems={"center"}>
                            {thumbnailFileUploadStatus === "loading" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Uploading thumbnail image</Typography>
                                    <CircularProgress color="secondary" />
                                </Stack>
                            ) : thumbnailFileUploadStatus === "error" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Could not upload thumbnail
                                        image</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                                </Stack>
                            ) : thumbnailFileUploadStatus === "success" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Thumbnail image uploaded
                                        successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </Stack>
                            ) : (
                                <Typography variant="customBody" mr={2}>Waiting for thumbnail image
                                    upload...</Typography>
                            )}
                        </Grid>
                    ) : null}
                    {referenceImages ? (
                        <Grid item container justifyContent={"center"} alignItems={"center"}>
                            {refImageUploadStatus === "loading" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Uploading reference images</Typography>
                                    <CircularProgress color="secondary" />
                                </Stack>
                            ) : refImageUploadStatus === "error" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Could not upload reference
                                        images</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                                </Stack>
                            ) : refImageUploadStatus === "success" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Reference images uploaded
                                        successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </Stack>
                            ) : (
                                <Typography variant="customBody" mr={2}>Waiting for reference images
                                    upload...</Typography>
                            )}
                        </Grid>
                    ) : null}
                    {completedCGModelFile ? (
                        <Grid item container justifyContent={"center"} alignItems={"center"}>
                            {completedCGModelFileUploadStatus === "loading" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Uploading completed CG model
                                        file</Typography>
                                    <CircularProgress color="secondary" />
                                </Stack>
                            ) : completedCGModelFileUploadStatus === "error" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Could not upload completed CG model
                                        file</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                                </Stack>
                            ) : completedCGModelFileUploadStatus === "success" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Completed CG model file uploaded
                                        successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </Stack>
                            ) : (
                                <Typography variant="customBody" mr={2}>Waiting for completed CG model file
                                    upload...</Typography>
                            )}
                        </Grid>
                    ) : null}
                    {substancePainterFile ? (
                        <Grid item container justifyContent={"center"} alignItems={"center"}>
                            {substancePainterFileUploadStatus === "loading" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Uploading Substance Painter
                                        file</Typography>
                                    <CircularProgress color="secondary" />
                                </Stack>
                            ) : substancePainterFileUploadStatus === "error" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Could not upload Substance Painter
                                        file</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                                </Stack>
                            ) : substancePainterFileUploadStatus === "success" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Substance Painter file uploaded
                                        successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </Stack>
                            ) : (
                                <Typography variant="customBody" mr={2}>Waiting for Substance Painter file
                                    upload...</Typography>
                            )}
                        </Grid>
                    ) : null}
                    {sourceMaskTextureFile ? (
                        <Grid item container justifyContent={"center"} alignItems={"center"}>
                            {sourceMaskTextureFileUploadStatus === "loading" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Uploading Source Mask Textures</Typography>
                                    <CircularProgress color="secondary" />
                                </Stack>
                            ) : sourceMaskTextureFileUploadStatus === "error" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Could not upload Source Mask
                                        Textures</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                                </Stack>
                            ) : sourceMaskTextureFileUploadStatus === "success" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Source Mask Textures uploaded
                                        successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </Stack>
                            ) : (
                                <Typography variant="customBody" mr={2}>Waiting for Source Mask Textures
                                    upload...</Typography>
                            )}
                        </Grid>
                    ) : null}
                    {photogrammetryFile ? (
                        <Grid item container justifyContent={"center"} alignItems={"center"}>
                            {photogrammetryFileUploadStatus === "loading" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Uploading Photogrammetry File</Typography>
                                    <CircularProgress color="secondary" />
                                </Stack>
                            ) : photogrammetryFileUploadStatus === "error" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Could not upload Photogrammetry
                                        File</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                                </Stack>
                            ) : photogrammetryFileUploadStatus === "success" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Photogrammetry File uploaded
                                        successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </Stack>
                            ) : (
                                <Typography variant="customBody" mr={2}>Waiting for Photogrammetry File
                                    upload...</Typography>
                            )}
                        </Grid>
                    ) : null}
                    {isModelUploadedFromMopu ? (
                        <Grid item container justifyContent={"center"} alignItems={"center"}>
                            {copyPosterFileStatus === "loading" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Copying Thumbnail Image From MOPU</Typography>
                                    <CircularProgress color="secondary" />
                                </Stack>
                            ) : copyPosterFileStatus === "error" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Could not Copy Thumbnail Image</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                                </Stack>
                            ) : copyPosterFileStatus === "success" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Thumbnail Image Copied Successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </Stack>
                            ) : (
                                <Typography variant="customBody" mr={2}>Waiting for Copying Thumbnail Image From
                                    MOPU...</Typography>
                            )}
                            {copyGLBFileStatus === "loading" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Copying GLB File From MOPU</Typography>
                                    <CircularProgress color="secondary" />
                                </Stack>
                            ) : copyGLBFileStatus === "error" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Could not Copy GLB File</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                                </Stack>
                            ) : copyGLBFileStatus === "success" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>GLB File Copied Successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </Stack>
                            ) : (
                                <Typography variant="customBody" mr={2}>Waiting for Copying GLB File From
                                    MOPU...</Typography>
                            )}

                            {copyUSDZFileStatus === "loading" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Copying USDZ File From MOPU</Typography>
                                    <CircularProgress color="secondary" />
                                </Stack>
                            ) : copyUSDZFileStatus === "error" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Could not Copy USDZ File</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                                </Stack>
                            ) : copyUSDZFileStatus === "success" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>USDZ File Copied Successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </Stack>
                            ) : (
                                <Typography variant="customBody" mr={2}>Waiting for Copying USDZ File From
                                    MOPU...</Typography>
                            )}

                            {copyReferenceFileStatus === "loading" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Copying Reference File(s) From
                                        MOPU</Typography>
                                    <CircularProgress color="secondary" />
                                </Stack>
                            ) : copyReferenceFileStatus === "error" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Could not Copy Reference
                                        File(s)</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                                </Stack>
                            ) : copyReferenceFileStatus === "success" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Reference File(s) Copied
                                        Successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </Stack>
                            ) : (
                                <Typography variant="customBody" mr={2}>Waiting for Copying Reference File(s) From
                                    MOPU...</Typography>
                            )}
                        </Grid>
                    ) : null}
                    <Grid item>
                        <CustomLightButton onClick={handleCloseCreateModelDialog}>OK</CustomLightButton>
                    </Grid>
                </Grid>
            </Dialog>
        </>
    )
}
