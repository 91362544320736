import { Autocomplete, Button, Chip, CircularProgress, Grid, Stack, TextField, Typography, Box, Dialog, Switch } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Header from '../components/Header/Header';
import { useDeleteBaseModel, useGetBaseModelById, useUpdateBaseModel } from '../shared/query-hooks/ModelService';
import { categories, category, model_status, Option } from '../shared/interfaces/Enums';
import { useGetAllBrands } from '../shared/query-hooks/BrandService';
import { ModelData, ModelUploadData } from '../shared/interfaces/Model';
import CustomDropZone from '../components/CustomDropZone/CustomDropZone';
import { useUploadFile, useUploadMultipleFiles } from '../shared/query-hooks/FileUploadService';
import { FileUploadData, MultipleFilesUploadData } from '../shared/interfaces/FileModel';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { categoryChanger } from '../shared/utils/CategoryChanger';
import { useAuth } from 'qreal-auth-provider/src';
import { authKeys, authRoles, checkAuth } from '../enums/authKeys';
import { AppStates } from '../enums/app';

const EditBaseModel = () => {

  let params = useParams();
  let navigate = useNavigate();

  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = useState(false);

  
  const {data: baseModelResponseData, status: baseModelResponseStatus, isSuccess: BaseModelResponseIsSuccess} = useGetBaseModelById(params.baseModelId!);
  const {data: allBrandData, status: allBrandDataStatus, isSuccess: allBrandDataIsSuccess} = useGetAllBrands();
  const {mutate: updateBaseModelMutate, data: updateBaseModelResponseData, status: updateBaseModelStatus} = useUpdateBaseModel();
  const {mutate: deleteBaseModelMutate, status: deleteBaseModelMutateStatus} = useDeleteBaseModel();

  //Auth provider
  const { authList, user } = useAuth();
  const deletePermission = checkAuth(authList, authKeys.delete_model);

  //file upload mutates
  const {mutate: thumbnailFileUploadMutate, status: thumbnailFileUploadStatus} = useUploadFile();
  const {mutate: refImageUploadMutate, status: refImageUploadStatus} = useUploadMultipleFiles();
  const {mutate: completedCGModelFileUploadMutate, status: completedCGModelFileUploadStatus} = useUploadFile();
  const {mutate: substancePainterFileUploadMutate, status: substancePainterFileUploadStatus} = useUploadFile();
  const {mutate: sourceMaskTextureFileUploadMutate, status: sourceMaskTextureFileUploadStatus} = useUploadMultipleFiles();
  const {mutate: photogrammetryFileUploadMutate, status: photogrammetryFileUploadStatus} = useUploadFile();

  //base model states
  const [baseModelName, setBaseModelName] = useState("")
  const [selectedBrand, setSelectedBrand] = useState("")
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedModelStatus, setSelectedModelStatus] = useState("");
  const [tags, setTags] = useState<string[]>([]);
  const [isSticky, setIsSticky] = useState(false);

  //brand-select
  const [brands, setBrands] = useState<Option[]>([]);

  //file upload states
  const [thumbnailFile, setThumbnailFile] = useState();
  const [referenceImages, setReferenceImages] = useState();
  const [completedCGModelFile, setCompletedCGModelFile] = useState(); //.blend
  const [substancePainterFile, setSubstancePainterFile] = useState(); //.spp
  const [sourceMaskTextureFile, setSourceMaskTextureFile] = useState(); //.png
  const [photogrammetryFile, setPhotogrammetryFile] = useState(); //.fbx

  const handleOpenUpdateDialog = () => {
    setOpenUpdateDialog(true);
  };

  const handleCloseUpdateDialog = () => {
    setOpenUpdateDialog(false);
  };

  const handleOpenErrorDialog = () => {
    setOpenErrorDialog(true);
  };

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  const handleDeleteBaseModel = () => {
    deleteBaseModelMutate(params.baseModelId!);
  }

  useEffect(() => {
    if(deleteBaseModelMutateStatus === 'success'){
      navigate("/");
    }
  }, [deleteBaseModelMutateStatus]);

  const handleBaseModelUpdate = () => {
    const updateBaseModelData: ModelUploadData = {
      _id: params.baseModelId!,
      model_name: baseModelName,
      brand_id: selectedBrand,
      category: selectedCategory,
      model_status: selectedModelStatus,
      tags: tags,
      is_sticky: isSticky
    }
    if(thumbnailFile){
      updateBaseModelData.thumbnail_file = "update"
    }
    if(referenceImages){
      updateBaseModelData.reference_images = "update"
    }
    if(completedCGModelFile){
      updateBaseModelData.completed_cg_model_file = "update"
    }
    if(substancePainterFile){
      updateBaseModelData.substance_painter_file = "update"
    }
    if (sourceMaskTextureFile) {
      updateBaseModelData.source_mask_textures = "update"
    }
    if (photogrammetryFile) {
      updateBaseModelData.photogrammetry_file = "update"
    }
    updateBaseModelMutate(updateBaseModelData);
    handleOpenUpdateDialog();
  }

  //brand-select
  useEffect(() => {
    if(allBrandDataStatus === "success"){
        const tempArray: Option[] = []
        allBrandData?.data.forEach((brand,index) => {
            tempArray[index] = {value: brand._id, label: brand.brand_name}
          });
        setBrands(tempArray);
    }
  }, [allBrandDataStatus])
  
  useEffect(() => {
    if (baseModelResponseStatus === "success") {
        setBaseModelName(baseModelResponseData?.data[0].model_name!)
        setSelectedBrand(baseModelResponseData?.data[0].brand_detail[0]._id)
        const new_category = categoryChanger(baseModelResponseData?.data[0].category)
        setSelectedCategory(new_category)
        setSelectedModelStatus(baseModelResponseData?.data[0].model_status!)
        setTags(baseModelResponseData?.data[0].tags!)
        setIsSticky(baseModelResponseData?.data[0].is_sticky!)
    }
  }, [baseModelResponseStatus])

  useEffect(() => {
    if(updateBaseModelResponseData?.data.data[0].thumbnail_file){
      if(thumbnailFile){
        const thumbnailUploadData: FileUploadData = {
          file: thumbnailFile[0],
          url: updateBaseModelResponseData?.data.data[0].thumbnail_file.pre_signed_url,
          content_type: "image/webp"
        }
        thumbnailFileUploadMutate(thumbnailUploadData);
      }
    }
    if(updateBaseModelResponseData?.data.data[0].reference_images){
      if(referenceImages){
        const referenceImagesUploadData: MultipleFilesUploadData = {
          files: referenceImages,
          urls: updateBaseModelResponseData?.data.data[0].reference_images,
          content_type: "image/jpeg"
        }
        refImageUploadMutate(referenceImagesUploadData);
      }
    }
    if(updateBaseModelResponseData?.data.data[0].completed_cg_model_file){
      if(completedCGModelFile){
        const completedCGModelFileUploadData: FileUploadData = {
          file: completedCGModelFile[0],
          url: updateBaseModelResponseData?.data.data[0].completed_cg_model_file.pre_signed_url,
          content_type: "application/octet-stream",
        }
        completedCGModelFileUploadMutate(completedCGModelFileUploadData);
      }
    }
    if(updateBaseModelResponseData?.data.data[0].substance_painter_file){
      if(substancePainterFile){
        const substancePainterFileUploadData: FileUploadData = {
          file: substancePainterFile[0],
          url: updateBaseModelResponseData?.data.data[0].substance_painter_file.pre_signed_url,
          content_type: "application/octet-stream",
        }
        substancePainterFileUploadMutate(substancePainterFileUploadData);
      }
    }
    if(updateBaseModelResponseData?.data.data[0].source_mask_textures){
      if(sourceMaskTextureFile){
        const sourceMaskTextureFileUploadData: MultipleFilesUploadData = {
          files: sourceMaskTextureFile,
          urls: updateBaseModelResponseData?.data.data[0].source_mask_textures,
          content_type: "image/png",
        }
        sourceMaskTextureFileUploadMutate(sourceMaskTextureFileUploadData);
      }
    }
    if(updateBaseModelResponseData?.data.data[0].photogrammetry_file){
      if(photogrammetryFile){
        const photogrammetryFileUploadData: FileUploadData = {
          file: photogrammetryFile[0],
          url: updateBaseModelResponseData?.data.data[0].photogrammetry_file.pre_signed_url,
          content_type: "application/octet-stream",
        }
        photogrammetryFileUploadMutate(photogrammetryFileUploadData);
      }
    }
  }, [updateBaseModelResponseData])
  
  const handleTagsChange = (e:any, newValue:any) => {
    setTags(newValue)
  }

  const handleIsStickyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsSticky(event.target.checked);
  };

  return (
    <>
    <Header header='UPDATE BASE MODEL'/>
    <Grid container marginTop={'84px'}>
        <Grid item xs={1.5}></Grid>
        <Grid item container xs={9} direction={"column"}>
            {allBrandDataIsSuccess && BaseModelResponseIsSuccess ? 
            <Grid item container direction='column' spacing={4}>
            
                <>
                <Grid item container direction='column'>
                    <Grid item> <Typography variant='customH3' mb={0.5}> Thumbnail image </Typography> </Grid>
                    <Grid item> <Typography variant='customBody' mb={0.5}> Supported file types: .webp </Typography> </Grid>
                    <Grid item>
                    <CustomDropZone
                      setFile={setThumbnailFile}
                      fileName={"Thumbnail"}
                      isMultiple={true}
                      accept={{'image/webp': ['.webp']}}
                      maxFiles={10}
                      isDisabled={false}
                    />
                    </Grid>
                </Grid>
                <Grid item container direction={"column"}>
                    <Grid item> <Typography variant='customH3' mb={1}> Brand </Typography> </Grid>
                    <Grid item width={'417px'}>
                        <Autocomplete size='small' disableClearable={true} value={brands.find(element => element.value === selectedBrand) || {label:"",value:""}} onChange={(e,selected) => {setSelectedBrand(selected?.value!)}} renderInput={(params) => <TextField {...params} />} options={brands}/>
                    </Grid>
                </Grid>

                <Grid item container direction={"column"}>
                    <Typography variant="customH3" mb={1}> Name </Typography>
                    <TextField value={baseModelName || ""} onChange={(e) => setBaseModelName(e.target.value)}  sx={{ "& .MuiInputBase-root": { height: '40px', width:'417px' } }} />
                </Grid>

                <Grid item container direction={'column'}>
                  <Grid item>
                      <Typography variant='customH3' mb={1}> Category </Typography>
                  </Grid>
                  <Grid item width={'417px'}>
                      <Autocomplete size='small' disableClearable={true} value={category.find(element => element.value === selectedCategory) || {label:"",value:""}} onChange={(e,selected) => {setSelectedCategory(selected?.value!)}} renderInput={(params) => <TextField {...params} />} options={category}/>
                  </Grid>
                </Grid>

                <Grid item container direction={'column'}>
                  <Grid item>
                      <Typography variant='customH3' mb={1}> Tags </Typography>
                  </Grid>
                  <Grid item width={"417px"}>
                    <Autocomplete
                        size='small'
                        multiple
                        id="tags-filled"
                        options={categories}
                        value={[...tags]}
                        onChange={handleTagsChange}
                        freeSolo
                        renderTags={(value: readonly string[], getTagProps) =>
                        value?.map((option: string, index: number) => (
                            <Chip label={option} {...getTagProps({ index })} />
                        ))
                        }
                        renderInput={(params) => (
                        <TextField {...params}/>
                        )}
                    />
                  </Grid>
                </Grid>

                <Grid item container direction={'column'}>
                    <Grid item>
                        <Typography variant='customH3'  mb={1}> Sticky </Typography>
                    </Grid>
                    <Grid item>
                        <Switch
                            color="secondary"
                            checked={isSticky}
                            onChange={handleIsStickyChange}
                        />
                    </Grid>
                </Grid>

                {(user.role === authRoles.ADMIN || process.env.REACT_APP_APP_STATE === AppStates.DEV) &&<Grid item container direction={'column'}>
                  <Grid item>
                      <Typography variant='customH3' mb={1}> Status </Typography>
                  </Grid>
  
                  <Grid item width={'417px'}>
                  <Autocomplete size='small' disableClearable={true} value={model_status.find(element => element.value === selectedModelStatus) || {label:"",value:""}} onChange={(e,selected) => {setSelectedModelStatus(selected?.value!)}} renderInput={(params) => <TextField {...params} />} options={model_status}/>
                  </Grid>
                </Grid>
}
                <Grid item container direction={"column"}>
                    <Grid item pb={2.5}>
                      <Typography variant='customH3'> Upload files </Typography>
                    </Grid>
    
                    <Grid item container spacing={5}>
                        <Grid item>
                        <Stack>
                          <Stack pb={1.5}>
                            <Typography variant='customBodyStrong'> Photogrammetry file </Typography>
                            <Typography variant='customBodySm'> Supported File Type: .fbx</Typography>
                          </Stack>
                          <CustomDropZone
                            setFile={setPhotogrammetryFile}
                            fileName={"Photogrammetry Model"}
                            isMultiple={true}
                            accept={{'application/octet-stream': ['.fbx']}}
                            maxFiles={10}
                            isDisabled={false}
                          />
                        </Stack>
                        </Grid>
                        <Grid item>
                        <Stack>
                          <Stack pb={1.5}>
                            <Typography variant='customBodyStrong'> CG file </Typography>
                            <Typography variant='customBodySm'> Supported File Type: .blend</Typography>
                          </Stack>
                          <CustomDropZone
                            setFile={setCompletedCGModelFile}
                            fileName={"CG Model"}
                            isMultiple={true}
                            accept={{'application/octet-stream': ['.blend']}}
                            maxFiles={10}
                            isDisabled={false}
                          />
                        </Stack>
                        </Grid>
                        <Grid item>
                        <Stack>
                          <Stack pb={1.5}>
                            <Typography variant='customBodyStrong'> Reference Images </Typography>
                            <Typography variant='customBodySm'> Supported File Type: .jpeg</Typography>
                          </Stack>
                          <CustomDropZone
                            setFile={setReferenceImages}
                            fileName={"Reference Images"}
                            isMultiple={true}
                            accept={{'image/jpeg': ['.jpeg', '.jpg']}}
                            maxFiles={10}
                            isDisabled={false}
                          />
                        </Stack>
                        </Grid>
                        {/* <Grid item>
                        <Stack>
                        <Stack pb={1.5}>
                            <Typography variant='customBodyStrong'> Substance Painter </Typography>
                            <Typography variant='customBodySm'> Supported File Type: .spp</Typography>
                          </Stack>
                          <CustomDropZone
                            setFile={props.setCompletedCGModelFile}
                            fileName={"Substance Painter"}
                            isMultiple={true}
                            accept={{'application/octet-stream': ['.spp']}}
                            maxFiles={10}
                          />
                        </Stack>
                        </Grid> */}
                        <Grid item>
                        <Stack>
                        <Stack pb={1.5}>
                            <Typography variant='customBodyStrong'> Source Textures </Typography>
                            <Typography variant='customBodySm'> Supported File Type: .png</Typography>
                          </Stack>
                          <CustomDropZone
                            setFile={setSourceMaskTextureFile}
                            fileName={"Source Textures"}
                            isMultiple={true}
                            accept={{'image/png': ['.png']}}
                            maxFiles={10}
                            isDisabled={false}
                          />
                        </Stack>
                        </Grid>
                      
                    </Grid>
                </Grid>
                <Grid item container mb={10} spacing={2}>
                  <Grid item>
                    <Button variant='contained' onClick={handleBaseModelUpdate}>Update Model</Button>
                  </Grid>
                  {
                    (deletePermission && (user.role === authRoles.ADMIN || process.env.REACT_APP_APP_STATE === AppStates.DEV)) &&
                    <Grid item>
                      <Button variant='contained' color='error' onClick={handleOpenErrorDialog}>Delete Model</Button>
                    </Grid>
                  }
                  
                </Grid>
                </>
            </Grid>: null}
        </Grid>
        <Grid item xs={1.5}></Grid>
    </Grid>
    <Dialog open={openUpdateDialog}>
        <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"} p={3} spacing={2}>
          <Grid item container justifyContent={"center"} alignItems={"center"}>
          {updateBaseModelStatus === "loading" ? (
            <Stack direction={"row"} alignItems={"center"}>
            <Typography variant='customBody' mr={2}>Updating {baseModelName}</Typography>
            <CircularProgress color="secondary"/>
            </Stack>
          ) : updateBaseModelStatus === "error" ? (
            <Stack direction={"row"} alignItems={"center"}>
            <Typography variant='customBody' mr={2}>Could not update {baseModelName}</Typography>
            <ErrorOutlineOutlinedIcon color='error' fontSize='large'/>
            </Stack>
          ): updateBaseModelStatus === "success" ? (
            <Stack direction={"row"} alignItems={"center"}>
            <Typography variant='customBody' mr={2}>{baseModelName} updated successfully</Typography>
            <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large'/>
            </Stack>
          ) : (
            <Typography variant="customBody" mr={2}>Waiting for {baseModelName} to upload...</Typography>
          )}
          </Grid>
          {thumbnailFile ? (
            <Grid item container justifyContent={"center"} alignItems={"center"}>
            {thumbnailFileUploadStatus === "loading" ? (
              <Stack direction={"row"} alignItems={"center"}>
              <Typography variant='customBody' mr={2}>Uploading thumbnail image</Typography>
              <CircularProgress color="secondary"/>
              </Stack>
            ) : thumbnailFileUploadStatus === "error" ? (
              <Stack direction={"row"} alignItems={"center"}>
              <Typography variant='customBody' mr={2}>Could not upload thumbnail image</Typography>
              <ErrorOutlineOutlinedIcon color='error' fontSize='large'/>
              </Stack>
            ): thumbnailFileUploadStatus === "success" ? (
              <Stack direction={"row"} alignItems={"center"}>
              <Typography variant='customBody' mr={2}>Thumbnail image uploaded successfully</Typography>
              <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large'/>
              </Stack>
            ) : (
              <Typography variant="customBody" mr={2}>Waiting for thumbnail image upload...</Typography>
            )}
            </Grid>
          ): null}
          {referenceImages ? (
            <Grid item container justifyContent={"center"} alignItems={"center"}>
            {refImageUploadStatus === "loading" ? (
              <Stack direction={"row"} alignItems={"center"}>
              <Typography variant='customBody' mr={2}>Uploading reference images</Typography>
              <CircularProgress color="secondary"/>
              </Stack>
            ) : refImageUploadStatus === "error" ? (
              <Stack direction={"row"} alignItems={"center"}>
              <Typography variant='customBody' mr={2}>Could not upload reference images</Typography>
              <ErrorOutlineOutlinedIcon color='error' fontSize='large'/>
              </Stack>
            ): refImageUploadStatus === "success" ? (
              <Stack direction={"row"} alignItems={"center"}>
              <Typography variant='customBody' mr={2}>Reference images uploaded successfully</Typography>
              <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large'/>
              </Stack>
            ) : (
              <Typography variant="customBody" mr={2}>Waiting for reference images upload...</Typography>
            )}
            </Grid>
          ): null}
          {completedCGModelFile ? (
            <Grid item container justifyContent={"center"} alignItems={"center"}>
            {completedCGModelFileUploadStatus === "loading" ? (
              <Stack direction={"row"} alignItems={"center"}>
              <Typography variant='customBody' mr={2}>Uploading completed CG model file</Typography>
              <CircularProgress color="secondary"/>
              </Stack>
            ) : completedCGModelFileUploadStatus === "error" ? (
              <Stack direction={"row"} alignItems={"center"}>
              <Typography variant='customBody' mr={2}>Could not upload completed CG model file</Typography>
              <ErrorOutlineOutlinedIcon color='error' fontSize='large'/>
              </Stack>
            ): completedCGModelFileUploadStatus === "success" ? (
              <Stack direction={"row"} alignItems={"center"}>
              <Typography variant='customBody' mr={2}>Completed CG model file uploaded successfully</Typography>
              <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large'/>
              </Stack>
            ) : (
              <Typography variant="customBody" mr={2}>Waiting for completed CG model file upload...</Typography>
            )}
            </Grid>
          ): null}
          {substancePainterFile ? (
            <Grid item container justifyContent={"center"} alignItems={"center"}>
            {substancePainterFileUploadStatus === "loading" ? (
              <Stack direction={"row"} alignItems={"center"}>
              <Typography variant='customBody' mr={2}>Uploading Substance Painter file</Typography>
              <CircularProgress color="secondary"/>
              </Stack>
            ) : substancePainterFileUploadStatus === "error" ? (
              <Stack direction={"row"} alignItems={"center"}>
              <Typography variant='customBody' mr={2}>Could not upload Substance Painter file</Typography>
              <ErrorOutlineOutlinedIcon color='error' fontSize='large'/>
              </Stack>
            ): substancePainterFileUploadStatus === "success" ? (
              <Stack direction={"row"} alignItems={"center"}>
              <Typography variant='customBody' mr={2}>Substance Painter file uploaded successfully</Typography>
              <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large'/>
              </Stack>
            ) : (
              <Typography variant="customBody" mr={2}>Waiting for Substance Painter file upload...</Typography>
            )}
            </Grid>
          ): null}
          {sourceMaskTextureFile ? (
            <Grid item container justifyContent={"center"} alignItems={"center"}>
            {sourceMaskTextureFileUploadStatus === "loading" ? (
              <Stack direction={"row"} alignItems={"center"}>
              <Typography variant='customBody' mr={2}>Uploading Source Mask Textures</Typography>
              <CircularProgress color="secondary"/>
              </Stack>
            ) : sourceMaskTextureFileUploadStatus === "error" ? (
              <Stack direction={"row"} alignItems={"center"}>
              <Typography variant='customBody' mr={2}>Could not upload Source Mask Textures</Typography>
              <ErrorOutlineOutlinedIcon color='error' fontSize='large'/>
              </Stack>
            ): sourceMaskTextureFileUploadStatus === "success" ? (
              <Stack direction={"row"} alignItems={"center"}>
              <Typography variant='customBody' mr={2}>Source Mask Textures uploaded successfully</Typography>
              <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large'/>
              </Stack>
            ) : (
              <Typography variant="customBody" mr={2}>Waiting for Source Mask Textures upload...</Typography>
            )}
            </Grid>
          ): null}
          {photogrammetryFile ? (
            <Grid item container justifyContent={"center"} alignItems={"center"}>
            {photogrammetryFileUploadStatus === "loading" ? (
              <Stack direction={"row"} alignItems={"center"}>
              <Typography variant='customBody' mr={2}>Uploading Photogrammetry File</Typography>
              <CircularProgress color="secondary"/>
              </Stack>
            ) : photogrammetryFileUploadStatus === "error" ? (
              <Stack direction={"row"} alignItems={"center"}>
              <Typography variant='customBody' mr={2}>Could not upload Photogrammetry File</Typography>
              <ErrorOutlineOutlinedIcon color='error' fontSize='large'/>
              </Stack>
            ): photogrammetryFileUploadStatus === "success" ? (
              <Stack direction={"row"} alignItems={"center"}>
              <Typography variant='customBody' mr={2}>Photogrammetry File uploaded successfully</Typography>
              <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large'/>
              </Stack>
            ) : (
              <Typography variant="customBody" mr={2}>Waiting for Photogrammetry File upload...</Typography>
            )}
            </Grid>
          ): null}
          <Grid item container justifyContent={"center"} alignItems={"center"}>
            <Button variant='contained' onClick={handleCloseUpdateDialog}>OK</Button>
          </Grid>
        </Grid>
    </Dialog>
    <Dialog open={openErrorDialog}>
      
        <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"} p={3} spacing={2}>
        <Grid item>
        <Typography variant='customH3'>Delete {baseModelName}</Typography>
        </Grid>
        <Grid item>
        <Typography variant='customBody'>This action can not be undone</Typography>
        </Grid>
        <Grid item container justifyContent={"center"} alignItems={"center"}>
          {deleteBaseModelMutateStatus === "loading" ? (
            <Stack direction={"row"} alignItems={"center"}>
            <Typography variant='customBody' mr={2}>Deleting {baseModelName}</Typography>
            <CircularProgress color="secondary"/>
            </Stack>
          ) : deleteBaseModelMutateStatus === "error" ? (
            <Stack direction={"row"} alignItems={"center"}>
            <Typography variant='customBody' mr={2}>Could not delete {baseModelName}</Typography>
            <ErrorOutlineOutlinedIcon color='error' fontSize='large'/>
            </Stack>
          ): deleteBaseModelMutateStatus === "success" ? (
            <Stack direction={"row"} alignItems={"center"}>
            <Typography variant='customBody' mr={2}>{baseModelName} deleted successfully</Typography>
            <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large'/>
            </Stack>
          ) : null}
          </Grid>
        <Grid item container spacing={2} justifyContent={"flex-end"}>
          <Grid item>
            <Button onClick={handleDeleteBaseModel} variant="contained" color="error">delete</Button>
          </Grid>
          <Grid item>
            <Button onClick={handleCloseErrorDialog} variant="contained">cancel</Button>
          </Grid>
        </Grid>
        </Grid>
      
    </Dialog>
    </>
  )
}

export default EditBaseModel