import axios from "axios";
import {useMutation} from "@tanstack/react-query";
import {CopyFileData} from "../interfaces/FileModel";
import {baseURL} from "../constants";
import { getTokenFromStorage } from "./AuthService";

export const copyFile = async (copyData: CopyFileData) => {
    return await axios.post(
        `${baseURL}/models/copy?is_debug=true`,
        {
            copy_source: copyData.copy_source,
            target_source: copyData.target_source,
        },
        {
            headers: {
                "Authorization": `Bearer ${getTokenFromStorage()}`
            }
        })
}

export const useCopyFile = () => {
    return useMutation(copyFile, {
        onSuccess: () => {
            console.log("file uploaded")
        },
        onError: () => {
            console.log("error")
        }
    })
}