import React, { useState } from 'react'
import { Box, Grid, TextField, Typography, Checkbox, Stack } from '@mui/material';
import { SearchProps } from './SearchBlock.config';
import styles from './SearchBlock.module.scss';


function SearchBlock(props: SearchProps) {

    const handleHatsCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setShowHats(event.target.checked);
    };
    const handleEyewearCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setShowEyewear(event.target.checked);
    };
    const handleShoesCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setShowShoes(event.target.checked);
    };
    const handleWatchesCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setShowWatches(event.target.checked);
    };
    const handleSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setSearchText(event.target.value);
    }
    const handleShowPassivesCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setShowPassives(event.target.checked);
    }
    return (
        <Box className={styles.searchBox} >
            <Grid container direction='column' alignItems={"space-between"} justifyContent={"space-evenly"} height={"100%"}>
                <Grid item>
                    <Typography variant='customBodyStrong'> SEARCH MODEL</Typography>
                </Grid>
                <Grid item>
                    <TextField name='searchModel' onChange={handleSearchText} label="Search" variant="outlined" fullWidth size='small' />
                </Grid>
                <Grid item container alignItems={'center'}>
                    <Grid item marginRight={'26px'}>
                        <Typography variant='customBodyStrong'> SHOW ONLY </Typography>
                    </Grid>
                    <Stack direction={"row"} alignItems={"center"} mr={1}>
                        <Typography variant='customBodyCondensed'>Hats</Typography>
                        <Checkbox
                            checked={props.showHats}
                            onChange={handleHatsCheckbox}
                            inputProps={{ 'aria-label': 'controlled' }}
                            color='secondary' />
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} mr={1}>
                        <Typography variant='customBodyCondensed'>Eyewear</Typography>
                        <Checkbox
                            checked={props.showEyewear}
                            onChange={handleEyewearCheckbox}
                            inputProps={{ 'aria-label': 'controlled' }}
                            color='secondary' />
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} mr={1}>
                        <Typography variant='customBodyCondensed'>Shoes</Typography>
                        <Checkbox
                            checked={props.showShoes}
                            onChange={handleShoesCheckbox}
                            inputProps={{ 'aria-label': 'controlled' }}
                            color='secondary' />
                    </Stack>
                    <Stack direction={"row"} alignItems={"center"} mr={1}>
                        <Typography variant='customBodyCondensed'>Watches</Typography>
                        <Checkbox
                            checked={props.showWatches}
                            onChange={handleWatchesCheckbox}
                            inputProps={{ 'aria-label': 'controlled' }}
                            color='secondary' />
                    </Stack>

                    <Stack direction={"row"} alignItems={"center"} mr={1}>
                        <Typography variant='customBodyCondensed'>Passives</Typography>
                        <Checkbox
                            checked={props.showPassives}
                            onChange={handleShowPassivesCheckbox}
                            inputProps={{ 'aria-label': 'controlled' }}
                            color='secondary' />
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    )
}

export default SearchBlock;
