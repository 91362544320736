import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { Grid } from '@mui/material';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { timeZones } from '../../enums/date';

dayjs.extend(utc);
dayjs.extend(timezone);
const DEFAULT_TIMEZONE = timeZones.usTimezone;

function NotificationDatePicker({ callback }: {
  callback: (value: string) => void
}) {
  const [selectedDate, setSelectedDate] = React.useState<Date>(dayjs().tz(timeZones.usTimezone).toDate());

  const getFormattedDates = (currentDateTime: any) => {
    // Helper function to format a date
    const formatDate = (date: Date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    // Get dates for each timezone
    const turkeyDate = formatDate(new Date(currentDateTime.toLocaleString('en-US', { timeZone: timeZones.trTimezone })));
    const usDate = formatDate(new Date(currentDateTime.toLocaleString('en-US', { timeZone: timeZones.usTimezone })));
    const londonDate = formatDate(new Date(currentDateTime.toLocaleString('en-US', { timeZone: timeZones.loTimezone })));
    const laDate = formatDate(new Date(currentDateTime.toLocaleString('en-US', { timeZone: timeZones.laTimezone })));
    const jpDate = formatDate(new Date(currentDateTime.toLocaleString('en-US', { timeZone: timeZones.jpTimezone })));
    const beDate = formatDate(new Date(currentDateTime.toLocaleString('en-US', { timeZone: timeZones.beTimezone })));

    return {
      turkey: turkeyDate,
      us: usDate,
      london: londonDate,
      la: laDate,
      berlin: beDate,
      japan: jpDate,
    };
  }


  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <DatePicker label="Select Send Date" value={dayjs(selectedDate)} defaultValue={dayjs(selectedDate)} onChange={(value) => {
              if (value?.isBefore(dayjs())) {
                setSelectedDate(new Date());
                callback(new Date().toISOString());
              } else {
                if (value?.toDate()) {
                  const updateDate = new Date(value?.toDate().setMinutes(0, 0, 0));
                  setSelectedDate(updateDate);
                  callback(updateDate.toISOString());
                }
              }
            }} />
          </Grid>

          <Grid item xs={12} sm={4}>
            <TimePicker ampm={false} timezone={timeZones.usTimezone} views={['hours']} onChange={(value => {
              const date = dayjs(value as any).set('minute', 0);
              setSelectedDate(dayjs(selectedDate).tz(DEFAULT_TIMEZONE).set('hour', date.hour()).set('minute', 0).toDate())
              callback(dayjs(selectedDate).tz(DEFAULT_TIMEZONE).set('hour', date.hour()).set('minute', 0).toISOString())
            })} />
          </Grid>
          .
          <Grid item xs={12} sm={4}>
            <div>
              <h3 style={{ margin: 0 }}>World Clocks</h3>
              <p>Turkey: {getFormattedDates(selectedDate).turkey}</p>
              <p><strong>New York: {getFormattedDates(selectedDate).us}</strong></p>
              <p>Los Angeles: {getFormattedDates(selectedDate).la}</p>
              <p>United Kingdom: {getFormattedDates(selectedDate).london}</p>
              <p>Berlin: {getFormattedDates(selectedDate).berlin}</p>
              <p>Japan: {getFormattedDates(selectedDate).japan}</p>
            </div>
          </Grid>
        </Grid>

      </DemoContainer>
    </LocalizationProvider>
  );
}

export default NotificationDatePicker;