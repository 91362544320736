import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { FileUploadData, MultipleFilesUploadData } from "../interfaces/FileModel";

export const uploadFile = async (imageData: FileUploadData) => {
    console.log(imageData.file);
    return await axios({
        method: "put",
        url: imageData.url,
        headers: {
            "Content-Type": imageData.content_type,
        },
        data: imageData.file,
    })
}

export const useUploadFile = () => {
    return useMutation(uploadFile, {
        onSuccess : () => {
            console.log("file uploaded")
        },
        onError : () => {
            console.log("error")
        }
    })
}

const uploadMultipleFiles = async (files: MultipleFilesUploadData) => {
    for (let i = 0; i < files.files.length; i++) {
        await axios({
            method: "put",
            url: files.urls[i].pre_signed_url,
            headers: {
                "Content-Type": files.content_type
            },
            data: files.files[i]
        })
    }
}

export const useUploadMultipleFiles = () => {
    return useMutation(uploadMultipleFiles, {
        onSuccess : () => {
            console.log("files uploaded")
        },
        onError : () => {
            console.log("error")
        }
    })
}