import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { getTokenFromStorage } from "./AuthService";
import { PushNotificationData, PushNotificationsHistoryData } from "../interfaces/PushNotificationData";
import { baseURL } from "../constants";
import { NotificationStatus } from "../../enums/status";

const sendPushNotification = async (pushNotification: PushNotificationData) => {
    return await axios.post(`${baseURL}/notifications/notify`, pushNotification, { // url should be inserted
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
}

const cancelPushNotification = async (id: String) => {
    return await axios.put(`${baseURL}/notifications/history/${id}`, {
        status: NotificationStatus.CANCELLED
    }, { // url should be inserted
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
}

export const getNotificationHistory = async (historyRequest: PushNotificationsHistoryData) => {
    return await axios.get(`${baseURL}/notifications/history?page=${historyRequest.page}&per_page=${historyRequest.per_page}`, {
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
}

export const useSendPushNotification = () => {
    return useMutation(sendPushNotification, {
        onSuccess: () => {
            console.log("push notification sent");
        },
        onError: () => {
            console.log("error")
        },
    })
}

export const useCancelNotification = () => {
    return useMutation(cancelPushNotification, {
        onSuccess: () => {
            console.log("push notification canceled");
        },
        onError: () => {
            console.log("error")
        },
    })
}
