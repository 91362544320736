import { Button, Grid, TextField, Typography, CircularProgress, Autocomplete, Chip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDeleteBrand, useGetBrandById, useUpdateBrand } from '../shared/query-hooks/BrandService';
import Select, { OnChangeValue } from 'react-select';
import { category, model_status, Option, categories } from '../shared/interfaces/Enums';
import { UpdateBrandData } from '../shared/interfaces/Brand';
import Header from '../components/Header/Header';
import Dialog from '@mui/material/Dialog';
import CustomDropZone from '../components/CustomDropZone/CustomDropZone';
import { FileUploadData } from '../shared/interfaces/FileModel';
import { useUploadFile } from '../shared/query-hooks/FileUploadService';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { authKeys, checkAuth } from '../enums/authKeys';
import { useAuth } from 'qreal-auth-provider/src';

const userData: Option[] = []

const EditBrand = () => {

    let params = useParams();
    let navigate = useNavigate();

    const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);

    //Auth provider
    const { authList } = useAuth();
    const deletePermission = checkAuth(authList, authKeys.delete_model);

    const handleOpenUpdateDialog = () => {
        setOpenUpdateDialog(true);
    };

    const handleCloseUpdateDialog = () => {
        setOpenUpdateDialog(false);
    };

    const handleOpenErrorDialog = () => {
        setOpenErrorDialog(true);
    };

    const handleCloseErrorDialog = () => {
        setOpenErrorDialog(false);
    };

    const { data: brandData, status, isFetched, isSuccess, refetch } = useGetBrandById(params.brandId!);
    //const {data: userListData, isFetched: userListIsFetched} = useGetUserList();

    const [brandName, setBrandName] = useState("");
    const [selectedBrandStatus, setSelectedBrandStatus] = useState("");
    const [tags, setTags] = useState<string[]>([]);
    //const [userAccess, setUserAccess] = useState<string[]>([]);
    const [thumbnailFile, setThumbnailFile] = useState();

    const {
        mutate: updateBrandMutate,
        data: updateBrandResponseData,
        isSuccess: updateBrandIsSuccess,
        status: updateBrandStatus
    } = useUpdateBrand();
    const { mutate: deleteBrandMutate, status: deleteBrandMutateStatus } = useDeleteBrand();
    const {
        mutate: thumbnailFileUploadMutate,
        isLoading: thumbnailFileUploadIsLoading,
        isSuccess: thumbnailFileUploadIsSuccess,
        isError: thumbnailFileUploadIsError,
        status: thumbnailFileUploadStatus
    } = useUploadFile();


    useEffect(() => {
        refetch();
    }, [status])


    useEffect(() => {
        if (isFetched) {
            console.log(brandData);
            setBrandName(brandData?.data[0].brand_name!);
            setTags(brandData?.data[0].tags!);
            //setUserAccess(brandData?.data[0].authorized_users!);
            setSelectedBrandStatus(brandData?.data[0].brand_status!);
        }

    }, [brandData])

    useEffect(() => {
        if (updateBrandResponseData?.data.data[0].thumbnail_file) {
            if (thumbnailFile) {
                const thumbnailUploadData: FileUploadData = {
                    file: thumbnailFile[0],
                    url: updateBrandResponseData?.data.data[0].thumbnail_file.pre_signed_url,
                    content_type: "image/png"
                }
                thumbnailFileUploadMutate(thumbnailUploadData)
            }
        }
    }, [updateBrandResponseData])

    const handleTagsChange = (e: any, newValue: any) => {
        setTags(newValue)
    }

    const handleUpdateBrand = () => {
        const updateBrandData: UpdateBrandData = {
            _id: params.brandId!,
            brand_name: brandName,
            brand_status: selectedBrandStatus,
            tags: tags,
            //authorized_users: brandData?.data[0].authorized_users!,
        }
        if (thumbnailFile) {
            updateBrandData.thumbnail_file = "update"
        }
        console.log(updateBrandData);
        updateBrandMutate(updateBrandData);
        handleOpenUpdateDialog();
    }

    const handleDeleteBrand = () => {
        deleteBrandMutate(params.brandId!);
    }

    useEffect(() => {
        if (deleteBrandMutateStatus === 'success') {
            navigate("/");
        }
    }, [deleteBrandMutateStatus]);

    useEffect(() => {
        if (((thumbnailFile && thumbnailFileUploadIsSuccess) && updateBrandIsSuccess) || (!thumbnailFile && updateBrandIsSuccess)) {
            setTimeout(() => {
                handleCloseUpdateDialog();
                navigate(-1);
            }, 2000);
        }
    }, [thumbnailFileUploadIsSuccess, updateBrandIsSuccess])


    return (
        <>
            <Header header={`UPDATE BRAND`} />
            {status === "loading" ? <Typography mt={5} align='center' variant='customH3'>LOADING BRAND
                DATA...</Typography> : status === "error" ?
                <Typography mt={5} align='center' variant='customH3'>ERROR</Typography> :
                status === "success" ?
                    (
                        <Grid container>
                            <Grid item xs={1.5}></Grid>
                            <Grid item container xs={9} direction={"column"} marginTop={'25px'} spacing={4}>
                                <Grid item container direction={"column"}>
                                    <Typography variant="customH3" mb={2}> Brand Logo </Typography>
                                    <Grid item>
                                        <CustomDropZone setFile={setThumbnailFile}
                                            fileName={"Thumbnail"}
                                            isMultiple={true}
                                            accept={{ 'image/png': ['.png'] }}
                                            maxFiles={10}
                                            isDisabled={false}
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item container direction={"column"}>
                                    <Typography variant="customH3" mb={2}> Name </Typography>
                                    <TextField value={brandName || ""} onChange={(e) => setBrandName(e.target.value)}
                                        sx={{ "& .MuiInputBase-root": { height: '40px', width: '417px' } }} />
                                </Grid>
                                <Grid item container direction={"column"}>
                                    <Grid item>
                                        <Typography variant="customH3" mb={2}> Status </Typography>
                                    </Grid>
                                    <Grid item width={"417px"}>
                                        <Autocomplete size='small' disableClearable={true}
                                            value={model_status.find(element => element.value === selectedBrandStatus) || {
                                                label: "",
                                                value: ""
                                            }} onChange={(e, selected) => {
                                                setSelectedBrandStatus(selected?.value!)
                                            }} renderInput={(params) => <TextField {...params} />} options={model_status} />
                                    </Grid>
                                </Grid>
                                <Grid item container direction={"column"}>
                                    <Grid item>
                                        <Typography variant="customH3" mb={2}> Tags </Typography>
                                    </Grid>
                                    <Grid item width={"417px"}>
                                        <Autocomplete
                                            size='small'
                                            multiple
                                            id="tags-filled"
                                            options={categories}
                                            value={[...tags]}
                                            onChange={handleTagsChange}
                                            freeSolo
                                            renderTags={(value: readonly string[], getTagProps) =>
                                                value?.map((option: string, index: number) => (
                                                    <Chip label={option} {...getTagProps({ index })} />
                                                ))
                                            }
                                            renderInput={(params) => (
                                                <TextField {...params} />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                                {/* <Grid item width={"417px"}>
                <Select isMulti name="users" defaultValue={defaultUserAccess} options={userData} onChange={handleUserAccessChange}/>
            </Grid> */}
                                <Grid item container spacing={2}>
                                    <Grid item>
                                        <Button onClick={handleUpdateBrand} variant="contained">update brand</Button>
                                    </Grid>
                                    {deletePermission &&
                                        <Grid item>
                                            <Button onClick={handleOpenErrorDialog} variant="contained" color="error">delete brand</Button>
                                        </Grid>}

                                </Grid>
                                <Grid item container height={"100px"}></Grid>
                            </Grid>
                            <Grid item xs={1.5}></Grid>
                        </Grid>
                    ) : null
            }
            {deletePermission && <Dialog open={openErrorDialog} onClose={handleCloseErrorDialog}>
                <Grid container justifyContent={"center"} direction={"column"} p={3} spacing={2}>
                    <Grid item>
                        <Typography variant='customH3'>Delete {brandName}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='customBody'>This action can not be undone</Typography>
                    </Grid>
                    <Grid item container spacing={2} justifyContent={"flex-end"}>
                        <Grid item>
                            <Button onClick={handleDeleteBrand} variant="contained" color="error">delete</Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleCloseErrorDialog} variant="contained">cancel</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Dialog>
            }

            <Dialog open={openUpdateDialog} onClose={handleCloseUpdateDialog}>
                <Grid container justifyContent={"center"} direction={"column"} p={3} spacing={2}>
                    <Grid item container justifyContent={"center"} alignItems={"center"}>
                        {updateBrandStatus === "loading" ?
                            (<>
                                <Typography variant='customBody' mr={2}>updating {brandName}</Typography>
                                <CircularProgress color='secondary' />
                            </>) : updateBrandStatus === "error" ? (<>
                                <Typography variant='customBody' mr={2}>could not update {brandName}</Typography>
                                <ErrorOutlineOutlinedIcon color='error' fontSize='large' />

                            </>) : (<>
                                <Typography variant='customBody' mr={2}>{brandName} update successful</Typography>
                                <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                            </>)}
                    </Grid>
                    {thumbnailFile ?
                        <Grid item container justifyContent={"center"} alignItems={"center"}>
                            {thumbnailFileUploadStatus === "loading" ?
                                (<>
                                    <Typography variant='customBody' mr={2}>uploading thumbnail image</Typography>
                                    <CircularProgress color='secondary' />
                                </>) : thumbnailFileUploadStatus === "error" ? (<>
                                    <Typography variant='customBody' mr={2}>could not upload thumbnail
                                        image</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />

                                </>) : thumbnailFileUploadStatus === "success" ? (<>
                                    <Typography variant='customBody' mr={2}>thumbnail image uploaded
                                        successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </>) : (<>
                                    <Typography variant="customBody" mr={2}>waiting for thumbnail image
                                        upload...</Typography>
                                </>)}
                        </Grid> : null
                    }
                </Grid>
            </Dialog>
        </>
    )
}

export default EditBrand