import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import reportWebVitals from './reportWebVitals';
import Search from './pages/Search';
import ViewVariantModel from './pages/ViewVariantModel';
import CreateModel from './pages/CreateModel';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import CreateBrand from './pages/CreateBrand';
import EditBrand from './pages/EditBrand';
import EditBaseModel from './pages/EditBaseModel';
import EditVariantModel from './pages/EditVariantModel';
import ViewBaseModel from './pages/ViewBaseModel';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import { CookiesProvider } from 'react-cookie';
import CreateEnvironmentImage from './pages/CreateEnvironmentImage';
import PushNotification from './pages/PushNotification';
import LensList from './pages/Lens/LensList';
import Version from './pages/VersionControl';

// Authentication imports
import { AuthProvider } from "qreal-auth-provider/src"
import { ProtectedRoute } from "./hooks/protectedRoute"
import Login from './pages/Login';
import { authKeys } from './enums/authKeys';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  //<React.StrictMode>
  <CookiesProvider>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AuthProvider>
          <ScrollToTop />
          <Routes>
            <Route path='/' element={<App />}>
              <Route index element={<ProtectedRoute pagePermissionKey={authKeys.list_model}><Search /></ProtectedRoute>} />
              <Route path='create-model' element={<ProtectedRoute pagePermissionKey={authKeys.create_model}><CreateModel /></ProtectedRoute>} />
              <Route path='create-brand' element={<ProtectedRoute pagePermissionKey={authKeys.create_brand}><CreateBrand /></ProtectedRoute>} />
              <Route path='brands/:brandId' element={<ProtectedRoute pagePermissionKey={authKeys.edit_brand}><EditBrand /></ProtectedRoute>} />
              <Route path='base-models/:baseModelId' element={<ProtectedRoute pagePermissionKey={authKeys.list_model}><ViewBaseModel /></ProtectedRoute>} />
              <Route path='variant-models/:variantModelId' element={<ProtectedRoute pagePermissionKey={authKeys.list_model}><ViewVariantModel /></ProtectedRoute>} />
              <Route path='create-env-img' element={<ProtectedRoute><CreateEnvironmentImage /></ProtectedRoute>} />
              <Route path='base-models/:baseModelId/edit' element={<ProtectedRoute pagePermissionKey={authKeys.list_model}><EditBaseModel /></ProtectedRoute>} />
              <Route path='variant-models/:variantModelId/edit' element={<ProtectedRoute pagePermissionKey={authKeys.edit_model}><EditVariantModel /></ProtectedRoute>} />
              <Route path='push-notification-system' element={<ProtectedRoute pagePermissionKey={authKeys.push_notification}><PushNotification /></ProtectedRoute>} />
              <Route path='lens-list' element={<ProtectedRoute pagePermissionKey={authKeys.list_lens}><LensList /></ProtectedRoute>} />
              <Route path='version' element={<ProtectedRoute><Version /></ProtectedRoute>} />
              <Route path='sign-in' element={<Login />} />
            </Route>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </CookiesProvider>
  //</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
