import { Stack, Typography } from '@mui/material'
import { useState } from 'react'
import Dropzone from 'react-dropzone'
import { CustomDropZoneProps } from './CustomDropZone.config'
import styles from './CustomDropZone.module.scss'

const CustomDropZone = (props: CustomDropZoneProps) => {
    const [dropzoneText, setDropzoneText] = useState<string>("")
    return (
        <Dropzone
            onDrop={(acceptedFiles) => {
                props.setFile(acceptedFiles)
                let text = ""
                acceptedFiles.forEach((file) => {
                    text += file.name + " - " + file.size
                })
                setDropzoneText(text)
            }}
            multiple={props.isMultiple}
            accept={props.accept}
            maxFiles={props.maxFiles}
            disabled={props.isDisabled}
        >
            {({ getRootProps, getInputProps }) => (
                <div {...getRootProps({ className: 'dropzone' })} className={styles.dropZoneInner}>
                    <input {...getInputProps()} />
                    <Stack>
                        <Typography align='center' variant='customBodyStrongCondensed'
                            sx={{ color: "#8c8c8c" }}>Drag &amp; Drop</Typography>
                        <Typography align='center' variant='customBody' sx={{ color: "#9edf34" }}>Or select
                            manually</Typography>
                        <Typography align='center' variant='customBodySm'>{dropzoneText}</Typography>
                    </Stack>
                </div>
            )}
        </Dropzone>
    )
}

export default CustomDropZone