import { Box, Button, Modal, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react'
import { Version } from '../../shared/interfaces/Version';
import VersionForm from './VersionForm';

interface EditVersionModalProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    version: Version
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const EditVersionModal = (props: EditVersionModalProps) => {
  
  const closeEditModal = () => {
    props.setOpen(false);
  }
  const handleEdit = () => {
    console.log("version edited")
    closeEditModal();
  }
  return (
   <Modal open={props.open} onClose={closeEditModal}>
    <Box sx={style}>
        <Grid2 container direction={"column"}>
            <Grid2>
                <Typography variant='customH2'>Edit Version</Typography>
            </Grid2>
            <Grid2>
                <VersionForm versionInfo={props.version}/>
            </Grid2>
        </Grid2>
    </Box>
   </Modal>
  )
}

export default EditVersionModal