import { Box, Grid, Modal, Typography } from "@mui/material"
import React from "react"
import CustomLightButton from "../CustomButton/CustomButton"

import { Props } from './WarningPrompt.config'
import styles from './WarningPrompt.module.css'

const WarningPrompt = ({ isOpen, onConfirm, setIsOpen, promptMessage }: Props) => {
    return (
        <Modal open={isOpen} onClose={() => { setIsOpen(false) }}>
            <Box className={styles.warningBox}>
                <Grid container>
                    <Grid item xs={1} />

                    <Grid item container xs={10} direction={'column'} marginTop={'50px'} spacing='20px'>
                        <Grid item> <Typography variant='customH3'> {promptMessage} </Typography> </Grid>
                        <Grid item container direction={'row'} spacing={2} justifyContent={'flex-end'}>
                            <Grid item> <CustomLightButton onClick={() => { onConfirm(); }}> SEND </CustomLightButton> </Grid>
                            <Grid item> <CustomLightButton onClick={() => { setIsOpen(false) }}> CANCEL </CustomLightButton> </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={1} />
                </Grid>
            </Box>
        </Modal>
    )
}

export default WarningPrompt