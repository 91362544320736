import { Box, Button, Modal, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react'
import { Version } from '../../shared/interfaces/Version';

interface DeleteVersionModalProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    version: Version
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

const DeleteVersionModal = (props: DeleteVersionModalProps) => {

  const closeDeleteModal = () => {
    props.setOpen(false);
  }
  const handleDelete = () => {
    console.log("version deleted");
    closeDeleteModal();
  }
  return (
    <Modal open={props.open} onClose={closeDeleteModal}>
      <Box sx={style}>
        <Grid2 container direction={"column"} spacing={2}>
            <Grid2>
                <Typography variant='customH3' align='center' color={"error"}>WARNING !</Typography>
            </Grid2>
            <Grid2>
                <Typography align='center' variant='customBodyCondensed'>Are you sure you want to delete this version?</Typography>
            </Grid2>
            <Grid2>
                <Grid2 container direction={"column"} pt={0.7} alignItems={"center"} spacing={0.5}>
                <Grid2>
                    <Typography variant='customBodySm'>build number: {props.version.build_number}</Typography>
                </Grid2>
                <Grid2>
                    <Typography variant='customBodySm'>version: {props.version.version}</Typography>
                </Grid2>
                <Grid2>
                <Typography variant='customBodySm'>description: {props.version.description}</Typography>
                </Grid2>
                <Grid2>
                    <Typography variant='customBodySm'>type: {props.version.type}</Typography>
                </Grid2>
                <Grid2>
                    <Typography variant='customBodySm'>update_date: {props.version.update_date}</Typography>
                </Grid2>
                <Grid2>
                    <Typography variant='customBodySm' sx={props.version.state === "PRODUCTION" ? {color:"green"} : props.version.state === "APP REVIEW" ? {color: "orange"} : props.version.state === "REJECTED" ? {color:"red"} : null}>state: {props.version.state}</Typography>
                </Grid2>
                </Grid2>
            </Grid2>
            <Grid2>
                <Grid2 container direction={"row"} justifyContent="center">
                    <Grid2>
                        <Button variant='contained' color='primary' onClick={closeDeleteModal}>Cancel</Button>
                    </Grid2>
                    <Grid2>
                        <Button variant='contained' color='error' onClick={handleDelete}>Yes, Delete</Button>
                    </Grid2>
                </Grid2>
            </Grid2>
        </Grid2>
      </Box>
    </Modal>
  )
}

export default DeleteVersionModal