import axios from "axios";
import {useInfiniteQuery, useMutation, useQuery} from "@tanstack/react-query";
import {baseURL, results_per_page} from "../constants";
import {SovrnLink, VariantModel, VariantModelData, VariantModelList, VariantModelUploadData} from "../interfaces/VariantModel";
import { getTokenFromStorage } from "./AuthService";

const getVariantModels = async ({pageParam = 1}): Promise<VariantModelList> => {
    const {data} = await axios.get(`${baseURL}/variant-models?per_page=${results_per_page}&page=${pageParam}`, {
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
    console.log(data);
    return data;
}

export const useGetVariantModels = () => {
    return useInfiniteQuery<VariantModelList, Error>(['variant-models'], getVariantModels,
    {
        getNextPageParam: (pages) => 
        {
          if(pages.data.length > 0){
            return pages.page + 1
          }else {
            return undefined
          }
        },
    }
    );
}

const getVariantModelById = async (variant_model_id: string): Promise<VariantModel> => {
    const {data} = await axios.get(`${baseURL}/variant-models/${variant_model_id}`, {
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
    console.log(data);
    return data;
}

export const useGetVariantModelById = (variant_model_id: string) => {
    return useQuery<VariantModel, Error>(['variant-model', variant_model_id], () => getVariantModelById(variant_model_id), {cacheTime: 1000});
}


const createVariantModel = async (variant_model: VariantModelUploadData) => {
    return await axios.post(`${baseURL}/variant-models?is_debug=true`, variant_model, {
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
}

export const useCreateVariantModel = () => {
    return useMutation(createVariantModel, {
        onSuccess: () => {
            console.log("model added");
        },
        onError: () => {
            console.log("error")
        },
    })
}

const updateVariantModel = async (updated_variant_model: VariantModelUploadData) => {
    return await axios.put(`${baseURL}/variant-models/${updated_variant_model._id}`, updated_variant_model, {
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
}

export const useUpdateVarianteModel = () => {
    return useMutation(updateVariantModel, {
        onSuccess: () => {
            console.log("variant model updated");
        },
        onError: () => {
            console.log("error")
        },
    })
}

const deleteVariantModel = async (_id: string) => {
    return await axios.delete(`${baseURL}/variant-models/${_id}`, {
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
}

export const useDeleteVariantModel = () => {
    return useMutation(deleteVariantModel, {
        onSuccess: () => {
            console.log("variant model deleted");
        },
        onError: () => {
            console.log("error")
        },
    })
}

const getVariantModelListByBaseModelId = async (base_model_id: string): Promise<VariantModelList> => {
    const {data} = await axios.get(`${baseURL}/variant-models?list_all=true&base_model_id=${base_model_id}`, {
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
    console.log(data);
    return data;
}

export const useGetVariantModelListByBaseModelId = (base_model_id: string) => {
    return useQuery<VariantModelList, Error>(['variant-model-list-by-base-model-id', base_model_id], () => getVariantModelListByBaseModelId(base_model_id), {cacheTime: 1000});
}

const createSovrnLink = async (url: string): Promise<SovrnLink> => {
    const {data} = await axios.get(`${baseURL}/variant-models/sovrn?unAffiliatedUrl=${url}`, {
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
    console.log(data);
    return data;
}

export const useCreateSovrnLink = (url: string) => {
    return useQuery<SovrnLink, Error>(['create-sovrn-link', url], () => createSovrnLink(url), {enabled: false})
}