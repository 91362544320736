import React, { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import { TableData } from "../../components/BasicTable/BasicTable.config";
import BasicTable from "../../components/BasicTable/BasicTable";
import { Grid, Tooltip } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { getLensListWithPagination, getVariantLenses, sendDeleteLens } from "../../shared/query-hooks/LensService";
import styles from './LensList.module.css';
import { getDateString } from "../../assets/scripts/date";
import WarningPrompt from "../../components/WarningPrompt/WarningPrompt";
import { Lens } from "./Lens.config";
import moment from "moment";
import { useAuth } from "qreal-auth-provider/src";
import { checkAuth, authKeys } from "../../enums/authKeys";

const LensList = ({ variantModelId = '' }) => {
    const [paginationInfo, setPaginationInfo] = useState({ current: 0, pageSize: 20, totalCount: 15 });
    const [lensList, setLensList] = useState<TableData | any>([]);
    const [selectedLens, setSelectedLens] = useState({} as Lens);
    const [noDataErrorMessage, setNoDataErrorMessage] = useState('');
    const { authList } = useAuth();
    const deletePermission = checkAuth(authList, authKeys.delete_lens);

    // Initial load
    useEffect(() => {
        updateTableData();
    }, [paginationInfo.current])

    // Delete selected lens after confirmation
    const deleteLens = () => {
        if (!deletePermission) return;
        sendDeleteLens(variantModelId || selectedLens?.variant_model_detail[0]?._id, selectedLens?._id).then((res) => {
            setSelectedLens({} as Lens);
            updateTableData();
        });
    }

    // Page table list update function
    const updateTableData = () => {
        setNoDataErrorMessage('');
        if (variantModelId) {
            getVariantLenses(variantModelId).then((response) => { return response.data }).then((response) => {
                if (response.data.length > 0) {
                    console.log(response)
                    const tableData = [
                        { title: 'Name', rowList: response.data?.map((item: any) => item.lens_name) },
                        {
                            title: 'Created At', rowList: response.data?.map((item: any) => {
                                return (
                                    <Tooltip title={getDateString(item.created_at)} placement={"top"}>
                                        <div>
                                            {moment(item.created_at).fromNow()}
                                        </div>
                                    </Tooltip>
                                )
                            })
                        },
                        {
                            title: 'Updated At', rowList: response.data?.map((item: any) => {
                                return (
                                    <Tooltip title={getDateString(item.updated_at)} placement={"top"}>
                                        <div>
                                            {moment(item.updated_at).fromNow()}
                                        </div>
                                    </Tooltip>
                                )
                            })
                        },
                        {
                            title: 'Action', rowList: response.data?.map((item: any) => {
                                return (
                                    deletePermission && <div onClick={() => { setSelectedLens(item) }} className={styles.deleteButton}>
                                        <DeleteIcon color="error" />
                                    </div>
                                )
                            })
                        }
                    ]
                    setLensList(tableData)
                } else {
                    setNoDataErrorMessage('No lens found');
                }
            })
        } else {
            getLensListWithPagination({
                page: paginationInfo.current + 1,
                per_page: paginationInfo.pageSize
            }).then((response) => { return response.data }).then((response) => {
                renderTableWithResponse(response);
            })
        }
    }

    const renderTableWithResponse = (response: any) => {
        console.log(response)
        if (response.data && response.data.length > 0) {
            const tableData = [
                { title: 'Name', rowList: response.data?.map((item: any) => item.lens_name) },
                {
                    title: 'Variant Model Name', rowList: response.data?.map((item: any) => {
                        const variant = item.variant_model_detail[0];
                        return (
                            <a href={`/variant-models/${variant?._id}`} className={styles.variantLink}>{variant?.variant_model_name}</a>
                        )
                    })
                },
                {
                    title: 'Category', rowList: response.data?.map((item: any) => {
                        const variant = item.variant_model_detail[0];
                        return (
                            <div>{variant?.category}</div>
                        )
                    })
                },
                {
                    title: 'Created At', rowList: response.data?.map((item: any) => {
                        return (
                            <Tooltip title={getDateString(item.created_at)} placement={"top"}>
                                <div>
                                    {moment(item.created_at).fromNow()}
                                </div>
                            </Tooltip>
                        )
                    })
                },
                {
                    title: 'Updated At', rowList: response.data?.map((item: any) => {
                        return (
                            <Tooltip title={getDateString(item.updated_at)} placement={"top"}>
                                <div>
                                    {moment(item.updated_at).fromNow()}
                                </div>
                            </Tooltip>
                        )
                    })
                },
                {
                    title: 'Action', rowList: response.data?.map((item: any) => {
                        return (
                            deletePermission ? <div onClick={() => { setSelectedLens(item) }} className={styles.deleteButton}>
                                <DeleteIcon color="error" />
                            </div> : 'Not Allowed'
                        )
                    })
                }
            ]
            setPaginationInfo((prevState) => { return { ...prevState, totalCount: response.total_count || 0 } })
            setLensList(tableData)
        } else {
            setNoDataErrorMessage('No lenses found');
        }
    }

    const historyPageUpdate = (page: number) => { setLensList([]); setPaginationInfo((prevState) => { return { ...prevState, current: page } }); }

    return <>
        {
            !variantModelId && <Header header='Lens Listing System'></Header>
        }

        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={variantModelId ? 12 : 11} pt={5}>
                <BasicTable
                    pagination={variantModelId ? false : true}
                    paginationInfo={paginationInfo}
                    tableData={lensList}
                    handleChangePage={historyPageUpdate}
                    noDataMessage={noDataErrorMessage}
                />
            </Grid>
        </Grid>
        <WarningPrompt
            isOpen={Object.keys(selectedLens).length > 0}
            onConfirm={() => { deleteLens(); }}
            setIsOpen={() => { setSelectedLens({} as Lens) }}
            promptMessage={'Are you sure you want to delete this lens?'}
        />
    </>
};

export default LensList;
