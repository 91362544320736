import { Autocomplete, Grid, MenuItem, Select, SelectChangeEvent, Slider, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { ModelViewerConfig, VariantModelUploadData } from '../../../shared/interfaces/VariantModel';
import { useGetEnvironmentImageList } from '../../../shared/query-hooks/EnvironmentImageService';
import {Option} from '../../../shared/interfaces/Enums'
import { Props } from './ModelViewerConfigForm.config'
import _ from 'lodash';
import CustomLightButton from '../../CustomButton/CustomButton';
import { useUpdateVarianteModel } from '../../../shared/query-hooks/VariantModelService';
import UpdateModal from '../../modals/UpdateModal/UpdateModal';

const ModelViewerConfigForm = ({exposure,setExposure,shadowIntensity,setShadowIntensity,shadowSoftness,setShadowSoftness, envImgId, setEnvImgId, baseModelId, variantModelId}:Props) => {
  
  const [modelViewerConfig, setModelViewerConfig] = useState<ModelViewerConfig[]>([])
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  
  //get env-img data for env-img-select
  const [envImgs, setEnvImgs] = useState<Option[]>([]);
  const {data: allEnvImgData, status: allEnvImgStatus} = useGetEnvironmentImageList();
  useEffect(() => {
      if (allEnvImgStatus === "success") {
          const tempArray: Option[] = []
          allEnvImgData.data.forEach((envImg, index) => {
              tempArray[index] = {value: envImg._id, label: envImg.environment_name}
          })
          setEnvImgs(tempArray);
      }
  }, [allEnvImgStatus])

  const handleHDRChange = (event: SelectChangeEvent) => {
    setEnvImgId(event.target.value as string);
  };

  const {
    handleSubmit,
    control,
    formState: {errors},
    setValue
  } = useForm()

  useEffect(() => {
    setValue("exposure", exposure);
    setValue("shadowIntensity", shadowIntensity);
    setValue("shadowSoftness", shadowSoftness);
    setValue("envImg", envImgId);
    setModelViewerConfig([{exposure: exposure.toString(), "shadow-intensity": shadowIntensity.toString(), "shadow-softness": shadowSoftness.toString()}])
  }, [exposure])

  //variant-model update/delete
  const {
    mutate: updateVariantModelMutate,
    status: updateVariantModelStatus,
    data: updateVariantModelResponseData
  } = useUpdateVarianteModel();
  
  const onSubmit: SubmitHandler<any> = (data:any) => {
    const updateMVConfigData: VariantModelUploadData = {
        _id: variantModelId,
        base_model_id: baseModelId,
        model_viewer_config: modelViewerConfig,
        environment_id: data.envImg,
    }
    updateVariantModelMutate(updateMVConfigData);
    setOpenUpdateModal(true);
  }
  
  return (
    <>
    <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container>
            <Grid item container direction={"column"}>
                <Grid item>
                    <Typography>Exposure: </Typography>
                </Grid>
                <Grid item>
                    <Controller
                        render={(({field}) => <Slider {...field} sx={{width:200}} size="small" color='secondary' value={exposure} onChange={(_, value) => {
                            field.onChange(value);
                            setExposure(value as number)
                            setModelViewerConfig([{exposure: value.toString(), "shadow-intensity": shadowIntensity.toString(), "shadow-softness": shadowSoftness.toString()}])
                        }} valueLabelDisplay="auto" min={0} max={2} step={0.05}/>)}
                        name="exposure"
                        control={control}
                    />
                </Grid>
            </Grid>
            <Grid item container direction={"column"}>
                <Grid item>
                    <Typography>Shadow Intensity: </Typography>
                </Grid>
                <Grid item>
                    <Controller
                        render={(({field}) => <Slider {...field} sx={{width:200}} size="small" color='secondary' value={shadowIntensity} onChange={(_, value) => {
                            field.onChange(value);
                            setShadowIntensity(value as number)
                            setModelViewerConfig([{exposure: exposure.toString(), "shadow-intensity": value.toString(), "shadow-softness": shadowSoftness.toString()}])
                        }} valueLabelDisplay="auto" min={0} max={1} step={0.05}/>)}
                        name="shadowIntensity"
                        control={control}
                    />
                </Grid>
            </Grid>
            <Grid item container direction={"column"}>
                <Grid item>
                    <Typography>Shadow Softness: </Typography>
                </Grid>
                <Grid item>
                    <Controller
                        render={(({field}) => <Slider {...field} sx={{width:200}} size="small" color='secondary' value={shadowSoftness} onChange={(_, value) => {
                            field.onChange(value);
                            setShadowSoftness(value as number)
                            setModelViewerConfig([{exposure: exposure.toString(), "shadow-intensity": shadowIntensity.toString(), "shadow-softness": value.toString()}])
                        }} valueLabelDisplay="auto" min={0} max={1} step={0.05}/>)}
                        name="shadowSoftness"
                        control={control}
                    />
                </Grid>
            </Grid>
            {allEnvImgStatus === "success" ? 
            <Grid item container spacing={2} alignItems={"center"}>
               <Grid item>
                <Typography>HDR: </Typography>
               </Grid>
               <Grid item flexGrow={1}>
                <Controller render={({field}) => (
                        <Select sx={{width:"100%", maxWidth:"175px"}} {...field} size="small" onChange={(e) => {field.onChange(e); handleHDRChange(e)}}>
                            {envImgs?.map((envImg,index) => (
                                <MenuItem key={index} value={envImg.value}>{envImg.label}</MenuItem>
                            ))}
                        </Select>
                    )}
                    name="envImg"
                    control={control}/>
               </Grid>
            </Grid> : null}
            <Grid item container my={1}>
                <CustomLightButton type='submit'>SAVE</CustomLightButton>
            </Grid>
        </Grid>
    </form>
    <UpdateModal updateStatus={updateVariantModelStatus} openUpdateModal={openUpdateModal} setOpenUpdateModal={setOpenUpdateModal}/>
    </>
  )
}

export default ModelViewerConfigForm