import { Box, Grid, Typography } from '@mui/material';

import { Properties } from './BrandBox.config';
import CardContainer from '../CardContainer/CardContainer';

function BrandBox(props: Properties) {
    return (
        <CardContainer link={`brands/${props.id}`} thumbnail_url={props.image}>
            <Box style={{ backgroundColor: '#EFEFEF', width: "261px", height: "62px",     padding: 0,
    justifyContent: 'end' }}>
                <Grid item container style={{ height: '100%' }} direction={"column"} justifyContent='center' alignItems='center'>
                    <Typography variant="customBodyStrongCondensed" pb={0.5}>{props.brand}</Typography>
                    <Typography variant="customBodySm">{props.model_count} Models available</Typography>
                </Grid>
            </Box>
        </CardContainer>
    );
}

export default BrandBox;
