import { Box, Grid, Typography } from '@mui/material'
import CustomChip from '../CustomChip'
import { Link } from 'react-router-dom';
import { BaseModelCardProps } from './BaseModelCard.config';
import styles from './BaseModelCard.module.scss'
import CardContainer from '../CardContainer/CardContainer';

const BaseModelCard = (props: BaseModelCardProps) => {

    return (
        <CardContainer link={`/base-models/${props.baseModel!._id}`} thumbnail_url={props.baseModel?.thumbnail_file[0].url || ''}>
            <Grid sx={{backgroundColor:"#EFEFEF", borderRadius:"0px 0px 5px 5px"}} direction={"column"} height={"50%"} p={2}>
                    <Grid item container direction={"column"} spacing={0.5}>
                        <Grid item>
                            <Typography variant='customBodyStrongCondensed'>{props.baseModel?.brand_detail[0].brand_name}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant='customBodyStrongCondensed'>{props.baseModel?.model_name}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container direction='row' spacing={'8px'} pt={1}>
                        {/* {props.baseModel!.tags?.map((tag, index) => (
                            <Grid item key={index}>
                                <CustomChip key={index} title={tag} color='#FFFFFF' bgColor='#000000' isFilled={true}></CustomChip>
                            </Grid>
                        ))} */}
                        <Grid item>
                            <CustomChip title={props.baseModel!.category} color='#FFFFFF' bgColor='#000000' isFilled={true}></CustomChip>
                        </Grid>
                        <Grid item>

                            {
                                props.baseModel?.model_status && 
                                <CustomChip title={props.baseModel?.model_status} color='#000000' isFilled={false} status={props.baseModel?.model_status.toLowerCase() as "no-status" | "active" | "passive"} />
                            }
                                                         
                        </Grid>
                    </Grid>
                </Grid>
        </CardContainer>
    )
}

export default BaseModelCard;
