import { baseURL } from "../constants";
import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { getTokenFromStorage } from "./AuthService";
import { EnvironmentImage, EnvironmentImageList, EnvironmentImageUploadData } from "../interfaces/EnvironmentImage";

const getEnvironmentImageList = async (): Promise<EnvironmentImageList> => {
    const {data} = await axios.get(`${baseURL}/env/?list_all=true`, {
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
    console.log(data);
    return data;
}

export const useGetEnvironmentImageList = () => {
    return useQuery<EnvironmentImageList, Error>(['environment-image-list'], getEnvironmentImageList, {cacheTime:1000});
}

const getEnvironmentImageById = async (_id: string): Promise<EnvironmentImage> => {
    const {data} = await axios.get(`${baseURL}/env/${_id}`, {
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
    console.log(data);
    return data;
}

export const useGetEnvironmentImageById = (_id: string) => {
    return useQuery<EnvironmentImage, Error>(['environment-image', _id], () => getEnvironmentImageById(_id), {cacheTime:1000})
}

const createEnvironmentImage = async (environment_image: EnvironmentImageUploadData) => {
    return await axios.post(`${baseURL}/env`, environment_image, {
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
}

export const useCreateEnvironmentImage = () => {
    return useMutation(createEnvironmentImage, {
        onSuccess: () => {
            console.log("env img added");
        },
        onError: () => {
            console.log("error")
        },
    })
}

const updateEnvironmentImage = async (environment_image: EnvironmentImageUploadData) => {
    return await axios.put(`${baseURL}/env/${environment_image._id}`, environment_image, {
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
}

export const useUpdateEnvironmentImage = () => {
    return useMutation(updateEnvironmentImage, {
        onSuccess: () => {
            console.log("env img updated");
        },
        onError: () => {
            console.log("error")
        },
    })
}

const deleteEnvironmentImage = async (_id: string) => {
    return await axios.delete(`${baseURL}/env/${_id}`, {
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
}

export const useDeleteEnvironmentImage = () => {
    return useMutation(deleteEnvironmentImage, {
        onSuccess: () => {
            console.log("env img deleted");
        },
        onError: () => {
            console.log("error")
        },
    })
}

