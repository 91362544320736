import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { baseURL } from "../constants";
import { UserList } from "../interfaces/User";
import { getTokenFromStorage } from "./AuthService";

const getUserList = async (): Promise<UserList> => {
    const {data} = await axios.get(`${baseURL}/users?list_all=true`,{
        headers: {
            "Authorization" : `Bearer ${getTokenFromStorage()}`
        }
    })
    console.log(data);
    return data;
}

export const useGetUserList = () => {
    return useQuery<UserList, Error>(['userList'], getUserList);
}