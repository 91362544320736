import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { baseURL, results_per_page } from "../constants";
import { Brand, BrandData, BrandList, UpdateBrandData } from "../interfaces/Brand";
import { getTokenFromStorage } from "./AuthService";

const getAllBrands = async (): Promise<BrandList> => {
    const {data} = await axios.get(
        `${baseURL}/brands?list_all=true`,
        {
            headers: {
                "Authorization" : `Bearer ${getTokenFromStorage()}`
            }
        });
    
    return data;
}

export const useGetAllBrands = () => {
    return useQuery<BrandList,Error>(['brands'],getAllBrands)
}

const getPaginatedBrands = async ({pageParam = 1}): Promise<BrandList> => {
    const {data} = await axios.get(
        `${baseURL}/brands?per_page=${results_per_page}&page=${pageParam}`,
        {
            headers: {
                "Authorization" : `Bearer ${getTokenFromStorage()}`
            }
        });
    
    return data;
}

export const useGetPaginatedBrands = () => {
    return useInfiniteQuery<BrandList,Error>(['brands'],getPaginatedBrands,
    {
        getNextPageParam: (pages) => 
        {
          if(pages.data.length > 0){
            return pages.page + 1
          }else {
            return undefined
          }
        },
    })
}

const getBrandById = async (brand_id: string): Promise<Brand> => {
    const {data} = await axios.get(`${baseURL}/brands/${brand_id}`, {
        headers: {
            "Authorization" : `Bearer ${getTokenFromStorage()}`
        }
    }
    );
    
    return data;
}

export const useGetBrandById = (brand_id: string) => {
    return useQuery<Brand, Error>(['getBrand',brand_id], () => getBrandById(brand_id), {cacheTime: 1000});
}

const createBrand = async (brand: BrandData) => {
    return await axios.post(`${baseURL}/brands`, brand, {
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
}

export const useCreateBrand = () => {
    return useMutation(createBrand, {
        onSuccess: () => {
            console.log("brand added");
        },
        onError: () => {
            console.log("error")
        },
    })
}

const updateBrand = async (updated_brand: UpdateBrandData) => {
    return await axios.put(`${baseURL}/brands/${updated_brand._id}`, updated_brand, {
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
}

export const useUpdateBrand = () => {
    return useMutation(updateBrand, {
        onSuccess: () => {
            console.log("brand updated");
        },
        onError: () => {
            console.log("error")
        },
    })
}

const deleteBrand = async (_id: string) => {
    return await axios.delete(`${baseURL}/brands/${_id}`,{
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    } )
}

export const useDeleteBrand = () => {
    return useMutation(deleteBrand, {
        onSuccess: () => {
            console.log("brand deleted");
        },
        onError: () => {
            console.log("error")
        },
    })
}