/**
 * 
 * @param {*} dateString 
 * @returns {String}
 */
// Output: DD Month, HH:MM
export const getDateString = (dateString) => {
    const d = new Date(dateString);
    const month = d.toLocaleString('en-US', { month: 'long' });
    const day = d.getDate();
    const hours = d.getHours();
    const minutes = d.getMinutes();
    return `${day} ${month}, ${hours}:${('0' + minutes).slice(-2)}`;
}
