import { Outlet } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './shared/theme';
import './App.scss';
import { CssBaseline } from '@mui/material';

function App() {

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline>
        <Outlet />
      </CssBaseline>
    </ThemeProvider>
  );
}

export default App;
