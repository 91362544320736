import React, { Fragment, useEffect, useState } from 'react';
import { Grid, Stack, Tab, Tabs, Typography, CircularProgress } from '@mui/material';
import CustomLightButton from '../components/CustomButton/CustomButton';
import SearchBlock from '../components/SearchBlock/SearchBlock';
import ProductBox from '../components/ProductBox/ProductBox';
import BrandBox from '../components/BrandBox/BrandBox';
import { Link, useNavigate } from 'react-router-dom';
import { useGetPaginatedBrands } from '../shared/query-hooks/BrandService';
import { useGetPaginatedlBaseModels } from '../shared/query-hooks/ModelService';
import BaseModelCard from '../components/BaseModelCard/BaseModelCard';
import { useInView } from 'react-intersection-observer'
import { ModelListData } from '../shared/interfaces/Model';
import { BrandData } from '../shared/interfaces/Brand';
import { useSearch } from '../shared/query-hooks/SearchService';
import useDebounce from '../shared/utils/useDebounce';
import { debouncedSearchDelay } from '../shared/constants';
import { useAuth } from 'qreal-auth-provider/src';
import { authKeys, checkAuth } from '../enums/authKeys';
import MenuList from '../components/Header/Menu';
import { VariantModelListData } from '../shared/interfaces/VariantModel';
import { ProductStatuses, QueryStatuses } from '../enums/statuses';

function Search() {
  let navigate = useNavigate();
  const { authList } = useAuth();

  const [value, setValue] = React.useState(0);
  const [showHats, setShowHats] = useState(true)
  const [showEyewear, setShowEyewear] = useState(true)
  const [showShoes, setShowShoes] = useState(true)
  const [showWatches, setShowWatches] = useState(true)
  const [showPassives, setShowPassives] = useState(false)
  const [searchText, setSearchText] = useState("");

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Grid container direction={"column"} marginBottom="52px">
        <Grid item container sx={{ height: "232px", backgroundColor: "#EFEFEF" }}>
          <Grid item xs={1.5}></Grid>
          <Grid item container xs={9} pt={7} justifyContent={"space-between"}>
            <Grid item xs={2} container alignItems={"center"}>
              <Typography variant="customH3"> {'TRYO'} </Typography>
            </Grid>
            <Grid item xs={'auto'} container alignItems={"center"}>
              <Tabs value={value} onChange={handleChange} indicatorColor='secondary'>
                <Tab label="VARIANT MODELS" value={0} />
                <Tab label="BASE MODELS" value={1} />
                {checkAuth(authList, authKeys.list_brand) && <Tab label="BRANDS" value={2} />}
              </Tabs>
            </Grid>
            <Grid item xs={'auto'} container alignItems={"center"} justifyContent='end'>
              <MenuList />
            </Grid>
            <Grid item container alignItems={"center"} justifyContent='end'>
              {checkAuth(authList, authKeys.search_model) && <Grid item container pt={4}>
                <SearchBlock
                  setShowPassives={setShowPassives}
                  showPassives={showPassives}
                  showHats={showHats}
                  showEyewear={showEyewear}
                  showShoes={showShoes}
                  showWatches={showWatches}
                  setShowHats={setShowHats}
                  setShowEyewear={setShowEyewear}
                  setShowShoes={setShowShoes}
                  setShowWatches={setShowWatches}
                  setSearchText={setSearchText}
                />
              </Grid>}
            </Grid>
          </Grid>
          <Grid item xs={1.5}></Grid>
        </Grid>
        <Grid item container>
          <Grid item xs={1.5}></Grid>
          <Grid item container xs={9} mt={12} spacing={4} justifyContent={"center"}>
            {value == 0 ? <VariantModels
              showPassives={showPassives}
              showHats={showHats}
              showEyewear={showEyewear}
              showShoes={showShoes}
              showWatches={showWatches}
              searchText={searchText} /> : value === 1 ? <BaseModels showPassives={showPassives} /> : value === 2 ? <Brands /> : null}
          </Grid>
          <Grid item xs={1.5}></Grid>
        </Grid>
      </Grid>
    </>
  )
}

interface VariantModelTabProps {
  showPassives: boolean,
  showHats: boolean,
  showEyewear: boolean,
  showShoes: boolean,
  showWatches: boolean,
  searchText: string
}

const VariantModels = (props: VariantModelTabProps) => {

  const debouncedSearch = useDebounce(props.searchText, debouncedSearchDelay);
  const { status: searchStatus, data: searchData, refetch: searchRefetch } = useSearch(debouncedSearch);

  const filterPassives = (data: VariantModelListData[]) => {
    if (data) {
      return data.filter((item) => {
        if (!props.showPassives) {
          return item.variant_model_status !== "PASSIVE"
        } else {
          return item.variant_model_status === ProductStatuses.PASSIVE
        }
      });
    } else {
      return []
    }
  }
  return (
    <>
      {
        searchStatus === QueryStatuses.LOADING ?
          <Grid container direction={'column'} alignItems={'center'} spacing={'32px'}>
            <Grid item> <Typography mt={10} variant="customH3">Loading Variant Model Data</Typography>  </Grid>
            <Grid item> <CircularProgress color="secondary" /> </Grid>
          </Grid> :
          searchStatus === QueryStatuses.ERROR ?
            <Typography mt={20} variant="customH3">Error on Loading Variant Model Data</Typography> :
            searchStatus === QueryStatuses.SUCCESS ?
              <Grid container direction={"column"}>
                {props.showHats ?
                  <Grid item container direction={"column"} mt={2}>
                    <Grid item container justifyContent={"center"} alignItems={"center"} spacing={1}>
                      <Grid item>
                        <Typography variant='customH3'>HATS</Typography>
                      </Grid>
                      <Grid item>
                        {filterPassives(searchData?.data[0].HATS).length > 0 ?
                          <Typography variant='customBodyXsCondensed'>{filterPassives(searchData?.data[0].HATS).length!} models available</Typography>
                          :
                          null
                        }
                      </Grid>
                    </Grid>
                    <Grid item container pt={2} spacing={4} justifyContent={"center"}>
                      {
                        (<>
                          {searchData.data[0].HATS?.length > 0 ?
                            <>
                              {filterPassives(searchData.data[0].HATS)?.map((variantModel, index) => (
                                <Grid item key={index}>
                                  <ProductBox model={variantModel} />
                                </Grid>
                              ))}
                            </> :
                            <Typography mt={2} variant='customBodyCondensed'>no variant model found for {props.searchText}</Typography>
                          }
                        </>)
                      }
                    </Grid>
                  </Grid>
                  :
                  null
                }
                {props.showEyewear ?
                  <Grid item container direction={"column"} mt={2}>
                    <Grid item container justifyContent={"center"} alignItems={"center"} spacing={1}>
                      <Grid item>
                        <Typography variant='customH3'>EYEWEAR</Typography>
                      </Grid>
                      <Grid item>
                        {filterPassives(searchData?.data[0].EYEWEAR).length > 0 ?
                          <Typography variant='customBodyXsCondensed'>{searchData?.data[0].EYEWEAR.length!} models available</Typography>
                          :
                          null
                        }
                      </Grid>
                    </Grid>
                    <Grid item container pt={2} spacing={4} justifyContent={"center"}>
                      {
                        (<>
                          {filterPassives(searchData.data[0].EYEWEAR).length > 0 ?
                            <>
                              {filterPassives(searchData.data[0].EYEWEAR)?.map((variantModel, index) => (
                                <Grid item key={index}>
                                  <ProductBox model={variantModel} />
                                </Grid>
                              ))}
                            </> :
                            <Typography mt={2} variant='customBodyCondensed'>no variant model found for {props.searchText}</Typography>
                          }
                        </>)
                      }
                    </Grid>
                  </Grid>
                  :
                  null
                }
                {props.showShoes ?
                  <Grid item container direction={"column"} mt={2}>
                    <Grid item container justifyContent={"center"} alignItems={"center"} spacing={1}>
                      <Grid item>
                        <Typography variant='customH3'>SHOES</Typography>
                      </Grid>
                      <Grid item>
                        {filterPassives(searchData?.data[0].SHOES).length > 0 ?
                          <Typography variant='customBodyXsCondensed'>{filterPassives(searchData?.data[0].SHOES).length!} models available</Typography>
                          :
                          null
                        }
                      </Grid>
                    </Grid>
                    <Grid item container pt={2} spacing={4} justifyContent={"center"}>
                      {
                        (<>
                          {filterPassives(searchData?.data[0].SHOES).length > 0 ?
                            <>
                              {filterPassives(searchData.data[0].SHOES)?.map((variantModel, index) => (
                                <Grid item key={index}>
                                  <ProductBox model={variantModel} />
                                </Grid>
                              ))}
                            </> :
                            <Typography mt={2} variant='customBodyCondensed'>no variant model found for {props.searchText}</Typography>
                          }
                        </>)
                      }
                    </Grid>
                  </Grid>
                  :
                  null
                }
                {props.showWatches ?
                  <Grid item container direction={"column"} mt={2}>
                    <Grid item container justifyContent={"center"} alignItems={"center"} spacing={1}>
                      <Grid item>
                        <Typography variant='customH3'>WATCHES</Typography>
                      </Grid>
                      <Grid item>
                        {filterPassives(searchData?.data[0].WATCHES).length > 0 ?
                          <Typography variant='customBodyXsCondensed'>{filterPassives(searchData?.data[0].WATCHES).length!} models available</Typography>
                          :
                          null
                        }
                      </Grid>
                    </Grid>
                    <Grid item container pt={2} spacing={4} justifyContent={"center"}>
                      {
                        (<>
                          {filterPassives(searchData?.data[0].WATCHES).length > 0 ?
                            <>
                              {filterPassives(searchData.data[0].WATCHES)?.map((variantModel, index) => (
                                <Grid item key={index}>
                                  <ProductBox model={variantModel} />
                                </Grid>
                              ))}
                            </> :
                            <Typography mt={2} variant='customBodyCondensed'>no variant model found for {props.searchText}</Typography>
                          }
                        </>)
                      }
                    </Grid>
                  </Grid>
                  :
                  null
                }
              </Grid> : null
      }

    </>
  );
}

const BaseModels = (props: any) => {
  const { ref, inView } = useInView()
  const { data: baseModelData, hasNextPage, fetchNextPage, status: baseModelStatus } = useGetPaginatedlBaseModels();

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView])

  return (
    <>
      {
        baseModelStatus === QueryStatuses.LOADING ?
          <Grid container direction={'column'} alignItems={'center'} spacing={'32px'}>
            <Grid item> <Typography mt={20} variant="customH3">Loading Base Model Data</Typography> </Grid>
            <Grid item> <CircularProgress color="secondary" /> </Grid>
          </Grid> :
          baseModelStatus === QueryStatuses.ERROR ?
            <Typography mt={20} variant="customH3">Error on Loading Base Model Data</Typography> :
            baseModelStatus === QueryStatuses.SUCCESS ?
              (
                <>
                  {baseModelData.pages?.map((group, i) => (
                    <Fragment key={i}>
                      {
                        group.data.filter(item => {
                          if (!props.showPassives) {
                            return true
                          } else {
                            return item.model_status === ProductStatuses.PASSIVE
                          }
                        })?.map((model: ModelListData) => (
                          <Grid item key={model._id}>
                            <BaseModelCard baseModel={model} />
                          </Grid>
                        ))
                      }
                    </Fragment>
                  ))}
                  <Grid container justifyContent={"center"} py={3}>
                    <CustomLightButton ref={ref} disabled={!hasNextPage} onClick={() => { fetchNextPage() }}>{hasNextPage ? "load more" : "nothing more to see"}</CustomLightButton>
                  </Grid>
                </>
              ) : null
      }
    </>
  )
}

const Brands = () => {
  const { ref, inView } = useInView()
  const { data: brandData, hasNextPage, fetchNextPage, status: brandStatus } = useGetPaginatedBrands();

  useEffect(() => {
    if (inView) {
      fetchNextPage()
    }
  }, [inView])

  return (
    <>
      {
        brandStatus === QueryStatuses.LOADING ?
          <Grid container direction={'column'} alignItems={'center'} spacing={'32px'}>
            <Grid item> <Typography mt={20} variant="customH3">Loading Brand Data</Typography> </Grid>
            <Grid item> <CircularProgress color="secondary" /> </Grid>
          </Grid> :
          brandStatus === QueryStatuses.ERROR ?
            <Typography mt={20} variant="customH3">Error on Loading Brand Data</Typography> :
            brandStatus === QueryStatuses.SUCCESS ?
              (
                <>
                  {brandData?.pages?.map((group, i) => (
                    <Fragment key={i}>
                      {
                        group.data?.map((brand: BrandData) => (
                          <Grid item key={brand._id}>
                            <BrandBox model_count={brand.available_model_count!} id={brand._id} brand={brand.brand_name} image={brand.thumbnail_file[0].url ? brand.thumbnail_file[0].url : "../../img_placeholder.jpg"} />
                          </Grid>
                        ))
                      }
                    </Fragment>
                  ))}
                  <Grid container justifyContent={"center"} py={3}>
                    <CustomLightButton ref={ref} disabled={!hasNextPage} onClick={() => { fetchNextPage() }}>{hasNextPage ? "load more" : "nothing more to see"}</CustomLightButton>
                  </Grid>
                </>
              ) : null
      }
    </>
  );
}

export default Search;
