import { createTheme } from '@mui/material/styles';
import React from 'react';
import CrystalTrialExpandedBold from "./fonts/Crystal-Trial-WOFF2/CrystalTrial-ExpandedBold.woff2";
import CrystalTrialBold from "./fonts/Crystal-Trial-WOFF2/CrystalTrial-Bold.woff2";
import CrystalTrialMedium from "./fonts/Crystal-Trial-WOFF2/CrystalTrial-Medium.woff2";
import CrystalTrialRegular from "./fonts/Crystal-Trial-WOFF2/CrystalTrial-Regular.woff2";
import CrystalTrialCondensedBold from "./fonts/Crystal-Trial-WOFF2/CrystalTrial-CondensedBold.woff2";
import CrystalTrialCondensedRegular from "./fonts/Crystal-Trial-WOFF2/CrystalTrial-CondensedRegular.woff2";
declare module '@mui/material/styles' {
    interface TypographyVariants{
        customH1: React.CSSProperties;
        customH2: React.CSSProperties;
        customH3: React.CSSProperties;
        customSubtitle: React.CSSProperties;
        customBodyStrong: React.CSSProperties;
        customBody: React.CSSProperties;
        customBodyStrongCondensed: React.CSSProperties;
        customBodyCondensed: React.CSSProperties;
        customBodySm: React.CSSProperties;
        customBodyXsCondensed: React.CSSProperties;
        formLabel: React.CSSProperties;
    }
    interface TypographyVariantsOptions {
        customH1?: React.CSSProperties;
        customH2?: React.CSSProperties;
        customH3?: React.CSSProperties;
        customSubtitle?: React.CSSProperties;
        customBodyStrong?: React.CSSProperties;
        customBody?: React.CSSProperties;
        customBodyStrongCondensed?: React.CSSProperties;
        customBodyCondensed?: React.CSSProperties;
        customBodySm?: React.CSSProperties;
        customBodyXsCondensed?: React.CSSProperties;
        formLabel?: React.CSSProperties;
    }
}

declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        customH1: true;
        customH2: true;
        customH3: true;
        customSubtitle: true;
        customBodyStrong: true;
        customBody: true;
        customBodyStrongCondensed: true;
        customBodyCondensed: true;
        customBodySm: true;
        customBodyXsCondensed: true,
        formLabel: true,
    }
}

export const theme = createTheme({
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    customH1: 'h1',
                    customH2: 'h2',
                    customH3: 'h3',
                    customSubtitle: 'h4',
                    customBodyStrong: 'p',
                    customBody: 'p',
                    customBodyStrongCondensed: 'p',
                    customBodyCondensed: 'p',
                    customBodySm: 'p',
                    customBodyXsCondensed: 'p',
                    formLabel: 'p',
                }
            }
        },
        MuiCssBaseline: {
            styleOverrides:
            `
            @font-face {
                font-family: 'CrystalTrial-ExpandedBold';
                font-style: normal;
                font-display: swap;
                src: local('CrystalTrial-ExpandedBold'), url(${CrystalTrialExpandedBold}) format('woff2');
                unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            } 

            @font-face {
                font-family: 'CrystalTrial-Bold';
                font-style: normal;
                font-display: swap;
                src: local('CrystalTrial-Bold'), url(${CrystalTrialBold}) format('woff2');
                unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            } 

            @font-face {
                font-family: 'CrystalTrial-CondensedBold';
                font-style: normal;
                font-display: swap;
                src: local('CrystalTrial-CondensedBold'), url(${CrystalTrialCondensedBold}) format('woff2');
                unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            } 

            @font-face {
                font-family: 'CrystalTrial-CondensedRegular';
                font-style: normal;
                font-display: swap;
                src: local('CrystalTrial-CondensedRegular'), url(${CrystalTrialCondensedRegular}) format('woff2');
                unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            } 

            @font-face {
                font-family: 'CrystalTrial-Medium';
                font-style: normal;
                font-display: swap;
                src: local('CrystalTrial-Medium'), url(${CrystalTrialMedium}) format('woff2');
                unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            } 

            @font-face {
                font-family: 'CrystalTrial-Regular';
                font-style: normal;
                font-display: swap;
                src: local('CrystalTrial-Regular'), url(${CrystalTrialRegular}) format('woff2');
                unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
            } 
            `,
            
        }
    },
    shape: {
        borderRadius: 5,
    },
    typography: {
        customH1:{
            fontFamily: 'CrystalTrial-ExpandedBold',
            fontSize:"64px",
            fontWeight: "bold",
            letterSpacing: "-0.02em",
            lineHeight: "84px",
        },
        customH2:{
            fontFamily: 'CrystalTrial-ExpandedBold',
            fontSize:"40px",
            fontWeight: "700",
            letterSpacing: "-0.02em",
            lineHeight: "52px",
        },
        customH3:{
            fontFamily: 'CrystalTrial-Bold',
            fontSize:"24px",
            fontWeight: "bold",
            letterSpacing: "-0.02em",
            lineHeight: "31px",
        },
        customSubtitle:{
            fontFamily: 'CrystalTrial-Medium',
            fontSize:"24px",
            fontWeight: "500",
            lineHeight: "31px",
        },
        customBodyStrong:{
            fontFamily: 'CrystalTrial-Bold',
            fontSize:"16px",
            fontWeight: "bold",
            lineHeight: "21px",
        },
        customBody:{
            fontFamily: 'CrystalTrial-Regular',
            fontSize:"16px",
            fontWeight: "400",
            lineHeight: "21px",
        },
        customBodyStrongCondensed: {
            fontFamily: 'CrystalTrial-CondensedBold',
            fontSize:"16px",
            fontWeight: "bold",
            lineHeight: "19px",
            letterSpacing: "0.05em",
            textTransform: "uppercase",
        },
        customBodyCondensed: {
            fontFamily: 'CrystalTrial-CondensedRegular',
            fontSize:"16px",
            fontWeight: "500",
            lineHeight: "21px",
            letterSpacing: "0.05em",
            textTransform: "uppercase",
        },
        customBodySm:{
            fontFamily: 'CrystalTrial-Medium',
            fontSize:"14px",
            fontWeight: "500",
            lineHeight: "16px",
        },
        customBodyXsCondensed:{
            fontFamily: 'CrystalTrial-CondensedRegular',
            fontSize:"11px",
            fontWeight: "500",
            lineHeight: "14px",
            letterSpacing: "0.03em",
            textTransform: "uppercase",
        },
        button:{
            fontFamily: 'CrystalTrial-CondensedBold',
            letterSpacing: "0.05em",
            fontSize: "16px",
            lineHeight: "21px",
            fontWeight: "700",
        },
        formLabel:{
            fontFamily: 'CrystalTrial-Regular',
            fontSize: "20px",
            lineHeight: "26.2px",
            fontWeight: "600",
        }
    },
    palette: {
        primary: {
            main: "#8C8C8C",
        },
        secondary: {
            main: "#9EDF34"
        },
        success: {
            main: "#9EDF34",
        },
        error: {
            main: "#D03131"
        }
    }
})