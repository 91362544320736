import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { baseURL } from "../constants";
import { Search } from "../interfaces/Search";
import { getTokenFromStorage } from "./AuthService";

const searchData = async (search_query: string): Promise<Search> => {
    console.log("searchData test: " + getTokenFromStorage());
    const {data} = await axios.get<Search>(`${baseURL}/search?search_query=${search_query}&status=ALL`, {
        headers: {
          "Authorization" : `Bearer ${getTokenFromStorage()}`
        }
      });

    console.log(data)
    return data;
}

export const useSearch = (search_query: string) => {
    return useQuery<Search, Error>(['search', search_query], () => searchData(search_query))
}