import { Button, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import React, { useState } from 'react'
import CustomLightButton from '../components/CustomButton/CustomButton'
import CustomTimeline from '../components/CustomTimeline/CustomTimeline'
import Header from '../components/Header/Header'
import CreateVersionModal from '../components/VersionForm/CreateVersionModal'
import VersionForm from '../components/VersionForm/VersionForm'
import { Version } from '../shared/interfaces/Version'

const VersionControl = () => {
    const versionHistory: Version[] = [
        {
          "build_number": 1,
          "version": "0.0.1",
          "type": "MAJOR",
          "update_date": "16.03.2022 13:01:00",
          "description": "Version update notes",
          "state": "PRODUCTION",
        },
        {
          "build_number": 2,
          "version": "0.0.2",
          "type": "MAJOR",
          "update_date": "16.03.2022 13:50:00",
          "description": "Version update notes",
          "state": "REJECTED",
        },
        {
          "build_number": 3,
          "version": "0.0.3",
          "type": "MINOR",
          "update_date": "17.03.2022 08:05:00",
          "description": "Version update notes",
          "state": "APP_REVIEW",
        },
        {
          "build_number": 4,
          "version": "0.0.4",
          "type": "MAJOR",
          "update_date": "18.03.2022 17:05:00",
          "description": "Version update notes",
          "state": "PRODUCTION",
        },
        {
          "build_number": 5,
          "version": "0.0.5",
          "type": "MINOR",
          "update_date": "18.03.2022 17:05:00",
          "description": "Version update notes",
          "state": "REJECTED",
        }
      ]
    const [openCreate, setOpenCreate] = useState(false);

    const handleOpenCreateModal = () => {
      setOpenCreate(true);
    }
  return (
    <>
    <Grid2 container direction={"column"}>
      <Grid2>
        <Header header="VERSION CONTROL CENTER"/>
      </Grid2>
      <Grid2 container direction={"column"} m={4}>
        <Grid2 container justifyContent={"center"}>
          <CustomLightButton variant='contained' onClick={handleOpenCreateModal}>create new version</CustomLightButton>
        </Grid2>
        <Grid2>
          <CustomTimeline versionHistory={versionHistory}/>
        </Grid2>
      </Grid2>
    </Grid2>
    <CreateVersionModal setOpen={setOpenCreate} open={openCreate}/>
    </>
  )
}

export default VersionControl