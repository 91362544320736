export const categoryChanger = (old_category: string): string => {
    if (old_category === "HAT") {
        return "HATS"
    }else if (old_category === "WATCH") {
        return "WATCHES"
    }else if (old_category === "SHOE") {
        return "SHOES"
    }else if (old_category === "GLASSES") {
        return "EYEWEAR"
    }else if(old_category === "OTHER"){
        return "OTHER"
    }else {
        return old_category;
    }
}