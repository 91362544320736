import axios from "axios";
import { Auth } from "../interfaces/Auth";
import { baseURL } from "../constants";
import { Cookies } from "react-cookie";
import { useQuery } from "@tanstack/react-query";

var CryptoJS = require("crypto-js");

const cookies = new Cookies();

const loginPost = async (): Promise<Auth> => {
    const { data } = await axios.post<Auth>(
        baseURL + "/auth/login",
        {
            //this is visible on payload section
            username: "test_user",
            password: "test_user"
        }
    )
    console.log(data);
    return data;
}

export const useLoginPost = () => {
    return useQuery(['loginPost'], loginPost, { enabled: false });
}

export const getTokenFromStorage = (): string => {
    // Replaced with localStorage for identity server
    // const token = cookies.get("authToken");
    // const decrypted = CryptoJS.AES.decrypt(token,"qreal");
    // const decrypted_token = decrypted.toString(CryptoJS.enc.Utf8);
    // //console.log("token in use: " + decrypted_token);
    return localStorage.getItem('authToken') || '';
}