import React, { useState } from 'react'
import { IProps, Props } from './CustomTimeline.config'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import styles from "./CustomTimeline.module.css";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DeleteVersionModal from '../VersionForm/DeleteVersionModal';
import EditVersionModal from '../VersionForm/EditVersionModal';

export const ContentBox = ({version}:IProps) => {
  const [controlsVisible, setControlsVisible] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const handleOpenDeleteModal = () => {
    setOpenDelete(true);
  }
  const handleOpenEditModal = () => {
    setOpenEdit(true);
  }
  return (
    <>
    <TimelineContent sx={version.type === "MAJOR" ? {"&.MuiTimelineContent-root":{textAlign:"left"}} : {"&.MuiTimelineContent-root":{textAlign:"right"}}}>
    <Box className={styles.versionInfoCard} onMouseEnter={() => setControlsVisible(true)} onMouseLeave={() => setControlsVisible(false)}>
      <Grid2 container direction={version.type === "MAJOR" ? "row" : "row-reverse"}>
        <Grid2 container direction={"column"} pt={0.7} spacing={0.5}>
          <Grid2>
            <Typography variant='customBodySm'>build number: {version.build_number}</Typography>
          </Grid2>
          <Grid2>
            <Typography variant='customBodySm'>version: {version.version}</Typography>
          </Grid2>
          <Grid2>
          <Typography variant='customBodySm'>description: {version.description}</Typography>
          </Grid2>
          <Grid2>
            <Typography variant='customBodySm'>type: {version.type}</Typography>
          </Grid2>
          <Grid2>
            <Typography variant='customBodySm'>update_date: {version.update_date}</Typography>
          </Grid2>
          <Grid2>
            <Typography variant='customBodySm' sx={version.state === "PRODUCTION" ? {color:"green"} : version.state === "APP_REVIEW" ? {color: "orange"} : version.state === "REJECTED" ? {color:"red"} : null}>state: {version.state}</Typography>
          </Grid2>
        </Grid2>
        <Grid2 container direction={"column"} justifyContent={"center"} mx={2} display={controlsVisible ? "flex" : "none"}>
            <Grid2>
              <IconButton aria-label="delete" color='primary' onClick={handleOpenEditModal}>
                <EditIcon/>
              </IconButton>
            </Grid2>
            <Grid2>
              <IconButton aria-label='edit' color="error" onClick={handleOpenDeleteModal}>
                <DeleteIcon/>
              </IconButton>
            </Grid2>
        </Grid2>
      </Grid2>
    </Box>
    </TimelineContent>
    <DeleteVersionModal setOpen={setOpenDelete} open={openDelete} version={version}/>
    <EditVersionModal setOpen={setOpenEdit} open={openEdit} version={version}/>
    </>
  )
}

const CustomTimeline = ({versionHistory}:Props) => {
  versionHistory.reverse();
  return (
    <Timeline>
    {
        versionHistory?.map((version, index) => (
          <TimelineItem key={index} sx={version.type === "MAJOR" ? {"&.MuiTimelineItem-root":{}} : {"&.MuiTimelineItem-root":{flexDirection:"row-reverse"}}}>
          <TimelineSeparator>
            <TimelineDot sx={version.state === "PRODUCTION" ? {background:"green"} : version.state === "APP_REVIEW" ? {background: "orange"} : version.state === "REJECTED" ? {background:"red"} : null}/>
            <TimelineConnector />
          </TimelineSeparator>
          <ContentBox version={version}/>
        </TimelineItem>
        ))
    }
    </Timeline>
  )
}

export default CustomTimeline