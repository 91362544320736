import axios from "axios";

const MOPU_BASE_URL = "https://kfc0cibz54.execute-api.us-east-1.amazonaws.com/production"

export const searchModel = async (searchQuery: String) => {
    let url = `${MOPU_BASE_URL}/models/aasdadsgakgjkhajshkajshak?search_query=${searchQuery}`
    if (searchQuery === "") {
        url = url + "&list_all=false"
    }else {
        url = url + "&list_all=true"
    }
    const {data} = await axios.get(url);
    return data;
}

export const getModel = async (id: String) => {
    const {data} = await axios.get(`${MOPU_BASE_URL}/models/asfkjhasjkfhasjkhgkjashgjkashgh/${id}`);
    return data;
}
