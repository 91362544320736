import { Grid, Typography, TextField, Switch, Stack, Autocomplete, Chip, Slider } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { categories, category, model_status, Option } from '../shared/interfaces/Enums'
import { useGetAllBaseModels, useGetAllBaseModelsTemp } from '../shared/query-hooks/ModelService';
import { ModelViewerConfig, ProductLink } from '../shared/interfaces/VariantModel';
import CustomLightButton from './CustomButton/CustomButton';
import CustomDropZone from './CustomDropZone/CustomDropZone';
import { useGetEnvironmentImageList } from '../shared/query-hooks/EnvironmentImageService';
import { useCreateSovrnLink } from '../shared/query-hooks/VariantModelService';
import { isDevelopment } from '../enums/app';

const modelData: Option[] = []

interface VariantProps {
    modelName: string,
    setModelName: React.Dispatch<React.SetStateAction<string>>,
    selectedCategory: Option,
    setSelectedCategory: React.Dispatch<React.SetStateAction<Option>>
    selectedModelStatus: string,
    setSelectedModelStatus: React.Dispatch<React.SetStateAction<string>>
    tags: string[],
    setTags: React.Dispatch<React.SetStateAction<string[]>>,
    metadata: string[],
    setMetadata: React.Dispatch<React.SetStateAction<string[]>>,
    wannaModelId: string,
    setWannaModelId: React.Dispatch<React.SetStateAction<string>>,
    isTrending: boolean,
    setIsTrending: React.Dispatch<React.SetStateAction<boolean>>
    baseModelId: string,
    setBaseModelId: React.Dispatch<React.SetStateAction<string>>,
    facebookLink: string,
    setFacebookLink: React.Dispatch<React.SetStateAction<string>>,
    instagramLink: string,
    setInstagramLink: React.Dispatch<React.SetStateAction<string>>,
    snapchatLink: string,
    setSnapchatLink: React.Dispatch<React.SetStateAction<string>>,
    sparkProjectLink: string,
    setSparkProjectLink: React.Dispatch<React.SetStateAction<string>>,
    mvDemoLink: string,
    setMvDemoLink: React.Dispatch<React.SetStateAction<string>>,
    modelViewerConfig: ModelViewerConfig[],
    setModelViewerConfig: React.Dispatch<React.SetStateAction<ModelViewerConfig[]>>,
    mvExposureConfig: number | number[],
    setMvExposureConfig: React.Dispatch<React.SetStateAction<number | number[]>>,
    mvIntensityConfig: number | number[],
    setMvIntensityConfig: React.Dispatch<React.SetStateAction<number | number[]>>,
    mvSoftnessConfig: number | number[],
    setMvSoftnessConfig: React.Dispatch<React.SetStateAction<number | number[]>>,
    productLinks: ProductLink[],
    setProductLinks: React.Dispatch<React.SetStateAction<ProductLink[]>>,
    completedCGModelFile: any,
    setCompletedCGModelFile: React.Dispatch<React.SetStateAction<any>>,
    sourceMaskTextureFile: any,
    setSourceMaskTextureFile: React.Dispatch<React.SetStateAction<any>>,
    photogrammetryFile: any,
    setPhotogrammetryFile: React.Dispatch<React.SetStateAction<any>>,
    GLBFile: any,
    setGLBFile: React.Dispatch<React.SetStateAction<any>>,
    USDZFile: any,
    setUSDZFile: React.Dispatch<React.SetStateAction<any>>,
    referenceImages: any,
    setReferenceImages: React.Dispatch<React.SetStateAction<any>>,
    substancePainterFile: any,
    setSubstancePainterFile: React.Dispatch<React.SetStateAction<any>>,
    isDropzoneDisabled: boolean,
    envImgId: string,
    setEnvImgId: React.Dispatch<React.SetStateAction<string>>
    price: number,
    setPrice: React.Dispatch<React.SetStateAction<number>>,
    description: string,
    setDescription: React.Dispatch<React.SetStateAction<string>>,
}

const VariantTab = (props: VariantProps) => {
    const [showItems, setShowItems] = useState<{
        showLinks: boolean;
    }>({
        showLinks: false
    });

    //generate sovrn link
    const [url, setUrl] = useState("")
    const [sovrnURLName, setSovrnURLName] = useState("")
    const { data: sovrnLinkData, status: sovrnLinkGenerateStatus, refetch: sovrnLinkGenerate } = useCreateSovrnLink(url);

    //TO-DO: change this when base model list all bug is fixed
    const { data: baseModelListData, status: baseModelListDataStatus } = useGetAllBaseModelsTemp();

    //base model select
    const [baseModelList, setBaseModelList] = useState<Option[]>([]);
    useEffect(() => {
        if (baseModelListDataStatus === "success") {
            const tempArray: Option[] = []
            baseModelListData.data.forEach((baseModel, index) => {
                tempArray[index] = { value: baseModel._id!, label: baseModel.model_name }
            })
            setBaseModelList(tempArray);
        }
    }, [baseModelListDataStatus])

    useEffect(() => {
        if (sovrnLinkGenerateStatus === "success") {
            props.setProductLinks([...props.productLinks, { name: sovrnURLName, url: sovrnLinkData.data[0].anywhereUrl }])
        }
    }, [sovrnLinkGenerateStatus])

    useEffect(() => {
        if (url) {
            sovrnLinkGenerate()
        }
    }, [url])



    //get env-img data for env-img-select
    const [envImgs, setEnvImgs] = useState<Option[]>([]);
    const { data: allEnvImgData, status: allEnvImgStatus } = useGetEnvironmentImageList();
    useEffect(() => {
        if (allEnvImgStatus === "success") {
            const tempArray: Option[] = []
            allEnvImgData.data.forEach((envImg, index) => {
                tempArray[index] = { value: envImg._id, label: envImg.environment_name }
            })
            setEnvImgs(tempArray);
        }
    }, [allEnvImgStatus])

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const handleProductLinkChange = (i, e) => {
        let newProductLinks = [...props.productLinks];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newProductLinks[i][e.target.name] = e.target.value;
        props.setProductLinks(newProductLinks);
    }

    const addProductLinkFields = () => {
        props.setProductLinks([...props.productLinks, { name: "", url: "" }])
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const removeProductLinkField = (i) => {
        let newProductLinks = [...props.productLinks];
        newProductLinks.splice(i, 1);
        props.setProductLinks(newProductLinks);
    }

    const handleTagsChange = (e: any, newValue: any) => {
        props.setTags(newValue)
    }

    function handleMetadataChange(e: any, newValue: any) {
        props.setMetadata(newValue)
    }

    const handleExposureSliderChange = (event: Event, value: number | number[]) => {
        props.setMvExposureConfig(value)
        handleModelViewerConfig()
    };

    const handleIntensitySliderChange = (event: Event, value: number | number[]) => {
        props.setMvIntensityConfig(value)
        handleModelViewerConfig()
    };

    const handleSoftnessSliderChange = (event: Event, value: number | number[]) => {
        props.setMvSoftnessConfig(value)
        handleModelViewerConfig()
    };

    const handleModelViewerConfig = () => {
        const tempConfig = [{ exposure: props.mvExposureConfig.toString(), "shadow-intensity": props.mvIntensityConfig.toString(), "shadow-softness": props.mvSoftnessConfig.toString() }]
        props.setModelViewerConfig(tempConfig)
    };

    const handleIsTrendingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setIsTrending(event.target.checked);
    };

    const handleGenerateSovrnLink = (element: ProductLink, index: number) => {
        if (element.url) {
            setSovrnURLName(element.name + " Sovrn URL")
            if (element.url.startsWith("https://")) {
                setUrl(element.url)
            } else {
                setUrl("https://" + element.url)
            }
        }
    }
    return (
        <>
            <Grid item container direction={'column'} width={'417px'} spacing={'16px'}>
                <Grid item>
                    <Typography variant='customH3'> Base model </Typography>
                </Grid>

                <Grid item width={'417px'}>
                    <Autocomplete size='small' disableClearable={true} onChange={(e, selected) => {
                        props.setBaseModelId(selected?.value!)
                    }} renderInput={(params) => <TextField {...params} />} options={baseModelList} />
                </Grid>
            </Grid>

            <Grid item container direction={'column'} spacing={'16px'}>
                <Grid item>
                    <Typography variant='customH3'> Variant Model Name </Typography>
                </Grid>

                <Grid item width={'417px'}>
                    <TextField
                        value={props.modelName}
                        size='small'
                        onChange={(e) => props.setModelName(e.target.value)}
                        fullWidth
                    />
                </Grid>
            </Grid>

            <Grid item container direction={'column'} spacing={'16px'}>
                <Grid item>
                    <Typography variant='customH3'> Category </Typography>
                </Grid>

                <Grid item width={'417px'}>
                    <Autocomplete
                        size='small'
                        value={props.selectedCategory}
                        disableClearable={true}
                        onChange={(e, selected) => {
                            props.setSelectedCategory(selected)
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Select..." />}
                        options={category}
                        autoComplete={false}
                    />
                </Grid>
            </Grid>



            {!isDevelopment && <>
                <Grid item container direction={'column'} spacing={'16px'}>
                    <Grid item>
                        <Typography variant='customH3'> Tags </Typography>
                    </Grid>
                    <Grid item width={'417px'}>
                        <Autocomplete
                            size='small'
                            multiple
                            id="tags-filled"
                            options={categories}
                            value={[...props.tags]}
                            onChange={handleTagsChange}
                            freeSolo
                            renderTags={(value: readonly string[], getTagProps) =>
                                value?.map((option: string, index: number) => (
                                    <Chip label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField {...params} />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid item container direction={'column'} spacing={'16px'}>
                    <Grid item>
                        <Typography variant='customH3'> Metadata </Typography>
                    </Grid>
                    <Grid item width={'417px'}>
                        <Autocomplete
                            size='small'
                            multiple
                            id="tags-filled"
                            options={categories}
                            value={[...props.metadata]}
                            onChange={handleMetadataChange}
                            freeSolo
                            renderTags={(value: readonly string[], getTagProps) =>
                                value?.map((option: string, index: number) => (
                                    <Chip label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField {...params} />
                            )}
                        />
                    </Grid>
                </Grid>

                <Grid item container direction={'column'} spacing={'16px'}>
                    <Grid item>
                        <Typography variant='customH3'> Wanna Model ID </Typography>
                    </Grid>

                    <Grid item width={'417px'}>
                        <TextField
                            value={props.wannaModelId}
                            size={'small'}
                            onChange={(e) => props.setWannaModelId(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                </Grid>

                <Grid item container direction={'column'} spacing={'16px'}>
                    <Grid item>
                        <Typography variant='customH3'> Price </Typography>
                    </Grid>

                    <Grid item>
                        <TextField value={props.price} type="number" onChange={(e) => props.setPrice(+e.target.value)} fullWidth
                            sx={{ "& .MuiInputBase-root": { height: '40px', width: '417px' } }} />
                    </Grid>
                </Grid>

                <Grid item container direction={'column'} spacing={'16px'}>
                    <Grid item>
                        <Typography variant='customH3'> Description </Typography>
                    </Grid>

                    <Grid item>
                        <TextField value={props.description} onChange={(e) => props.setDescription(e.target.value)} fullWidth
                            sx={{ "& .MuiInputBase-root": { height: '40px', width: '417px' } }} />
                    </Grid>
                </Grid>

                <Grid item container direction={'column'} spacing={'8px'}>
                    <Grid item>
                        <Typography variant='customH3'> Trending item </Typography>
                    </Grid>
                    <Grid item>
                        <Switch
                            color="secondary"
                            checked={props.isTrending}
                            onChange={handleIsTrendingChange}
                        />
                    </Grid>
                </Grid>

                <Grid item container direction={'column'} spacing={3}>

                    {
                        props.modelViewerConfig ?
                            <Grid item container direction={"column"} spacing={2}>
                                <Grid item>
                                    <Typography variant='customH3'> ModelViewer Config </Typography>
                                </Grid>
                                <Grid item container direction={"column"} spacing={1.5}>
                                    <Grid item>
                                        <Stack direction={"row"} alignItems={"center"}>
                                            <Typography variant='customBody' mr={2}> Exposure: </Typography>
                                            <Slider
                                                sx={{ width: 300 }}
                                                size="small"
                                                value={props.mvExposureConfig}
                                                aria-label="Exposure"
                                                valueLabelDisplay="auto"
                                                color="secondary"
                                                max={2}
                                                min={0}
                                                step={0.05}
                                                onChange={handleExposureSliderChange}
                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item>
                                        <Stack direction={"row"} alignItems={"center"}>
                                            <Typography variant='customBody' mr={2}> Shadow-Intensity: </Typography>
                                            <Slider
                                                sx={{ width: 300 }}
                                                size="small"
                                                value={props.mvIntensityConfig}
                                                aria-label="Intensity"
                                                valueLabelDisplay="auto"
                                                color="secondary"
                                                max={1}
                                                min={0}
                                                step={0.05}
                                                onChange={handleIntensitySliderChange}

                                            />
                                        </Stack>
                                    </Grid>
                                    <Grid item>
                                        <Stack direction={"row"} alignItems={"center"}>
                                            <Typography variant='customBody' mr={2}> Shadow-Softness: </Typography>
                                            <Slider
                                                sx={{ width: 300 }}
                                                size="small"
                                                value={props.mvSoftnessConfig}
                                                aria-label="Softness"
                                                valueLabelDisplay="auto"
                                                color="secondary"
                                                max={1}
                                                min={0}
                                                step={0.05}
                                                onChange={handleSoftnessSliderChange}
                                            />
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid> : null
                    }

                </Grid>
            </>}

            <Grid item container direction={"column"}>
                <Grid item> <Typography variant='customH3' mb={1}> Environment Image </Typography>
                </Grid>
                <Grid item width={'417px'}>
                    <Autocomplete size='small' disableClearable={true}
                        value={envImgs.find(element => element.value === props.envImgId) || {
                            label: "",
                            value: ""
                        }} onChange={(e, selected) => {
                            props.setEnvImgId(selected?.value!)
                        }} renderInput={(params) => <TextField {...params} />}
                        options={envImgs} />
                </Grid>
            </Grid>

            <Grid item container direction={'column'} spacing={'32px'}>
                <Grid item sx={{ display: 'flex' }} alignItems={'center'}>
                    <Typography variant='customH3'> Links </Typography>
                    <Switch
                        color="secondary"
                        checked={showItems.showLinks}
                        onChange={() => setShowItems({ ...showItems, showLinks: !showItems.showLinks })}
                    />
                </Grid>
                {showItems.showLinks && <>
                    <Grid item>
                        <Typography variant='formLabel'> Product Links </Typography>
                        {/* <TextField value={productLinks} onChange={(e) => setProductLinks(e.target.value)} fullWidth  sx={{ "& .MuiInputBase-root": { height: '40px', width:'630px' } }} />  */}
                        {props.productLinks?.map((element, index) => (
                            <Stack direction={"row"} key={index} spacing={"30px"} py={1}>
                                <TextField placeholder='Display Name' name='name' value={element.name || ""}
                                    onChange={e => handleProductLinkChange(index, e)}
                                    sx={{ "& .MuiInputBase-root": { height: '40px', width: '300px' } }}></TextField>
                                <TextField placeholder='URL' name='url' value={element.url || ""}
                                    onChange={e => handleProductLinkChange(index, e)}
                                    sx={{ "& .MuiInputBase-root": { height: '40px', width: '300px' } }}></TextField>
                                {!element.url.includes("viglink") ?
                                    <CustomLightButton onClick={() => handleGenerateSovrnLink(element, index)}>Sovrn Link</CustomLightButton> : null
                                }
                                {index ? <CustomLightButton
                                    onClick={() => removeProductLinkField(index)}>remove</CustomLightButton> : null}
                            </Stack>
                        ))}
                        <CustomLightButton onClick={addProductLinkFields}>add a product link</CustomLightButton>
                        {/* <Stack direction={"row"} alignItems={"center"}>
                            <Typography variant='customBodyCondensed'>Generate Sovrn Link</Typography>
                            <Checkbox color='secondary' checked={props.generateSovrnLinkFlag} onChange={handleGenerateSovrnLinkCheckbox}></Checkbox>
                        </Stack> */}
                    </Grid>
                    <Grid item>
                        <Typography variant='formLabel'> Facebook Link </Typography>
                        <TextField value={props.facebookLink} onChange={(e) => props.setFacebookLink(e.target.value)}
                            fullWidth sx={{ "& .MuiInputBase-root": { height: '40px', width: '630px' } }} />
                    </Grid>
                    <Grid item>
                        <Typography variant='formLabel'> Instagram Link </Typography>
                        <TextField value={props.instagramLink} onChange={(e) => props.setInstagramLink(e.target.value)}
                            fullWidth sx={{ "& .MuiInputBase-root": { height: '40px', width: '630px' } }} />
                    </Grid>
                    <Grid item>
                        <Typography variant='formLabel'> Spark Project Link </Typography>
                        <TextField value={props.sparkProjectLink}
                            onChange={(e) => props.setSparkProjectLink(e.target.value)} fullWidth
                            sx={{ "& .MuiInputBase-root": { height: '40px', width: '630px' } }} />
                    </Grid>
                    <Grid item>
                        <Typography variant='formLabel'> Snapchat Link </Typography>
                        <TextField value={props.snapchatLink} onChange={(e) => props.setSnapchatLink(e.target.value)}
                            fullWidth sx={{ "& .MuiInputBase-root": { height: '40px', width: '630px' } }} />
                    </Grid>
                    <Grid item>
                        <Typography variant='formLabel'> Modelviewer Demo Link </Typography>
                        <TextField value={props.mvDemoLink} onChange={(e) => props.setMvDemoLink(e.target.value)}
                            fullWidth sx={{ "& .MuiInputBase-root": { height: '40px', width: '630px' } }} />
                    </Grid>
                </>}
            </Grid>


            <Grid item container direction={"column"}>
                <Grid item pb={2.5}>
                    <Typography variant='customH3'> Upload files </Typography>
                </Grid>

                <Grid item container spacing={5}>
                    <Grid item>
                        <Stack>
                            <Stack pb={1.5}>
                                {
                                    props.isDropzoneDisabled
                                        ?
                                        <Typography variant='customBodyStrong'>Your file(s) will be upload
                                            automatically</Typography>
                                        :
                                        <Stack>
                                            <Typography variant='customBodyStrong'> GLB file </Typography>
                                            <Typography variant='customBodySm'> Supported File Type: .glb</Typography>
                                        </Stack>
                                }
                            </Stack>
                            <CustomDropZone
                                setFile={props.setGLBFile}
                                fileName={"GLB Model"}
                                isMultiple={true}
                                accept={{ 'model/gltf-binary': ['.glb'] }}
                                maxFiles={1}
                                isDisabled={props.isDropzoneDisabled}
                            />
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack>
                            <Stack pb={1.5}>
                                {
                                    props.isDropzoneDisabled
                                        ?
                                        <Typography variant='customBodyStrong'>Your file(s) will be upload
                                            automatically</Typography>
                                        :
                                        <Stack>
                                            <Typography variant='customBodyStrong'> USDZ file </Typography>
                                            <Typography variant='customBodySm'> Supported File Type: .usdz</Typography>
                                        </Stack>
                                }
                            </Stack>
                            <CustomDropZone
                                setFile={props.setUSDZFile}
                                fileName={"USDZ Model"}
                                isMultiple={true}
                                accept={{ 'model/vnd.usdz+zip': ['.usdz'] }}
                                maxFiles={1}
                                isDisabled={props.isDropzoneDisabled}
                            />
                        </Stack>
                    </Grid>
                    {!isDevelopment && <>
                        <Grid item>
                            <Stack>
                                <Stack pb={1.5}>
                                    {
                                        props.isDropzoneDisabled
                                            ?
                                            <Typography variant='customBodyStrong'>Your file(s) will be upload
                                                automatically</Typography>
                                            :
                                            <Stack>
                                                <Typography variant='customBodyStrong'> Reference Images </Typography>
                                                <Typography variant='customBodySm'> Supported File Type: .jpeg</Typography>
                                            </Stack>
                                    }
                                </Stack>
                                <CustomDropZone
                                    setFile={props.setReferenceImages}
                                    fileName={"Reference Images"}
                                    isMultiple={true}
                                    accept={{ 'image/jpeg': ['.jpeg', '.jpg'] }}
                                    maxFiles={10}
                                    isDisabled={props.isDropzoneDisabled}
                                />
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Stack>
                                <Stack pb={1.5}>
                                    <Typography variant='customBodyStrong'> Source Textures </Typography>
                                    <Typography variant='customBodySm'> Supported File Type: .png</Typography>
                                </Stack>
                                <CustomDropZone
                                    setFile={props.setSourceMaskTextureFile}
                                    fileName={"Source Textures"}
                                    isMultiple={true}
                                    accept={{ 'image/png': ['.png'] }}
                                    maxFiles={10}
                                    isDisabled={props.isDropzoneDisabled}
                                />
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Stack>
                                <Stack pb={1.5}>
                                    <Typography variant='customBodyStrong'> CG Model </Typography>
                                    <Typography variant='customBodySm'> Supported File Type: .blend</Typography>
                                </Stack>
                                <CustomDropZone
                                    setFile={props.setCompletedCGModelFile}
                                    fileName={"CG Model"}
                                    isMultiple={true}
                                    accept={{ 'application/octet-stream': ['.blend'] }}
                                    maxFiles={10}
                                    isDisabled={props.isDropzoneDisabled}
                                />
                            </Stack>
                        </Grid>
                        <Grid item>
                            <Stack>
                                <Stack pb={1.5}>
                                    <Typography variant='customBodyStrong'> Photogrammetry File </Typography>
                                    <Typography variant='customBodySm'> Supported File Type: .fbx</Typography>
                                </Stack>
                                <CustomDropZone
                                    setFile={props.setPhotogrammetryFile}
                                    fileName={"Photogrammetry File "}
                                    isMultiple={true}
                                    accept={{ 'application/octet-stream': ['.fbx'] }}
                                    maxFiles={10}
                                    isDisabled={props.isDropzoneDisabled}
                                />
                            </Stack>
                        </Grid>
                    </>}

                </Grid>
            </Grid>

            <Grid item container direction={'column'} spacing={'16px'}>
                <Grid item>
                    <Typography variant='customH3'> Status </Typography>
                </Grid>

                <Grid item width={'417px'}>
                    <Autocomplete size='small' disableClearable={true} onChange={(e, selected) => {
                        props.setSelectedModelStatus(selected?.value!)
                    }} renderInput={(params) => <TextField {...params} placeholder="Select..." />}
                        options={model_status} />
                </Grid>
            </Grid>
        </>
    );
}

export default VariantTab;
