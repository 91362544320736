import React from 'react'
import { Box, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

interface Properties {
  title: string;
  color: string;
  bgColor: string;
  isFilled: boolean;
  status: 'active' | 'passive' | 'no-status'
}

const defaultProps = {
  title:"",
  color:"",
  bgColor:"",
  isFilled:true,
  status: 'no-status'
};

CustomChip.defaultProps = defaultProps;

function iconSelector(status: string){
  if(status === 'active') {
    return ( <FiberManualRecordIcon style={{ color:"#9EDF34", width:'9px', height:'9px' }} /> );
  }
  else if(status === 'passive'){
    return ( <FiberManualRecordIcon style={{ color:"#F85045", width:'9px', height:'9px' }} /> )
  }
}

function CustomChip(props: Properties) {
  if (props.isFilled) {
    return (
      <Box style={{
          height:'25px', 
          borderRadius:'100px', 
          backgroundColor: props.bgColor, 
          paddingLeft:'12px', paddingRight:'12px',
          paddingTop:'6px', paddingBottom:'6px' }}
          alignItems='center'
          >
          <Typography variant='customBodyXsCondensed' color={props.color}> {props.title} { iconSelector(props.status) } </Typography>
      </Box>
    );
  }

  else {
    return(
      <Box style={{
        height:'25px',
        border: '1px solid #8C8C8C',
        borderRadius:'100px',
        backgroundColor: 'transparent',
        paddingLeft:'12px', paddingRight:'12px',
        paddingTop:'6px', paddingBottom:'6px' }}>
        <Typography variant='customBodyXsCondensed' color={props.color}> {props.title} { iconSelector(props.status) }  </Typography>
      </Box>
    );
  }
}

export default CustomChip;
