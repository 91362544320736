import { useEffect, useState } from "react";
import { searchStringLength } from "../constants";

export default function useDebounce(value:string, delay:number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
        if(value.length >= searchStringLength){
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        }else{
          setDebouncedValue("");
        }
    },
    [value, delay]
  );

  return debouncedValue;
}