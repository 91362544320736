import React from 'react'
import { AppBar, Box, Container, Grid, IconButton, Toolbar, Typography } from '@mui/material'
import CustomChip from '../CustomChip';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import { Properties } from './Header.config';


function chipCreator(status: string | undefined) {
  if (status != undefined) {
    if (status == "ACTIVE") {
      return (
        <CustomChip title="ACTIVE" color='#000000' isFilled={false} status={'active'} />
      );
    }
    else if (status == "PASSIVE") {
      return (
        <CustomChip title="PASSIVE" color='#000000' isFilled={false} status={'passive'} />
      );
    }
  }
}

function Header({
  header = '',
  itemModel = '',
  status = undefined,
  width = "100%",
  height = "80px",
  variantModelName
}: Properties) {
  let navigate = useNavigate();
  return (
    <AppBar position="sticky" sx={{ background: "white" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Grid container>
            <Grid item xs={1} />

            <Grid item container xs={10} spacing={'30px'} alignItems={"center"}>
              <Grid item> <Typography sx={{ color: "black" }} variant='customH3'> {header} </Typography> </Grid>
              <Grid item> <Typography sx={{ color: "black" }} variant='customSubtitle'> {itemModel} </Typography> </Grid>
              <Grid item> <Typography sx={{ color: "black" }} variant='customSubtitle'> {variantModelName} </Typography></Grid>
              <Grid item> {chipCreator(status)} </Grid>
            </Grid>

            <Grid item container xs={1} justifyContent={'flex-end'} style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={() => { navigate("/") }}> <CloseIcon /> </IconButton>
            </Grid>


          </Grid>
        </Toolbar>
      </Container>
    </AppBar>

  )
}

export default Header;
