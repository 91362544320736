import { Navigate } from 'react-router-dom';
import { checkAuth } from '../enums/authKeys';
import { useAuth } from 'qreal-auth-provider/src';

export const ProtectedRoute = ({ children, pagePermissionKey = '' }: {
    children: React.ReactNode;
    pagePermissionKey?: string;
}) : any => {
    const { token, isPermissionLoaded, authList } = useAuth();

    console.log('test 1', isPermissionLoaded, authList, pagePermissionKey, pagePermissionKey && !checkAuth(authList, pagePermissionKey));

    if (!token) {
        return <Navigate to="/sign-in" replace />;
    }
    else if (!isPermissionLoaded) {
        console.log('test', isPermissionLoaded, token, authList);
        return null;
    }

    // Check if the page has permission key and if the user has that permission
    else if (pagePermissionKey && !checkAuth(authList, pagePermissionKey)) {
        return <Navigate to="/" replace />;
    }

    return children;
};