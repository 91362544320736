import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import styles from './BasicTable.module.css';

import { Props } from './BasicTable.config'

export default function BasicTable({
  handleChangePage = () => { },
  handleChangeRowsPerPage = () => { },
  tableData,
  pagination,
  paginationInfo,
  noDataMessage = '',
}: Props) {
  return (
    tableData.length > 0 ?
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableData?.map((item, index) => (
                <TableCell className={styles.tableTitle} key={'basic-table' + item.title} align={index ? 'right' : 'left'}>{item.title}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData[0].rowList?.map((item, row) => (
              <TableRow
                key={'basic-table-row' + item}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {tableData?.map((data, index) => (
                  <TableCell key={'basic-table-column' + data.rowList[row]} align={index ? 'right' : 'left'}> {data.rowList[row]} </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          {
            pagination &&
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[]}
                  colSpan={tableData.length}
                  count={paginationInfo.totalCount}
                  rowsPerPage={paginationInfo.pageSize}
                  page={paginationInfo.current}
                  onPageChange={(e, page) => handleChangePage(page)}
                  ActionsComponent={TablePaginationActions}
                />
              </TableRow>
            </TableFooter>
          }
        </Table>
      </TableContainer> :
      (
        noDataMessage || <Box className={styles.loadingContainer} sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>
      )
  );
}
