import axios from "axios";
import { useInfiniteQuery, useMutation, useQuery } from "@tanstack/react-query";
import { baseURL, mopuBaseURL, results_per_page } from "../constants";
import { Model, ModelData, ModelList, ModelUploadData } from "../interfaces/Model";
import { getTokenFromStorage } from "./AuthService";

//TO-DO: remove this when base model list all bug is fixed
const getAllBaseModelsTemp = async (): Promise<ModelList> => {
    const {data} = await axios.get(`${baseURL}/models?per_page=1000&page=1`, {
        headers: {
            "Authorization" : `Bearer ${getTokenFromStorage()}`
        }
    })
    return data;
}

export const useGetAllBaseModelsTemp = () => {
    return useQuery<ModelList,Error>(['models'], getAllBaseModelsTemp);
}
//end TO-DO

const getAllBaseModels = async (): Promise<ModelList> => {
    const {data} = await axios.get(`${baseURL}/models?list_all=true`, {
        headers: {
            "Authorization" : `Bearer ${getTokenFromStorage()}`
        }
    })
    return data;
}

export const useGetAllBaseModels = () => {
    return useQuery<ModelList,Error>(['models'], getAllBaseModels);
}

const getPaginatedBaseModels = async ({pageParam = 1}): Promise<ModelList> => {
    const {data} = await axios.get(`${baseURL}/models?per_page=${results_per_page}&page=${pageParam}`, {
        headers: {
            "Authorization" : `Bearer ${getTokenFromStorage()}`
        }
    })
    return data;
}

export const useGetPaginatedlBaseModels = () => {
    return useInfiniteQuery<ModelList,Error>(['models'], getPaginatedBaseModels,
    {
        getNextPageParam: (pages) => 
        {
          if(pages.data.length > 0){
            return pages.page + 1
          }else {
            return undefined
          }
        },
    });
}

const getBaseModelById = async (model_id: string): Promise<Model> => {
    const {data} = await axios.get(`${baseURL}/models/${model_id}`,{
        headers: {
            "Authorization" : `Bearer ${getTokenFromStorage()}`
        }
    })
    console.log(data);
    return data;
}

export const useGetBaseModelById = (model_id: string) => {
    return useQuery<Model, Error>(['getBaseModel', model_id], () => getBaseModelById(model_id), {cacheTime: 1000});
}

const createModel = async (model: ModelUploadData) => {
    return await axios.post(`${baseURL}/models?is_debug=true`, model, {
        headers: {
            "Authorization" : `Bearer ${getTokenFromStorage()}`
        }
    })
}

export const useCreateModel = () => {
    return useMutation(createModel, {
        onSuccess: () => {
            console.log("model added");
        },
        onError: () => {
            console.log("error")
        },
    })
}

const getModelsFromMOPU = async () => {
    const {data} = await axios.get(`${mopuBaseURL}/models?list_all=true`)
    console.log(data);
    return data;
}

export const useModelsFromMOPU = () => {
    return useQuery(["mopuModels"], getModelsFromMOPU);
}

const updateBaseModel = async(updated_base_model: ModelUploadData) => {
    return await axios.put(`${baseURL}/models/${updated_base_model._id}`, updated_base_model, {
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
}

export const useUpdateBaseModel = () => {
    return useMutation(updateBaseModel, {
        onSuccess: () => {
            console.log("model updated");
        },
        onError: () => {
            console.log("error")
        },
    })
}

const deleteBaseModel = async (_id: string) => {
    return await axios.delete(`${baseURL}/models/${_id}`,{
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    } )
}

export const useDeleteBaseModel = () => {
    return useMutation(deleteBaseModel, {
        onSuccess: () => {
            console.log("base model deleted");
        },
        onError: () => {
            console.log("error")
        },
    })
}