import axios from "axios"
import { baseURL } from "../constants"
import { Pagination } from "../interfaces/Shared"
import { getTokenFromStorage } from "./AuthService"

export const getLensListWithPagination = async (historyRequest: Pagination) => {
    return await axios.get(`${baseURL}/variant-models/lenses?page=${historyRequest.page}&per_page=${historyRequest.per_page}`, {
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
}

export const getVariantLenses = async (variantModelId: string) => {
    return await axios.get(`${baseURL}/variant-models/${variantModelId}/lenses`, {
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
}

export const sendDeleteLens = async (variantModelId: string, lensId: string) => {
    return await axios.delete(`${baseURL}/variant-models/${variantModelId}/lenses/${lensId}`, {
        headers: {
            "Authorization": `Bearer ${getTokenFromStorage()}`
        }
    })
}