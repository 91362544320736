import React, { useState, useEffect } from 'react'
import { Snackbar, Alert, Box, Grid, Modal, TextField, Typography, Tooltip, Radio, RadioGroup, FormControlLabel, Button, Link } from '@mui/material';
import Header from '../components/Header/Header';
import CustomLightButton from '../components/CustomButton/CustomButton';
import { useSendPushNotification, getNotificationHistory, useCancelNotification } from '../shared/query-hooks/PushNotificationService';
import { PushNotificationData } from "../shared/interfaces/PushNotificationData";
import BasicTable from '../components/BasicTable/BasicTable';
import styles from '../assets/css/PushNotification.module.css';
import { TableData } from '../components/BasicTable/BasicTable.config';
import { getDateString } from '../assets/scripts/date';
import WarningPrompt from '../components/WarningPrompt/WarningPrompt';
import moment from "moment";
import { set } from 'lodash';
import { NotificationStatus } from '../enums/status';
import NotificationDatePicker from '../components/DatePicker/datePicker';

enum sendOptions {
    DIRECT = 'DIRECT',
    SCHEDULE = 'SCHEDULE'
}

type SendType = keyof typeof sendOptions;

const PushNotification = () => {
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [sendDate, setSendDate] = useState<string | null>(null);
    const [deeplink, setDeeplink] = useState("");
    const [selectedSendOption, setSelectedTypeOption] = useState<SendType>(sendOptions.DIRECT);
    const [paginationInfo, setPaginationInfo] = useState({ current: 0, pageSize: 5, totalCount: 15 });
    const [notificationHistory, setNotificationHistory] = useState<TableData | any>([]);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isSuccessAlertOpen, setIsSuccessAlertOpen] = React.useState(false);
    const [isErrorAlertOpen, setIsErrorAlertOpen] = React.useState(false);

    const buttonClicked = () => {
        setErrorMessage("")
        if (selectedSendOption == sendOptions.SCHEDULE && !sendDate) {
            setErrorMessage("Please select a date and hour to send notification")
            setIsErrorAlertOpen(true)
            return
        }
        setIsModalOpen(true)
    }

    const handleClick = () => {
        setIsSuccessAlertOpen(true);
    };

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setIsSuccessAlertOpen(false);
        setIsErrorAlertOpen(false);
    };

    const {
        mutate: pushNotificationMutate,
        data: pushNotificationResponseData,
        status: pushNotificationResponseStatus
    } = useSendPushNotification();

    const {
        mutate: cancelNotificationMutate,
        status: cancelNotificationResponseStatus
    } = useCancelNotification();


    const sendButtonClicked = () => {
        console.log("sendButtonClicked")
        const pushNotificationData: PushNotificationData = {
            "title": subject,
            "message": message,
            "payload": {
                variant_url: deeplink
            }
        }

        if (selectedSendOption === sendOptions.SCHEDULE) {
            pushNotificationData.send_date = sendDate as string
        }

        pushNotificationMutate(pushNotificationData)
        setIsModalOpen(false)
    }

    // Initial load
    useEffect(() => {
        updateTableData();
    }, [paginationInfo.current])

    useEffect(() => {
        if (pushNotificationResponseStatus === "success" && pushNotificationResponseData.data.is_complete) {
            updateTableData();
            setIsSuccessAlertOpen(true)
        } else if (pushNotificationResponseStatus === "error" || (pushNotificationResponseData && !pushNotificationResponseData.data.is_complete)) {
            setIsErrorAlertOpen(true)
        }
    }, [pushNotificationResponseStatus])

    useEffect(() => {
        if (cancelNotificationResponseStatus === "success") {
            updateTableData();
            setIsSuccessAlertOpen(true)
        } else if (cancelNotificationResponseStatus === "error" || (pushNotificationResponseData && !pushNotificationResponseData.data.is_complete)) {
            setErrorMessage("Cancel error!!!")
            setIsErrorAlertOpen(true)
        }
    }, [cancelNotificationResponseStatus])

    // Page table list update function
    const updateTableData = () => {
        getNotificationHistory({
            page: paginationInfo.current + 1,
            per_page: paginationInfo.pageSize
        }).then((response) => { return response.data }).then((response) => {
            if (response.data && response.data.length > 0) {
                const tableData = [
                    // TODO: title may be taken from object keys, couldn't add it due to title key conflict with table title
                    { title: 'Subject', rowList: response.data?.map((item: any) => item.title) },
                    { title: 'Message', rowList: response.data?.map((item: any) => item.message) },
                    {
                        title: 'Schedule Time', rowList: response.data?.map((item: any) => {
                            return (
                                <Tooltip title={getDateString(item.send_date)} placement={"top-end"}>
                                    <div>
                                        {moment(item.send_date).fromNow()}

                                        {item.status === NotificationStatus.PENDING && <Button sx={{ marginLeft: '10px' }} variant="outlined" color='error' size='small' onClick={() => {
                                            cancelNotificationMutate(item._id)
                                        }}>CANCEL</Button>}
                                    </div>
                                </Tooltip>
                            )
                        })
                    },
                    { title: 'Send Status', rowList: response.data?.map((item: any) => item.status ? item.status : '-') },
                    { title: 'Deep Link', rowList: response.data?.map((item: any) => item.payload && item.payload.variant_url ? <Link target='_blank' href={item.payload.variant_url}>Check Model</Link> : '-') },
                    {
                        title: 'Create Date', rowList: response.data?.map((item: any) => {
                            return (
                                <Tooltip title={getDateString(item.created_at)} placement={"top-end"}>
                                    <div>
                                        {moment(item.created_at).fromNow()}
                                    </div>
                                </Tooltip>
                            )
                        })
                    },
                ]
                setPaginationInfo((prevState) => { return { ...prevState, totalCount: response.total_count || 0 } })
                setNotificationHistory(tableData)
            }
        })
    }

    // Next page button callback
    const historyPageUpdate = (page: number) => { setNotificationHistory([]); setPaginationInfo((prevState) => { return { ...prevState, current: page } }); }

    return (
        <>
            <Snackbar open={isSuccessAlertOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                    Push notification sent successfully!
                </Alert>
            </Snackbar>
            <Snackbar open={isErrorAlertOpen} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {errorMessage || 'Push notification failed to send!'}
                </Alert>
            </Snackbar>
            <Header header='Push Notification Sending System'></Header>

            <Grid container sx={{ width: "100vw", mt: 8, mb: 8 }}>
                <Grid item xs={1.5} />

                <Grid item container xs={9} direction={'column'} spacing={4} justifyContent={'center'}>
                    <Grid item container>
                        <Alert severity='error'>WARNING !!! TRYO IOS APP IS NOW ON PRODUCTION. ANY NOTIFICATION IS GOING TO BE SENT TO ALL USERS WHO HAS TRYO APP !!!</Alert>
                    </Grid>
                    <Grid item container direction={'column'} spacing={1}>
                        <Grid item container width={"25vw"}>
                            <Grid item xs={6}>
                                <Typography variant='customH3'>Subject</Typography>
                            </Grid>

                            <Grid item container xs={6} justifyContent={'flex-end'}>
                                <Typography variant='customH3'>{`${subject.length}/40`}</Typography>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <TextField
                                size="small"
                                inputProps={{ maxLength: 40 }}
                                sx={{ "& .MuiInputBase-root": { width: '25vw' } }}
                                value={subject}
                                onChange={e => setSubject(e.target.value)}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container direction={'column'} spacing={1}>
                        <Grid item container width={"25vw"}>
                            <Grid item xs={6}>
                                <Typography variant='customH3'>Message</Typography>
                            </Grid>

                            <Grid item container xs={6} justifyContent={'flex-end'}>
                                <Typography variant='customH3'>{`${message.length}/160`}</Typography>
                            </Grid>
                        </Grid>


                        <Grid item>
                            <TextField
                                multiline
                                inputProps={{ maxLength: 160 }}
                                size="small"
                                sx={{ "& .MuiInputBase-root": { width: '25vw' } }}
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container direction={'column'} spacing={1}>
                        <Grid item container width={'25vw'}>
                            <Grid item>
                                <Typography variant='customH3'>Deeplink (Optional)</Typography> 
                            </Grid>
                            <Grid item>
                                <Typography variant='customBody'>
                                    If attached, clicking on the notification will navigate to the product's virtual try-on in the specified link. Links must be in the form of https://tryo.io (Ex. https://tryo.io/products/trending/puma/carina-2.0/white-black)
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <TextField
                                size="small"
                                sx={{ "& .MuiInputBase-root": { width: '30vw' } }}
                                value={deeplink}
                                onChange={e => setDeeplink(e.target.value)}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container>
                        <Alert severity='error'>WARNING !!! TRYO IOS APP IS NOW ON PRODUCTION. ANY NOTIFICATION IS GOING TO BE SENT TO ALL USERS WHO HAS TRYO APP !!!</Alert>
                    </Grid>

                    <Grid item container justifyContent={'flex-start'}>
                        <Grid item xs={12}>
                            <RadioGroup
                                value={selectedSendOption}
                                onChange={(event) => { setSelectedTypeOption(event.target.value as SendType) }}
                                sx={{ display: 'flex', flexDirection: 'row' }}
                            >
                                <FormControlLabel value={sendOptions.DIRECT} control={<Radio />} label="Send Now" />
                                <FormControlLabel value={sendOptions.SCHEDULE} control={<Radio />} label="Schedule Date" />
                            </RadioGroup>
                        </Grid>
                        {selectedSendOption === sendOptions.SCHEDULE &&
                            <Grid item xs={12}>
                                <NotificationDatePicker callback={(date) => {
                                    setSendDate(date)
                                }} />
                            </Grid>
                        }
                    </Grid>



                    <Grid item>
                        <CustomLightButton onClick={buttonClicked}> SEND PUSH NOTIFICATION </CustomLightButton>
                    </Grid>

                    <Grid item>
                        <Typography className={styles.underlined_title} variant='customH3'>Push Notification History</Typography>
                        <BasicTable
                            pagination={true}
                            paginationInfo={paginationInfo}
                            tableData={notificationHistory}
                            handleChangePage={historyPageUpdate}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={1.5} />

            </Grid>
            <WarningPrompt
                isOpen={isModalOpen}
                onConfirm={() => { sendButtonClicked(); }}
                setIsOpen={() => { setIsModalOpen(false) }}
                promptMessage={'Attention! You are about to send a push notification.'}
            />
        </>
    )
}

export default PushNotification;
