import {
    Autocomplete,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    Grid,
    Slider,
    Stack,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import CustomDropZone from '../components/CustomDropZone/CustomDropZone';
import Header from '../components/Header/Header';
import { ModelViewerConfig, ProductLink, VariantModelData, VariantModelUploadData } from '../shared/interfaces/VariantModel';
import { useGetAllBrands } from '../shared/query-hooks/BrandService';
import { useUploadFile, useUploadMultipleFiles } from '../shared/query-hooks/FileUploadService';
import {
    useCreateSovrnLink,
    useDeleteVariantModel,
    useGetVariantModelById,
    useUpdateVarianteModel
} from '../shared/query-hooks/VariantModelService';
import { categories, category, model_status, Option } from '../shared/interfaces/Enums';
import { useGetAllBaseModels } from '../shared/query-hooks/ModelService';
import CustomLightButton from '../components/CustomButton/CustomButton';
import { FileUploadData, MultipleFilesUploadData } from '../shared/interfaces/FileModel';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { categoryChanger } from '../shared/utils/CategoryChanger';
import { useGetEnvironmentImageList } from '../shared/query-hooks/EnvironmentImageService';
import { authKeys, authRoles, checkAuth } from '../enums/authKeys';
import { useAuth } from 'qreal-auth-provider/src';
import { AppStates } from '../enums/app';

const EditVariantModel = () => {
    let params = useParams();
    let navigate = useNavigate();

    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);

    const handleOpenUpdateDialog = () => {
        setOpenUpdateDialog(true);
    };

    const handleCloseUpdateDialog = () => {
        setOpenUpdateDialog(false);
    };

    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    //Auth provider
    const { authList, user } = useAuth();
    const deletePermission = checkAuth(authList, authKeys.delete_model);

    //variant-model states
    const [variantModelName, setVariantModelName] = useState("")
    const [selectedBrand, setSelectedBrand] = useState("")
    const [selectedCategory, setSelectedCategory] = useState("");
    const [selectedModelStatus, setSelectedModelStatus] = useState("");
    const [tags, setTags] = useState<string[]>([]);
    const [baseModelId, setBaseModelId] = useState("");
    const [envImgId, setEnvImgId] = useState("")
    const [isTrending, setIsTrending] = useState<boolean>(false);

    const [modelViewerConfig, setModelViewerConfig] = useState<ModelViewerConfig[]>([{ exposure: "1", "shadow-intensity": "1", "shadow-softness": "1" }])
    const [mvExposureConfig, setMvExposureConfig] = useState("1")
    const [mvIntesityConfig, setMvIntesityConfig] = useState("1")
    const [mvSoftnessConfig, setMvSoftnessConfig] = useState("1")

    const [metadata, setMetadata] = useState<string[]>([]);
    const [productLinks, setProductLinks] = useState<ProductLink[]>([{ name: "", url: "" }]);
    const [facebookLink, setFacebookLink] = useState("");
    const [instagramLink, setInstagramLink] = useState("");
    const [sparkProjectLink, setSparkProjectLink] = useState("");
    const [snapchatLink, setSnapchatLink] = useState("");
    const [mvDemoLink, setMvDemoLink] = useState("");
    const [wannaModelId, setWannaModelId] = useState("");
    const [description, setDescription] = useState("");
    const [seoDescription, setSeoDescription] = useState("");
    const [price, setPrice] = useState(0);

    //file upload states
    const [thumbnailFile, setThumbnailFile] = useState();
    const [referenceImages, setReferenceImages] = useState();
    const [completedCGModelFile, setCompletedCGModelFile] = useState(); //.blend
    const [substancePainterFile, setSubstancePainterFile] = useState(); //.spp
    const [sourceMaskTextureFile, setSourceMaskTextureFile] = useState(); //.png
    const [photogrammetryFile, setPhotogrammetryFile] = useState(); //.fbx
    const [USDZFile, setUSDZFile] = useState(); //.usdz
    const [GLBFile, setGLBFile] = useState(); //.glb

    //sovrn link generate
    const [url, setUrl] = useState("")
    const [sovrnURLName, setSovrnURLName] = useState("")
    const { data: sovrnLinkData, status: sovrnLinkGenerateStatus, refetch: sovrnLinkGenerate } = useCreateSovrnLink(url);

    useEffect(() => {
        if (sovrnLinkGenerateStatus === "success") {
            setProductLinks([...productLinks, { name: sovrnURLName, url: sovrnLinkData.data[0].anywhereUrl }])
        }
    }, [sovrnLinkGenerateStatus])

    useEffect(() => {
        if (url) {
            sovrnLinkGenerate()
        }
    }, [url])

    //get brand data for brand-select
    const [brands, setBrands] = useState<Option[]>([]);
    const { data: allBrandData, status: allBrandDataStatus, isSuccess: allBrandDataIsSuccess } = useGetAllBrands();
    useEffect(() => {
        if (allBrandDataStatus === "success") {
            const tempArray: Option[] = []
            allBrandData?.data.forEach((brand, index) => {
                tempArray[index] = { value: brand._id, label: brand.brand_name }
            });
            setBrands(tempArray);
        }
    }, [allBrandDataStatus])

    //get base-model data for base-model-select
    const [baseModels, setBaseModels] = useState<Option[]>([]);
    const { data: allBaseModelData, status: allBaseModelDataStatus } = useGetAllBaseModels();
    useEffect(() => {
        if (allBaseModelDataStatus === "success") {
            const tempArray: Option[] = []
            allBaseModelData?.data.forEach((baseModel, index) => {
                tempArray[index] = { value: baseModel._id!, label: baseModel.model_name }
            })
            setBaseModels(tempArray);
        }
    }, [allBaseModelDataStatus])

    //get env-img data for env-img-select
    const [envImgs, setEnvImgs] = useState<Option[]>([]);
    const { data: allEnvImgData, status: allEnvImgStatus } = useGetEnvironmentImageList();
    useEffect(() => {
        if (allEnvImgStatus === "success") {
            const tempArray: Option[] = []
            allEnvImgData.data.forEach((envImg, index) => {
                tempArray[index] = { value: envImg._id, label: envImg.environment_name }
            })
            setEnvImgs(tempArray);
        }
    }, [allEnvImgStatus])

    //get model by id
    const {
        data: variantModelResponseData,
        status: variantModelResponseStatus
    } = useGetVariantModelById(params.variantModelId!);
    useEffect(() => {
        if (variantModelResponseStatus === "success") {
            setVariantModelName(variantModelResponseData.data[0].variant_model_name)
            setSelectedBrand(variantModelResponseData.data[0].brand_detail[0]._id)
            const new_category = categoryChanger(variantModelResponseData.data[0].category)
            setSelectedCategory(new_category)
            setSelectedModelStatus(variantModelResponseData.data[0].variant_model_status)
            setTags(variantModelResponseData.data[0].tags)
            setBaseModelId(variantModelResponseData.data[0].base_model_detail[0]._id)
            setIsTrending(variantModelResponseData.data[0].is_trending)
            setEnvImgId(variantModelResponseData.data[0].environment_detail[0]?._id)

            setMvExposureConfig(variantModelResponseData.data[0].model_viewer_config[0]?.exposure)
            setMvIntesityConfig(variantModelResponseData.data[0].model_viewer_config[0]['shadow-intensity'])
            setMvSoftnessConfig(variantModelResponseData.data[0].model_viewer_config[0]['shadow-softness'])
            setModelViewerConfig([{ exposure: variantModelResponseData.data[0].model_viewer_config[0]?.exposure, "shadow-intensity": variantModelResponseData.data[0].model_viewer_config[0]['shadow-intensity'], "shadow-softness": variantModelResponseData.data[0].model_viewer_config[0]['shadow-softness'] }])

            setMetadata(variantModelResponseData.data[0].metadata)
            setProductLinks(variantModelResponseData.data[0].product_links)
            setFacebookLink(variantModelResponseData.data[0].facebook_link)
            setInstagramLink(variantModelResponseData.data[0].instagram_link)
            setSparkProjectLink(variantModelResponseData.data[0].spark_project_link)
            setSnapchatLink(variantModelResponseData.data[0].snapchat_link)
            setMvDemoLink(variantModelResponseData.data[0].mv_demo_link)
            setWannaModelId(variantModelResponseData.data[0].wanna_model)
            setDescription(variantModelResponseData.data[0].description)
            setSeoDescription(variantModelResponseData.data[0].seo_description)
            setPrice(variantModelResponseData.data[0].price)
        }
    }, [variantModelResponseData])

    //variant-model update/delete
    const {
        mutate: updateVariantModelMutate,
        status: updateVariantModelStatus,
        data: updateVariantModelResponseData
    } = useUpdateVarianteModel();
    const { mutate: deleteVariantModelMutate, status: deleteVariantModelMutateStatus } = useDeleteVariantModel();

    //file upload mutates
    const { mutate: thumbnailFileUploadMutate, status: thumbnailFileUploadStatus } = useUploadFile();
    const { mutate: refImageUploadMutate, status: refImageUploadStatus } = useUploadMultipleFiles();
    const { mutate: completedCGModelFileUploadMutate, status: completedCGModelFileUploadStatus } = useUploadFile();
    const { mutate: substancePainterFileUploadMutate, status: substancePainterFileUploadStatus } = useUploadFile();
    const { mutate: sourceMaskTextureFileUploadMutate, status: sourceMaskTextureFileUploadStatus } = useUploadMultipleFiles();
    const { mutate: photogrammetryFileUploadMutate, status: photogrammetryFileUploadStatus } = useUploadFile();
    const { mutate: GLBFileUploadMutate, status: GLBFileUploadStatus } = useUploadFile();
    const { mutate: USDZFileUploadMutate, status: USDZFileUploadStatus } = useUploadFile();

    useEffect(() => {
        if (updateVariantModelResponseData?.data.data[0].thumbnail_file) {
            if (thumbnailFile) {
                const thumbnailUploadData: FileUploadData = {
                    file: thumbnailFile[0],
                    url: updateVariantModelResponseData?.data.data[0].thumbnail_file.pre_signed_url,//
                    content_type: "image/webp"
                }
                thumbnailFileUploadMutate(thumbnailUploadData);
            }
        }
        if (updateVariantModelResponseData?.data.data[0].reference_images) {
            if (referenceImages) {
                const referenceImagesUploadData: MultipleFilesUploadData = {
                    files: referenceImages,
                    urls: updateVariantModelResponseData?.data.data[0].reference_images,
                    content_type: "image/jpeg"
                }
                refImageUploadMutate(referenceImagesUploadData);
            }
        }
        if (updateVariantModelResponseData?.data.data[0].completed_cg_model_file) {
            if (completedCGModelFile) {
                const completedCGModelFileUploadData: FileUploadData = {
                    file: completedCGModelFile[0],
                    url: updateVariantModelResponseData?.data.data[0].completed_cg_model_file.pre_signed_url,
                    content_type: "application/octet-stream",
                }
                completedCGModelFileUploadMutate(completedCGModelFileUploadData);
            }
        }
        if (updateVariantModelResponseData?.data.data[0].substance_painter_file) {
            if (substancePainterFile) {
                const substancePainterFileUploadData: FileUploadData = {
                    file: substancePainterFile[0],
                    url: updateVariantModelResponseData?.data.data[0].substance_painter_file.pre_signed_url,
                    content_type: "application/octet-stream",
                }
                substancePainterFileUploadMutate(substancePainterFileUploadData);
            }
        }
        if (updateVariantModelResponseData?.data.data[0].source_mask_textures) {
            if (sourceMaskTextureFile) {
                const sourceMaskTextureFileUploadData: MultipleFilesUploadData = {
                    files: sourceMaskTextureFile,
                    urls: updateVariantModelResponseData?.data.data[0].source_mask_textures,
                    content_type: "image/png",
                }
                sourceMaskTextureFileUploadMutate(sourceMaskTextureFileUploadData);
            }
        }
        if (updateVariantModelResponseData?.data.data[0].photogrammetry_file) {
            if (photogrammetryFile) {
                const photogrammetryFileUploadData: FileUploadData = {
                    file: photogrammetryFile[0],
                    url: updateVariantModelResponseData?.data.data[0].photogrammetry_file.pre_signed_url,
                    content_type: "application/octet-stream",
                }
                photogrammetryFileUploadMutate(photogrammetryFileUploadData);
            }
        }
        if (updateVariantModelResponseData?.data.data[0].glb_file) {
            if (GLBFile) {
                const GLBFileUploadData: FileUploadData = {
                    file: GLBFile[0],
                    url: updateVariantModelResponseData?.data.data[0].glb_file.pre_signed_url,
                    content_type: "model/gltf-binary"
                }
                GLBFileUploadMutate(GLBFileUploadData)
            }
        }
        if (updateVariantModelResponseData?.data.data[0].usdz_file) {
            if (USDZFile) {
                const USDZFileUploadData: FileUploadData = {
                    file: USDZFile[0],
                    url: updateVariantModelResponseData?.data.data[0].usdz_file.pre_signed_url,
                    content_type: "model/vnd.usdz+zip"
                }
                USDZFileUploadMutate(USDZFileUploadData)
            }
        }
    }, [updateVariantModelResponseData])

    const handleTagsChange = (e: any, newValue: any) => {
        setTags(newValue)
    }

    const handleMetadataChange = (e: any, newValue: any) => {
        setMetadata(newValue)
    }

    const handleIsTrendingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsTrending(event.target.checked);
    };

    const handleExposureSliderChange = (event: Event, value: number | number[]) => {
        setMvExposureConfig(value.toString())
        handleModelViewerConfig()
    };

    const handleIntensitySliderChange = (event: Event, value: number | number[]) => {
        setMvIntesityConfig(value.toString())
        handleModelViewerConfig()
    };

    const handleSoftnessSliderChange = (event: Event, value: number | number[]) => {
        setMvSoftnessConfig(value.toString())
        handleModelViewerConfig()
    };

    const handleModelViewerConfig = () => {
        const tempConfig = [{ exposure: mvExposureConfig, "shadow-intensity": mvIntesityConfig, "shadow-softness": mvSoftnessConfig }]
        setModelViewerConfig(tempConfig)
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const handleProductLinkChange = (i, e) => {
        let newProductLinks = [...productLinks];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newProductLinks[i][e.target.name] = e.target.value;
        setProductLinks(newProductLinks);
    }

    const addProductLinkFields = () => {
        setProductLinks([...productLinks, { name: "", url: "" }])
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const removeProductLinkField = (i) => {
        let newProductLinks = [...productLinks];
        newProductLinks.splice(i, 1);
        setProductLinks(newProductLinks);
    }

    const handleGenerateSovrnLink = (element: ProductLink, index: number) => {
        if (element.url) {
            setSovrnURLName(element.name + " Sovrn URL")
            if (element.url.startsWith("https://")) {
                setUrl(element.url)
            } else {
                setUrl("https://" + element.url)
            }
        }
    }

    const handleVariantModelUpdate = () => {
        const updateVariantModelData: VariantModelUploadData = {
            _id: params.variantModelId!,
            variant_model_name: variantModelName,
            base_model_id: baseModelId,
            brand_id: selectedBrand,
            category: selectedCategory,
            variant_model_status: selectedModelStatus,
            is_trending: isTrending,
            tags: tags,
            metadata: metadata,
            facebook_link: facebookLink,
            instagram_link: instagramLink,
            snapchat_link: snapchatLink,
            spark_project_link: sparkProjectLink,
            mv_demo_link: mvDemoLink,
            product_links: productLinks,
            model_viewer_config: modelViewerConfig,
            environment_id: envImgId,
            wanna_model: wannaModelId,
            description: description,
            seo_description: seoDescription,
            price: price
        }
        if (thumbnailFile) {
            updateVariantModelData.thumbnail_file = "update"
        }
        if (referenceImages) {
            updateVariantModelData.reference_images = "update"
        }
        if (completedCGModelFile) {
            updateVariantModelData.completed_cg_model_file = "update"
        }
        if (substancePainterFile) {
            updateVariantModelData.substance_painter_file = "update"
        }
        if (sourceMaskTextureFile) {
            updateVariantModelData.source_mask_textures = "update"
        }
        if (photogrammetryFile) {
            updateVariantModelData.photogrammetry_file = "update"
        }
        if (GLBFile) {
            updateVariantModelData.glb_file = "update"
        }
        if (USDZFile) {
            updateVariantModelData.usdz_file = "update"
        }
        updateVariantModelMutate(updateVariantModelData);
        handleOpenUpdateDialog();
    }

    const handleDeleteVariantModel = () => {
        deleteVariantModelMutate(params.variantModelId!);
    }

    useEffect(() => {
        if (deleteVariantModelMutateStatus === 'success') {
            navigate("/");
        }
    }, [deleteVariantModelMutateStatus]);

    return (
        <>
            <Header header={`UPDATE`} itemModel={`${variantModelResponseData?.data[0].brand_detail[0].brand_name} - ${variantModelResponseData?.data[0].base_model_detail[0].model_name}`} variantModelName={variantModelResponseData?.data[0].variant_model_name} status={variantModelResponseData?.data[0].variant_model_status} />
            <Grid container marginTop={"84px"}>
                <Grid item xs={1.5}></Grid>
                <Grid item xs={9} container direction={"column"} justifyContent={"center"} alignItems={"center"}>
                    {variantModelResponseStatus === "loading" ?
                        <Typography variant='customH3'>Loading Variant Model Data</Typography> :
                        variantModelResponseStatus === "error" ?
                            <Typography variant='customH3'>Error loading Variant Model Data</Typography> :
                            variantModelResponseStatus === "success" && allBrandDataStatus === "success" && allBaseModelDataStatus === "success" && allEnvImgStatus === "success" ?
                                <Grid item container direction={"column"} spacing={4}>
                                    <Grid item container direction='column'>
                                        <Grid item> <Typography variant='customH3' mb={0.5}> Thumbnail
                                            image </Typography> </Grid>
                                        <Grid item> <Typography variant='customBody' mb={0.5}> Supported file types:
                                            .webp </Typography> </Grid>
                                        <Grid item>
                                            <CustomDropZone
                                                setFile={setThumbnailFile}
                                                fileName={"Thumbnail"}
                                                isMultiple={true}
                                                accept={{ 'image/webp': ['.webp'] }}
                                                maxFiles={10}
                                                isDisabled={false}
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid item container direction={"column"}>
                                        <Grid item> <Typography variant='customH3' mb={1}> Brand </Typography> </Grid>
                                        <Grid item width={'417px'}>
                                            <Autocomplete size='small' disableClearable={true}
                                                value={brands.find(element => element.value === selectedBrand) || {
                                                    label: "",
                                                    value: ""
                                                }} onChange={(e, selected) => {
                                                    setSelectedBrand(selected?.value!)
                                                }} renderInput={(params) => <TextField {...params} />} options={brands} />
                                        </Grid>
                                    </Grid>

                                    <Grid item container direction={"column"}>
                                        <Grid item> <Typography variant='customH3' mb={1}> Base Model </Typography>
                                        </Grid>
                                        <Grid item width={'417px'}>
                                            <Autocomplete size='small' disableClearable={true}
                                                value={baseModels.find(element => element.value === baseModelId) || {
                                                    label: "",
                                                    value: ""
                                                }} onChange={(e, selected) => {
                                                    setBaseModelId(selected?.value!)
                                                }} renderInput={(params) => <TextField {...params} />}
                                                options={baseModels} />
                                        </Grid>
                                    </Grid>

                                    <Grid item container direction={"column"}>
                                        <Typography variant="customH3" mb={1}> Variant Model Name </Typography>
                                        <TextField value={variantModelName || ""}
                                            onChange={(e) => setVariantModelName(e.target.value)}
                                            sx={{ "& .MuiInputBase-root": { height: '40px', width: '417px' } }} />
                                    </Grid>

                                    <Grid item container direction={'column'}>
                                        <Grid item>
                                            <Typography variant='customH3' mb={1}> Category </Typography>
                                        </Grid>
                                        <Grid item width={'417px'}>
                                            <Autocomplete size='small' disableClearable={true}
                                                value={category.find(element => element.value === selectedCategory) || {
                                                    label: "",
                                                    value: ""
                                                }} onChange={(e, selected) => {
                                                    setSelectedCategory(selected?.value!)
                                                }} renderInput={(params) => <TextField {...params} />} options={category} />
                                        </Grid>
                                    </Grid>

                                    <Grid item container direction={'column'}>
                                        <Grid item>
                                            <Typography variant='customH3' mb={1}> Tags </Typography>
                                        </Grid>
                                        <Grid item width={"417px"}>
                                            <Autocomplete
                                                size='small'
                                                multiple
                                                id="tags-filled"
                                                options={categories}
                                                value={[...tags]}
                                                onChange={handleTagsChange}
                                                freeSolo
                                                renderTags={(value: readonly string[], getTagProps) =>
                                                    value.map((option: string, index: number) => (
                                                        <Chip label={option} {...getTagProps({ index })} />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField {...params} />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item container direction={'column'}>
                                        <Grid item>
                                            <Typography variant='customH3'> Metadata </Typography>
                                        </Grid>
                                        <Grid item width={'417px'}>
                                            <Autocomplete
                                                size='small'
                                                multiple
                                                id="tags-filled"
                                                options={categories}
                                                value={[...metadata]}
                                                onChange={handleMetadataChange}
                                                freeSolo
                                                renderTags={(value: readonly string[], getTagProps) =>
                                                    value.map((option: string, index: number) => (
                                                        <Chip label={option} {...getTagProps({ index })} />
                                                    ))
                                                }
                                                renderInput={(params) => (
                                                    <TextField {...params} />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item container direction={'column'} spacing={'16px'}>
                                        <Grid item>
                                            <Typography variant='customH3'> Wanna Model ID </Typography>
                                        </Grid>

                                        <Grid item>
                                            <TextField value={wannaModelId} onChange={(e) => setWannaModelId(e.target.value)} fullWidth
                                                sx={{ "& .MuiInputBase-root": { height: '40px', width: '417px' } }} />
                                        </Grid>
                                    </Grid>

                                    {/* TODO: delete div to make price and description visible when backend bug resolves */}
                                    {/* <div style={{display:"none"}}> */}
                                    <Grid item container direction={'column'} spacing={'16px'}>
                                        <Grid item>
                                            <Typography variant='customH3'> Price </Typography>
                                        </Grid>

                                        <Grid item>
                                            <TextField value={price} type="number" onChange={(e) => setPrice(+e.target.value)} fullWidth
                                                sx={{ "& .MuiInputBase-root": { height: '40px', width: '417px' } }} />
                                        </Grid>
                                    </Grid>

                                    <Grid item container direction={'column'} spacing={'16px'}>
                                        <Grid item>
                                            <Typography variant='customH3'> Description </Typography>
                                        </Grid>

                                        <Grid item>
                                            <TextField value={description} onChange={(e) => setDescription(e.target.value)} fullWidth
                                                sx={{ "& .MuiInputBase-root": { height: '40px', width: '417px' } }} />
                                        </Grid>
                                    </Grid>

                                    <Grid item container direction={'column'} spacing={'16px'}>
                                        <Grid item>
                                            <Typography variant='customH3'> Seo Content Description </Typography>
                                        </Grid>

                                        <Grid item>
                                            <TextField value={seoDescription} onChange={(e) => setSeoDescription(e.target.value)} fullWidth
                                                sx={{ "& .MuiInputBase-root": { height: '40px', width: '417px' } }} />
                                        </Grid>
                                    </Grid>
                                    {/* </div> */}

                                    <Grid item container direction={'column'}>
                                        <Grid item>
                                            <Typography variant='customH3'> Trending item </Typography>
                                        </Grid>
                                        <Grid item>
                                            <Switch
                                                value={isTrending}
                                                color="secondary"
                                                checked={isTrending}
                                                onChange={handleIsTrendingChange}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item container direction={"column"} spacing={2}>
                                        <Grid item>
                                            <Typography variant='customH3'> ModelViewer Config </Typography>
                                        </Grid>
                                        <Grid item container direction={"column"} spacing={1.5}>
                                            <Grid item>
                                                <Stack direction={"row"} alignItems={"center"}>
                                                    <Typography variant='customBody' mr={2}> Exposure: </Typography>
                                                    <Slider
                                                        sx={{ width: 300 }}
                                                        size="small"
                                                        defaultValue={parseFloat(variantModelResponseData.data[0].model_viewer_config[0].exposure)}
                                                        aria-label="Exposure"
                                                        valueLabelDisplay="auto"
                                                        color="secondary"
                                                        max={2}
                                                        min={0}
                                                        step={0.05}
                                                        onChange={handleExposureSliderChange}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item>
                                                <Stack direction={"row"} alignItems={"center"}>
                                                    <Typography variant='customBody' mr={2}> Shadow-Intensity: </Typography>
                                                    <Slider
                                                        sx={{ width: 300 }}
                                                        size="small"
                                                        defaultValue={parseFloat(variantModelResponseData.data[0].model_viewer_config[0]['shadow-intensity'])}
                                                        aria-label="Intensity"
                                                        valueLabelDisplay="auto"
                                                        color="secondary"
                                                        max={1}
                                                        min={0}
                                                        step={0.05}
                                                        onChange={handleIntensitySliderChange}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item>
                                                <Stack direction={"row"} alignItems={"center"}>
                                                    <Typography variant='customBody' mr={2}> Shadow-Softness: </Typography>
                                                    <Slider
                                                        sx={{ width: 300 }}
                                                        size="small"
                                                        defaultValue={parseFloat(variantModelResponseData.data[0].model_viewer_config[0]['shadow-softness'])}
                                                        aria-label="Softness"
                                                        valueLabelDisplay="auto"
                                                        color="secondary"
                                                        max={1}
                                                        min={0}
                                                        step={0.05}
                                                        onChange={handleSoftnessSliderChange}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item container direction={"column"}>
                                        <Grid item> <Typography variant='customH3' mb={1}> Environment Image </Typography>
                                        </Grid>
                                        <Grid item width={'417px'}>
                                            <Autocomplete size='small' disableClearable={true}
                                                value={envImgs.find(element => element.value === envImgId) || {
                                                    label: "",
                                                    value: ""
                                                }} onChange={(e, selected) => {
                                                    setEnvImgId(selected?.value!)
                                                }} renderInput={(params) => <TextField {...params} />}
                                                options={envImgs} />
                                        </Grid>
                                    </Grid>

                                    <Grid item container direction={"column"} spacing={3}>
                                        <Grid item>
                                            <Typography variant='customH3'>Links</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='formLabel'> Product Links </Typography>
                                            {productLinks.map((element, index) => (
                                                <Stack direction={"row"} key={index} spacing={"30px"} py={1}>
                                                    <TextField placeholder='Display Name' name='name'
                                                        value={element.name || ""}
                                                        onChange={e => handleProductLinkChange(index, e)} sx={{
                                                            "& .MuiInputBase-root": {
                                                                height: '40px',
                                                                width: '300px'
                                                            }
                                                        }}></TextField>
                                                    <TextField placeholder='URL' name='url' value={element.url || ""}
                                                        onChange={e => handleProductLinkChange(index, e)} sx={{
                                                            "& .MuiInputBase-root": {
                                                                height: '40px',
                                                                width: '300px'
                                                            }
                                                        }}></TextField>
                                                    {!element.url.includes("viglink") ?
                                                        <CustomLightButton onClick={() => handleGenerateSovrnLink(element, index)}>Sovrn Link</CustomLightButton> : null
                                                    }
                                                    {index ? <CustomLightButton
                                                        onClick={() => removeProductLinkField(index)}>remove</CustomLightButton> : null}
                                                </Stack>
                                            ))}
                                            <CustomLightButton onClick={addProductLinkFields}>add a product
                                                link</CustomLightButton>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='formLabel'> Facebook Link </Typography>
                                            <TextField value={facebookLink}
                                                onChange={(e) => setFacebookLink(e.target.value)} fullWidth
                                                sx={{ "& .MuiInputBase-root": { height: '40px', width: '417px' } }} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='formLabel'> Instagram Link </Typography>
                                            <TextField value={instagramLink}
                                                onChange={(e) => setInstagramLink(e.target.value)} fullWidth
                                                sx={{ "& .MuiInputBase-root": { height: '40px', width: '417px' } }} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='formLabel'> Spark Project Link </Typography>
                                            <TextField value={sparkProjectLink}
                                                onChange={(e) => setSparkProjectLink(e.target.value)} fullWidth
                                                sx={{ "& .MuiInputBase-root": { height: '40px', width: '417px' } }} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='formLabel'> Snapchat Link </Typography>
                                            <TextField value={snapchatLink}
                                                onChange={(e) => setSnapchatLink(e.target.value)} fullWidth
                                                sx={{ "& .MuiInputBase-root": { height: '40px', width: '417px' } }} />
                                        </Grid>
                                        <Grid item>
                                            <Typography variant='formLabel'> Modelviewer Demo Link </Typography>
                                            <TextField value={mvDemoLink}
                                                onChange={(e) => setMvDemoLink(e.target.value)} fullWidth
                                                sx={{ "& .MuiInputBase-root": { height: '40px', width: '417px' } }} />
                                        </Grid>
                                    </Grid>

                                    <Grid item container direction={"column"}>
                                        <Grid item pb={2.5}>
                                            <Typography variant='customH3'> Upload files </Typography>
                                        </Grid>

                                        <Grid item container spacing={5}>
                                            <Grid item>
                                                <Stack>
                                                    <Stack pb={1.5}>
                                                        <Typography variant='customBodyStrong'> GLB file </Typography>
                                                        <Typography variant='customBodySm'> Supported File Type:
                                                            .glb</Typography>
                                                    </Stack>
                                                    <CustomDropZone
                                                        setFile={setGLBFile}
                                                        fileName={"GLB Model"}
                                                        isMultiple={true}
                                                        accept={{ 'model/gltf-binary': ['.glb'] }}
                                                        maxFiles={10}
                                                        isDisabled={false}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item>
                                                <Stack>
                                                    <Stack pb={1.5}>
                                                        <Typography variant='customBodyStrong'> USDZ file </Typography>
                                                        <Typography variant='customBodySm'> Supported File Type:
                                                            .usdz</Typography>
                                                    </Stack>
                                                    <CustomDropZone
                                                        setFile={setUSDZFile}
                                                        fileName={"USDZ Model"}
                                                        isMultiple={true}
                                                        accept={{ 'model/vnd.usdz+zip': ['.usdz'] }}
                                                        maxFiles={10}
                                                        isDisabled={false}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item>
                                                <Stack>
                                                    <Stack pb={1.5}>
                                                        <Typography variant='customBodyStrong'> Photogrammetry
                                                            file </Typography>
                                                        <Typography variant='customBodySm'> Supported File Type:
                                                            .fbx</Typography>
                                                    </Stack>
                                                    <CustomDropZone
                                                        setFile={setPhotogrammetryFile}
                                                        fileName={"Photogrammetry Model"}
                                                        isMultiple={true}
                                                        accept={{ 'application/octet-stream': ['.fbx'] }}
                                                        maxFiles={10}
                                                        isDisabled={false}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item>
                                                <Stack>
                                                    <Stack pb={1.5}>
                                                        <Typography variant='customBodyStrong'> CG file </Typography>
                                                        <Typography variant='customBodySm'> Supported File Type:
                                                            .blend</Typography>
                                                    </Stack>
                                                    <CustomDropZone
                                                        setFile={setCompletedCGModelFile}
                                                        fileName={"CG Model"}
                                                        isMultiple={true}
                                                        accept={{ 'application/octet-stream': ['.blend'] }}
                                                        maxFiles={10}
                                                        isDisabled={false}
                                                    />
                                                </Stack>
                                            </Grid>
                                            <Grid item>
                                                <Stack>
                                                    <Stack pb={1.5}>
                                                        <Typography variant='customBodyStrong'> Reference
                                                            Images </Typography>
                                                        <Typography variant='customBodySm'> Supported File Type:
                                                            .jpeg</Typography>
                                                    </Stack>
                                                    <CustomDropZone
                                                        setFile={setReferenceImages}
                                                        fileName={"Reference Images"}
                                                        isMultiple={true}
                                                        accept={{ 'image/jpeg': ['.jpeg', '.jpg'] }}
                                                        maxFiles={10}
                                                        isDisabled={false}
                                                    />
                                                </Stack>
                                            </Grid>
                                            {/* <Grid item>
                        <Stack>
                        <Stack pb={1.5}>
                            <Typography variant='customBodyStrong'> Substance Painter </Typography>
                            <Typography variant='customBodySm'> Supported File Type: .spp</Typography>
                          </Stack>
                          <CustomDropZone
                            setFile={props.setCompletedCGModelFile}
                            fileName={"Substance Painter"}
                            isMultiple={true}
                            accept={{'application/octet-stream': ['.spp']}}
                            maxFiles={10}
                          />
                        </Stack>
                        </Grid> */}
                                            <Grid item>
                                                <Stack>
                                                    <Stack pb={1.5}>
                                                        <Typography variant='customBodyStrong'> Source
                                                            Textures </Typography>
                                                        <Typography variant='customBodySm'> Supported File Type:
                                                            .png</Typography>
                                                    </Stack>
                                                    <CustomDropZone
                                                        setFile={setSourceMaskTextureFile}
                                                        fileName={"Source Textures"}
                                                        isMultiple={true}
                                                        accept={{ 'image/png': ['.png'] }}
                                                        maxFiles={10}
                                                        isDisabled={false}
                                                    />
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {(user.role === authRoles.ADMIN || process.env.REACT_APP_APP_STATE === AppStates.DEV) && <Grid item container direction={'column'}>
                                        <Grid item>
                                            <Typography variant='customH3' mb={1}> Status </Typography>
                                        </Grid>

                                        <Grid item width={'417px'}>
                                            <Autocomplete size='small' disableClearable={true}
                                                value={model_status.find(element => element.value === selectedModelStatus) || {
                                                    label: "",
                                                    value: ""
                                                }} onChange={(e, selected) => {
                                                    setSelectedModelStatus(selected?.value!)
                                                }} renderInput={(params) => <TextField {...params} />}
                                                options={model_status} />
                                        </Grid>
                                    </Grid>
                                    }

                                    <Grid item container mb={10} spacing={2}>
                                        <Grid item>
                                            <Button variant='contained' onClick={handleVariantModelUpdate}>Update
                                                Model</Button>
                                        </Grid>
                                        {(deletePermission && (user.role === authRoles.ADMIN || process.env.REACT_APP_APP_STATE === AppStates.DEV)) && <Grid item>
                                            <Button variant='contained' color='error' onClick={handleOpenDeleteDialog}>Delete
                                                Model</Button>
                                        </Grid>}
                                    </Grid>

                                </Grid>
                                : null
                    }
                </Grid>
                <Grid item xs={1.5}></Grid>
            </Grid>
            <Dialog open={openUpdateDialog}>
                <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"} p={3} spacing={2}>
                    <Grid item container justifyContent={"center"} alignItems={"center"}>
                        {updateVariantModelStatus === "loading" ? (
                            <Stack direction={"row"} alignItems={"center"}>
                                <Typography variant='customBody' mr={2}>Updating {variantModelName}</Typography>
                                <CircularProgress color="secondary" />
                            </Stack>
                        ) : updateVariantModelStatus === "error" ? (
                            <Stack direction={"row"} alignItems={"center"}>
                                <Typography variant='customBody' mr={2}>Could not update {variantModelName}</Typography>
                                <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                            </Stack>
                        ) : updateVariantModelStatus === "success" ? (
                            <Stack direction={"row"} alignItems={"center"}>
                                <Typography variant='customBody' mr={2}>{variantModelName} updated
                                    successfully</Typography>
                                <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                            </Stack>
                        ) : (
                            <Typography variant="customBody" mr={2}>Waiting for {variantModelName} to
                                upload...</Typography>
                        )}
                    </Grid>
                    {thumbnailFile ? (
                        <Grid item container justifyContent={"center"} alignItems={"center"}>
                            {thumbnailFileUploadStatus === "loading" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Uploading thumbnail image</Typography>
                                    <CircularProgress color="secondary" />
                                </Stack>
                            ) : thumbnailFileUploadStatus === "error" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Could not upload thumbnail
                                        image</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                                </Stack>
                            ) : thumbnailFileUploadStatus === "success" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Thumbnail image uploaded
                                        successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </Stack>
                            ) : (
                                <Typography variant="customBody" mr={2}>Waiting for thumbnail image
                                    upload...</Typography>
                            )}
                        </Grid>
                    ) : null}
                    {referenceImages ? (
                        <Grid item container justifyContent={"center"} alignItems={"center"}>
                            {refImageUploadStatus === "loading" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Uploading reference images</Typography>
                                    <CircularProgress color="secondary" />
                                </Stack>
                            ) : refImageUploadStatus === "error" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Could not upload reference
                                        images</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                                </Stack>
                            ) : refImageUploadStatus === "success" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Reference images uploaded
                                        successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </Stack>
                            ) : (
                                <Typography variant="customBody" mr={2}>Waiting for reference images
                                    upload...</Typography>
                            )}
                        </Grid>
                    ) : null}
                    {completedCGModelFile ? (
                        <Grid item container justifyContent={"center"} alignItems={"center"}>
                            {completedCGModelFileUploadStatus === "loading" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Uploading completed CG model
                                        file</Typography>
                                    <CircularProgress color="secondary" />
                                </Stack>
                            ) : completedCGModelFileUploadStatus === "error" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Could not upload completed CG model
                                        file</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                                </Stack>
                            ) : completedCGModelFileUploadStatus === "success" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Completed CG model file uploaded
                                        successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </Stack>
                            ) : (
                                <Typography variant="customBody" mr={2}>Waiting for completed CG model file
                                    upload...</Typography>
                            )}
                        </Grid>
                    ) : null}
                    {substancePainterFile ? (
                        <Grid item container justifyContent={"center"} alignItems={"center"}>
                            {substancePainterFileUploadStatus === "loading" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Uploading Substance Painter
                                        file</Typography>
                                    <CircularProgress color="secondary" />
                                </Stack>
                            ) : substancePainterFileUploadStatus === "error" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Could not upload Substance Painter
                                        file</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                                </Stack>
                            ) : substancePainterFileUploadStatus === "success" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Substance Painter file uploaded
                                        successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </Stack>
                            ) : (
                                <Typography variant="customBody" mr={2}>Waiting for Substance Painter file
                                    upload...</Typography>
                            )}
                        </Grid>
                    ) : null}
                    {sourceMaskTextureFile ? (
                        <Grid item container justifyContent={"center"} alignItems={"center"}>
                            {sourceMaskTextureFileUploadStatus === "loading" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Uploading Source Mask Textures</Typography>
                                    <CircularProgress color="secondary" />
                                </Stack>
                            ) : sourceMaskTextureFileUploadStatus === "error" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Could not upload Source Mask
                                        Textures</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                                </Stack>
                            ) : sourceMaskTextureFileUploadStatus === "success" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Source Mask Textures uploaded
                                        successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </Stack>
                            ) : (
                                <Typography variant="customBody" mr={2}>Waiting for Source Mask Textures
                                    upload...</Typography>
                            )}
                        </Grid>
                    ) : null}
                    {photogrammetryFile ? (
                        <Grid item container justifyContent={"center"} alignItems={"center"}>
                            {photogrammetryFileUploadStatus === "loading" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Uploading Photogrammetry File</Typography>
                                    <CircularProgress color="secondary" />
                                </Stack>
                            ) : photogrammetryFileUploadStatus === "error" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Could not upload Photogrammetry
                                        File</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                                </Stack>
                            ) : photogrammetryFileUploadStatus === "success" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Photogrammetry File uploaded
                                        successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </Stack>
                            ) : (
                                <Typography variant="customBody" mr={2}>Waiting for Photogrammetry File
                                    upload...</Typography>
                            )}
                        </Grid>
                    ) : null}
                    {GLBFile ? (
                        <Grid item container justifyContent={"center"} alignItems={"center"}>
                            {GLBFileUploadStatus === "loading" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Uploading GLB File</Typography>
                                    <CircularProgress color="secondary" />
                                </Stack>
                            ) : GLBFileUploadStatus === "error" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Could not upload GLB File</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                                </Stack>
                            ) : GLBFileUploadStatus === "success" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>GLB File uploaded successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </Stack>
                            ) : (
                                <Typography variant="customBody" mr={2}>Waiting for GLB File upload...</Typography>
                            )}
                        </Grid>
                    ) : null}
                    {USDZFile ? (
                        <Grid item container justifyContent={"center"} alignItems={"center"}>
                            {USDZFileUploadStatus === "loading" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Uploading USDZ File</Typography>
                                    <CircularProgress color="secondary" />
                                </Stack>
                            ) : USDZFileUploadStatus === "error" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>Could not upload USDZ File</Typography>
                                    <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                                </Stack>
                            ) : USDZFileUploadStatus === "success" ? (
                                <Stack direction={"row"} alignItems={"center"}>
                                    <Typography variant='customBody' mr={2}>USDZ File uploaded successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                                </Stack>
                            ) : (
                                <Typography variant="customBody" mr={2}>Waiting for USDZ File upload...</Typography>
                            )}
                        </Grid>
                    ) : null}
                    <Grid item container justifyContent={"center"} alignItems={"center"}>
                        <Button variant='contained' onClick={handleCloseUpdateDialog}>OK</Button>
                    </Grid>
                </Grid>
            </Dialog>
            {deletePermission && <Dialog open={openDeleteDialog}>

                <Grid container justifyContent={"center"} alignItems={"center"} direction={"column"} p={3} spacing={2}>
                    <Grid item>
                        <Typography variant='customH3'>Delete {variantModelName}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant='customBody'>This action can not be undone</Typography>
                    </Grid>
                    <Grid item container justifyContent={"center"} alignItems={"center"}>
                        {deleteVariantModelMutateStatus === "loading" ? (
                            <Stack direction={"row"} alignItems={"center"}>
                                <Typography variant='customBody' mr={2}>Deleting {variantModelName}</Typography>
                                <CircularProgress color="secondary" />
                            </Stack>
                        ) : deleteVariantModelMutateStatus === "error" ? (
                            <Stack direction={"row"} alignItems={"center"}>
                                <Typography variant='customBody' mr={2}>Could not delete {variantModelName}</Typography>
                                <ErrorOutlineOutlinedIcon color='error' fontSize='large' />
                            </Stack>
                        ) : deleteVariantModelMutateStatus === "success" ? (
                            <Stack direction={"row"} alignItems={"center"}>
                                <Typography variant='customBody' mr={2}>{variantModelName} deleted
                                    successfully</Typography>
                                <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large' />
                            </Stack>
                        ) : null}
                    </Grid>
                    <Grid item container spacing={2} justifyContent={"flex-end"}>
                        <Grid item>
                            <Button onClick={handleDeleteVariantModel} variant="contained" color="error">delete</Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleCloseDeleteDialog} variant="contained">cancel</Button>
                        </Grid>
                    </Grid>
                </Grid>

            </Dialog>}
        </>
    )
}

export default EditVariantModel