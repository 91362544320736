import { Grid, TextField, CircularProgress, Typography, MenuItem, Stack, Dialog } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomDropZone from '../components/CustomDropZone/CustomDropZone'
import Header from '../components/Header/Header'
import { model_status, Option } from '../shared/interfaces/Enums'
import { useCreateEnvironmentImage } from '../shared/query-hooks/EnvironmentImageService'
import { useUploadFile } from '../shared/query-hooks/FileUploadService'
import Select, {OnChangeValue} from 'react-select';
import CustomLightButton from '../components/CustomButton/CustomButton'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { EnvironmentImageData, EnvironmentImageUploadData } from '../shared/interfaces/EnvironmentImage'
import { FileUploadData } from '../shared/interfaces/FileModel'
const CreateEnvironmentImage = () => {

  const [openEnvImgCreationModal, setOpenEnvImgCreationModal] = useState(false);
  const handleModalOpen = () => setOpenEnvImgCreationModal(true);
  const handleModalClose = () => setOpenEnvImgCreationModal(false);

  const [envImgName, setEnvImageName] = useState("")
  const [envImgStatus, setEnvImgStatus] = useState("")

  const [hdrFile, setHdrFile] = useState()
  const [exrFile, setExrFile] = useState()

  const {mutate: hdrFileUploadMutate, status: hdrFileUploadStatus} = useUploadFile()
  const {mutate: exrFileUploadMutate, status: exrFileUploadStatus} = useUploadFile()

  const {mutate: createEnvImgMutate, status: createEnvImgStatus, data: createEnvImgData} = useCreateEnvironmentImage()

  const handleCreateEnvImg = () => {
    const envImg: EnvironmentImageUploadData = {
        environment_name: envImgName,
        environment_status: envImgStatus
    }
    handleModalOpen()
    createEnvImgMutate(envImg)
  }

  useEffect(() => {
    if (createEnvImgData?.data.data[0].hdr_file[0].pre_signed_url) {
        if(hdrFile){
            const hdrFileUpladData: FileUploadData = {
                file: hdrFile[0],
                url: createEnvImgData?.data.data[0].hdr_file[0].pre_signed_url,
                content_type: "application/octet-stream"
            }
            hdrFileUploadMutate(hdrFileUpladData)
        }
    }
    if (createEnvImgData?.data.data[0].exr_file[0].pre_signed_url) {
        if (exrFile) {
            const exrFileUploadData: FileUploadData = {
                file: exrFile[0],
                url: createEnvImgData?.data.data[0].exr_file[0].pre_signed_url,
                content_type: "application/octet-stream"
            }
            exrFileUploadMutate(exrFileUploadData)
        }
    }
  }, [createEnvImgData])
  
  return (
    <>
    <Header header='CREATE ENVIRONMENT IMAGE'/>
    <Grid container>
        <Grid item xs={0} md={1.5}></Grid>
        <Grid item container xs={12} md={9} direction={"column"} spacing={3}>
            <Grid item container direction={"column"} spacing={2}>
                <Grid item>
                    <Typography variant="customH3"> Environment Image Name </Typography>
                </Grid>
                <Grid item>
                <TextField value={envImgName} onChange={(e) => setEnvImageName(e.target.value)}
                                   sx={{"& .MuiInputBase-root": {height: '40px', width: '417px'}}}/>
                </Grid>
            </Grid>

            <Grid item container direction={"column"} spacing={2}>
                <Grid item>
                    <Typography variant="customH3"> Environment Image Status </Typography>
                </Grid>
                <Grid item width={"433px"}>
                    <Select options={model_status} onChange={(selected) => {
                                setEnvImgStatus(selected?.value!)
                            }}/>
                    {/* <Select>
                        {model_status?.map((status, index) => (
                            <MenuItem key={index} value={status.value}>{status.label}</MenuItem>
                        ))}
                    </Select> */}
                </Grid>
            </Grid>

            <Grid item container direction={"column"} spacing={2}>
                <Grid item>
                    <Typography variant="customH3"> Upload File </Typography>
                </Grid>
                <Grid item container spacing={3}>
                    <Grid item>
                        <Stack>
                            <Stack>
                                <Typography variant='customBodyStrong'> HDR File </Typography>
                                <Typography variant='customBodySm'> Supported File Type: .hdr</Typography>
                            </Stack>
                                <CustomDropZone setFile={setHdrFile}
                                    fileName={"HDR File"}
                                    isMultiple={false}
                                    accept={{ 'application/octet-stream': ['.hdr'] }}
                                    maxFiles={1} isDisabled={false}/>
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack>
                            <Stack>
                                <Typography variant='customBodyStrong'> EXR File </Typography>
                                <Typography variant='customBodySm'> Supported File Type: .exr</Typography>
                            </Stack>
                                <CustomDropZone setFile={setExrFile}
                                    fileName={"HDR File"}
                                    isMultiple={false}
                                    accept={{ 'application/octet-stream': ['.exr'] }}
                                    maxFiles={1} isDisabled={false}/>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item container direction={"column"}>
                <Grid item>
                    <CustomLightButton onClick={handleCreateEnvImg}>Create</CustomLightButton>
                </Grid>
            </Grid>
        </Grid>
        <Grid item xs={0} md={1.5}></Grid>
        <Dialog open={openEnvImgCreationModal} onClose={handleModalClose}>
            <Grid container justifyContent={"center"} direction={"column"} p={3} spacing={2}>
                <Grid item container justifyContent={"center"} alignItems={"center"}>
                    {createEnvImgStatus === "loading" ?
                        (<>
                            <Typography variant='customBody'>Creating {envImgName}</Typography>
                            <CircularProgress color='secondary'/>
                        </>) : createEnvImgStatus === "error" ? (<>
                            <Typography variant='customBody'>could not create {envImgName}</Typography>
                            <ErrorOutlineOutlinedIcon color='error' fontSize='large'/>
                        </>) : (<>
                            <Typography variant='customBody'>{envImgName} creation successful</Typography>
                            <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large'/>
                        </>)}
                </Grid>
                {hdrFile ? (
                    <Grid item container justifyContent={"center"} alignItems={"center"}>
                    {hdrFileUploadStatus === "loading" ?
                        (<>
                            <Typography variant='customBody'>uploading hdr file</Typography>
                            <CircularProgress color='secondary'/>
                        </>) : hdrFileUploadStatus === "error" ? (<>
                            <Typography variant='customBody'>could not upload hdr file</Typography>
                            <ErrorOutlineOutlinedIcon color='error' fontSize='large'/>
                        </>) : hdrFileUploadStatus === "success" ? (<>
                                <Typography variant='customBody'>hdr file uploaded successfully</Typography>
                                <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large'/>
                            </>) :
                            (<>
                                <Typography variant="customBody" mr={2}>waiting for hdr file upload...</Typography>
                            </>)}
                </Grid>
                ) : null}
                {exrFile ? (
                    <Grid item container justifyContent={"center"} alignItems={"center"}>
                    {exrFileUploadStatus === "loading" ?
                        (<>
                            <Typography variant='customBody'>uploading exr file</Typography>
                            <CircularProgress color='secondary'/>
                        </>) : exrFileUploadStatus === "error" ? (<>
                            <Typography variant='customBody'>could not upload exr file</Typography>
                            <ErrorOutlineOutlinedIcon color='error' fontSize='large'/>
                        </>) : exrFileUploadStatus === "success" ? (<>
                                <Typography variant='customBody'>exr file uploaded successfully</Typography>
                                <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large'/>
                            </>) :
                            (<>
                                <Typography variant="customBody" mr={2}>waiting for exr file upload...</Typography>
                            </>)}
                </Grid>
                ) : null}
            </Grid>
        </Dialog>
    </Grid>
    </>
  )
}

export default CreateEnvironmentImage