import { Grid, Typography, TextField, Stack, Autocomplete, Chip, Switch } from '@mui/material'
import React from 'react'
import { categories, category, model_status } from '../../shared/interfaces/Enums'
import CustomDropZone from '../CustomDropZone/CustomDropZone'
import ColumnWithTitle from '../Widgets/ColumnWithTitle/ColumnWithTitle'
import { BaseModelProps } from './BaseModelTab.config'

const BaseModelTab = (props: BaseModelProps) => {

    const handleTagsChange = (e: any, newValue: any) => {
        props.setTags(newValue)
    }

    const handleIsStickyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.setIsSticky(event.target.checked);
    };

    return (
        <>
            <ColumnWithTitle title='Model name'>
                <TextField
                    value={props.modelName}
                    size='small'
                    onChange={(e) => props.setModelName(e.target.value)}
                    fullWidth
                />
            </ColumnWithTitle>

            <ColumnWithTitle title='Category'>
                <Autocomplete
                    size="small"
                    disableClearable={true}
                    onChange={(e, selected) => {
                        props.setSelectedCategory(selected)
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    options={category}
                />
            </ColumnWithTitle>

            <ColumnWithTitle title='Tags'>
                <Autocomplete
                    size='small'
                    multiple
                    id="tags-filled"
                    options={categories}
                    value={[...props.tags]}
                    onChange={handleTagsChange}
                    freeSolo
                    renderTags={(value: readonly string[], getTagProps) =>
                        value?.map((option: string, index: number) => (
                            <Chip label={option} {...getTagProps({ index })} />
                        ))
                    }
                    renderInput={(params) => (
                        <TextField {...params} />
                    )}
                />
            </ColumnWithTitle>

            <ColumnWithTitle title='Sticky'>
                <Switch
                    color="secondary"
                    checked={props.isSticky}
                    onChange={handleIsStickyChange}
                />
            </ColumnWithTitle>

            <ColumnWithTitle title='Upload files'>
                <Grid item container spacing={5}>
                    <Grid item>
                        <Stack>
                            <Stack pb={1.5}>
                                <Typography variant='customBodyStrong'> Photogrammetry file </Typography>
                                <Typography variant='customBodySm'> Supported File Type: .fbx</Typography>
                            </Stack>
                            <CustomDropZone
                                setFile={props.setPhotogrammetryFile}
                                fileName={"Photogrammetry Model"}
                                isMultiple={true}
                                accept={{ 'application/octet-stream': ['.fbx'] }}
                                maxFiles={10}
                                isDisabled={false}
                            />
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack>
                            <Stack pb={1.5}>
                                <Typography variant='customBodyStrong'> CG file </Typography>
                                <Typography variant='customBodySm'> Supported File Type: .blend</Typography>
                            </Stack>
                            <CustomDropZone
                                setFile={props.setCompletedCGModelFile}
                                fileName={"CG Model"}
                                isMultiple={true}
                                accept={{ 'application/octet-stream': ['.blend'] }}
                                maxFiles={10}
                                isDisabled={false}
                            />
                        </Stack>
                    </Grid>
                    <Grid item>
                        <Stack>
                            <Stack pb={1.5}>
                                <Typography variant='customBodyStrong'> Reference Images </Typography>
                                <Typography variant='customBodySm'> Supported File Type: .jpeg,</Typography>
                            </Stack>
                            <CustomDropZone
                                setFile={props.setReferenceImages}
                                fileName={"Reference Images"}
                                isMultiple={true}
                                accept={{ 'image/jpeg': ['.jpeg', '.jpg'] }}
                                maxFiles={10}
                                isDisabled={false}
                            />
                        </Stack>
                    </Grid>
                    {/* <Grid item>
                      <Stack>
                      <Stack pb={1.5}>
                          <Typography variant='customBodyStrong'> Substance Painter </Typography>
                          <Typography variant='customBodySm'> Supported File Type: .spp</Typography>
                        </Stack>
                        <CustomDropZone
                          setFile={props.setCompletedCGModelFile}
                          fileName={"Substance Painter"}
                          isMultiple={true}
                          accept={{'application/octet-stream': ['.spp']}}
                          maxFiles={10}
                        />
                      </Stack>
                      </Grid> */}
                    <Grid item>
                        <Stack>
                            <Stack pb={1.5}>
                                <Typography variant='customBodyStrong'> Source Textures </Typography>
                                <Typography variant='customBodySm'> Supported File Type: .png</Typography>
                            </Stack>
                            <CustomDropZone
                                setFile={props.setSourceMaskTextureFile}
                                fileName={"Source Textures"}
                                isMultiple={true}
                                accept={{ 'image/png': ['.png'] }}
                                maxFiles={10}
                                isDisabled={false}
                            />
                        </Stack>
                    </Grid>

                </Grid>
            </ColumnWithTitle>

            <ColumnWithTitle title='Sticky'>
                <Autocomplete size="small" disableClearable={true} onChange={(e, selected) => {
                    props.setSelectedModelStatus(selected?.value!)
                }} renderInput={(params) => <TextField {...params} />} options={model_status} />
            </ColumnWithTitle>
        </>
    );
}

export default BaseModelTab