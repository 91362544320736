import { CircularProgress, Dialog, Grid, Typography } from '@mui/material'
import React from 'react'
import { Props } from './UpdateModal.config'
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import styles from "./UpdateModal.module.css"
import CustomLightButton from '../../CustomButton/CustomButton';

const UpdateModal = ({openUpdateModal, setOpenUpdateModal, updateStatus}: Props) => {

    const handleClose = () => {
        setOpenUpdateModal(false);
    }
  return (
    <Dialog fullWidth open={openUpdateModal} onClose={handleClose}>
        <Grid container direction={"column"} p={3} spacing={2} alignItems={"center"}>
            <Grid item>
                {updateStatus === "loading" ? <CircularProgress color='success' className={styles.progress}/> :
                updateStatus === "error" ? <ErrorOutlineOutlinedIcon color="error" sx={{fontSize:75}}/> :
                updateStatus === "success" ? <CheckCircleOutlineOutlinedIcon color='success' sx={{fontSize:75}}/> : null}
            </Grid>
            <Grid item>
                {updateStatus === "loading" ? <Typography variant='customH3'>Updating Model</Typography> :
                updateStatus === "error" ? <Typography variant='customH3'>Error on Updating Model</Typography> :
                updateStatus === "success" ? <Typography variant='customH3'>Model Updated</Typography> : null}
            </Grid>
            <Grid item>
                <CustomLightButton onClick={handleClose}>OK</CustomLightButton>
            </Grid>
        </Grid>
    </Dialog>
  )
}

export default UpdateModal