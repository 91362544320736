import { Box, Grid, Button, Typography, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styles from "./SortVariantModels.module.css"
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import { useGetVariantModelListByBaseModelId, useUpdateVarianteModel } from '../../shared/query-hooks/VariantModelService';
import { useParams } from 'react-router-dom';
import { VariantModelListData, VariantModelUploadData } from '../../shared/interfaces/VariantModel';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import VerifiedIcon from '@mui/icons-material/Verified';
import CustomLightButton from '../CustomButton/CustomButton';
import { Info } from '@mui/icons-material';

const SortVariantModels = () => {

  let params = useParams();
  const [testArray, setTestArray] = useState([{id:"warrior", name:"Warrior"},{id:"mage", name:"Mage"},{id:"priest", name:"Priest"},{id:"rogue", name:"Rogue"}]);
  const {data, status} = useGetVariantModelListByBaseModelId(params.baseModelId!);
  const [sortedVariants, setSortedVariants] = useState<VariantModelListData[]>([]);
  const {
        mutate: updateVariantModelMutate,
        status: updateVariantModelStatus,
        data: updateVariantModelResponseData
    } = useUpdateVarianteModel();

  useEffect(() => {
    if (status === "success") {
        setSortedVariants(data.data ? data.data.sort((a, b) => {
            return a.order - b.order;
        }) : []);
    }
  }, [status])
  
  const saveOrder = () => {
    //post sorted variants to backend here
    console.log("unsorted variants: ", data?.data)
    //console.log("sorted variants: ", sortedVariants);
    sortedVariants?.map((variant, index) => {
        let updateVariantModelData: VariantModelUploadData = {
            base_model_id: variant.base_model_detail[0]._id,
            _id: variant._id,
            order: index + 1,
        }
        updateVariantModelMutate(updateVariantModelData);
        console.log(variant._id + " saved with order: " + index);
        
    })
    console.log("sorted variants: ", sortedVariants);
  }

  const onDragEnd = (result: DropResult) => {

    if (!result.destination) {
        return;
    }

    if (result.destination.droppableId === result.source.droppableId && result.destination.index === result.source.index) {
        return;
    }
    console.log(result);

    const newArray = Array.from(sortedVariants);
    const movedVariant = newArray[result.source.index];
    newArray.splice(result.source.index, 1);
    newArray.splice(result.destination.index, 0, movedVariant);

    setSortedVariants(newArray);
  }
  
  return (
    <DragDropContext 
            onDragEnd={onDragEnd}>
            
    <Grid2 container direction={"column"}>
        <Grid2 container alignItems={"center"} my={2} spacing={1}>
            <Grid2>
                <Typography variant='customH3'>Variant Model Order</Typography>
            </Grid2>
            <Grid2>
                <Tooltip title={'Variants can be ordered with dragging here. The first variant will be considered as base variant. Click on SAVE ORDER button to apply the new order. '}>
                    <Info fontSize='small'/>
                </Tooltip>
            </Grid2>
        </Grid2>
        <Grid2>
            <div className={styles.container}>
                <Droppable droppableId={"variant"}>
                    {provided => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                        {sortedVariants?.map((item, index) => (
                            <Draggable draggableId={item._id} index={index} key={item._id}>
                                {(provided) => (
                                    <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef} className={styles.item} key={item._id}>
                                        <Grid2 container alignItems={"center"} spacing={1} px={1}>
                                            <Grid2>
                                                <img width={75} height={"auto"} src={item.thumbnail_file[0].url}></img>
                                            </Grid2>
                                            <Grid2>
                                                <Typography variant='customBodyCondensed'>{item.variant_model_name}</Typography>
                                            </Grid2>
                                            <Grid2 justifyContent={"flex-end"} flexGrow={1} display={index === 0 ? "flex" : "none"}>
                                                <VerifiedIcon color='secondary'/>
                                                <Typography color='secondary'>Base Model</Typography>
                                            </Grid2>
                                        </Grid2>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </Grid2>
        <Grid2 my={2}>
            <CustomLightButton disableRipple onClick={saveOrder}>save order</CustomLightButton>
        </Grid2>
    </Grid2>
    </DragDropContext> 
  )
}

export default SortVariantModels