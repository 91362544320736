import { Grid, Typography } from "@mui/material"
import { Props } from './ColumnWithTitle.config'

const ColumnWithTitle = ({ title, children }: Props) => {
    return (
        <Grid item container direction={'column'} spacing={'16px'}>
            <Grid item>
                <Typography variant='customH3'>{title}</Typography>
            </Grid>
            <Grid item width={'417px'}>
                {children}
            </Grid>
        </Grid>
    )
}

export default ColumnWithTitle