import {Box, CircularProgress, Dialog, Grid, IconButton, Modal, TextField, Typography} from '@mui/material'
import React, {useEffect, useState} from 'react'
import CustomLightButton from '../components/CustomButton/CustomButton'
import CustomCheckBox from '../components/CustomCheckBox/CustomCheckBox'
import Header from '../components/Header/Header';
import {category, model_status, Option} from '../shared/interfaces/Enums';
import Select, {OnChangeValue} from 'react-select';
import CreatableSelect from 'react-select/creatable';
import {useCreateBrand} from '../shared/query-hooks/BrandService';
import {BrandData} from '../shared/interfaces/Brand';
import {useUploadFile} from '../shared/query-hooks/FileUploadService';
import {FileUploadData} from '../shared/interfaces/FileModel';
import {useGetUserList} from '../shared/query-hooks/UserService';
import CustomDropZone from '../components/CustomDropZone/CustomDropZone';
import ThumbnailImgDropZone from '../components/ThumbnailImgDropZone/ThumbnailImgDropZone';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

const userData: Option[] = []

const CreateBrand = () => {

    const [openBrandCreationModal, setOpenBrandCreationModal] = useState(false);
    const handleModalOpen = () => setOpenBrandCreationModal(true);
    const handleModalClose = () => setOpenBrandCreationModal(false);

    const [brandName, setBrandName] = useState("");
    const [selectedBrandStatus, setSelectedBrandStatus] = useState("");
    const [tags, setTags] = useState<string[]>([]);
    const [userAccess, setUserAccess] = useState<string[]>([]);
    const [brandCreateIndicator, setBrandCreateIndicator] = useState(false);

    const [thumbnailFile, setThumbnailFile] = useState();

    const {data: userListData, isFetched: userListIsFetched} = useGetUserList();

    useEffect(() => {
        userListData?.data?.map((user, index) => {
            userData[index] = {label: user.username, value: user._id};
        })
    }, [userListIsFetched])


    const {
        mutate: thumbnailFileUploadMutate,
        isLoading: thumbnailFileUploadIsLoading,
        isSuccess: thumbnailFileUploadIsSuccess,
        isError: thumbnailFileUploadIsError,
        status: thumbnailFileUploadStatus
    } = useUploadFile();

    const handleTagsChange = (newValue: OnChangeValue<Option, true>) => {
        const tempArray: string[] = newValue.map(value => value.value)
        setTags(tempArray)
    }

    const handleUserAccessChange = (newValue: OnChangeValue<Option, true>) => {
        const tempArray: string[] = newValue.map(value => value.value)
        setUserAccess(tempArray)
    }

    const {
        mutate: createBrandMutate,
        data: brandResponseData,
        isSuccess: brandCreationIsSuccess,
        isLoading: brandCreationIsLoading,
        isError: brandCreationIsError,
        status: brandCreationStatus
    } = useCreateBrand();

    useEffect(() => {
        if (brandResponseData?.data.data[0].thumbnail_file[0].pre_signed_url) {
            console.log(brandResponseData?.data.data[0].thumbnail_file[0].pre_signed_url);
            if (thumbnailFile) {
                const thumbnailUploadData: FileUploadData = {
                    file: thumbnailFile[0],
                    url: brandResponseData?.data.data[0].thumbnail_file[0].pre_signed_url,
                    content_type: "image/png"
                }
                thumbnailFileUploadMutate(thumbnailUploadData)
            }
        }
    }, [brandResponseData])

    useEffect(() => {
        if (thumbnailFileUploadIsSuccess) {
            setTimeout(() => {
                handleModalClose();
            }, 2000);
        }
    }, [thumbnailFileUploadIsSuccess])


    const handleCreateBrand = () => {
        const brandData: BrandData = {
            _id: '',
            brand_name: brandName,
            brand_slug: '',
            brand_status: selectedBrandStatus,
            creator_id: '',
            tags: tags,
            thumbnail_file: [],
            authorized_users: userAccess,
            created_at: '',
            updated_at: '',
            available_model_count: 0
        }
        createBrandMutate(brandData);
        handleModalOpen();
        setBrandCreateIndicator(!brandCreateIndicator);

        console.log("brand creation")
    }
    return (
        <>
            <Header header='CREATE A BRAND'/>
            <Grid container>
                <Grid item xs={1.5}></Grid>
                <Grid container item xs={9} direction={'column'} marginTop={'25px'} spacing={4}>
                    <Grid item container direction={"column"}>
                        <Typography variant="customH3" mb={2}> Brand Logo </Typography>
                        {/* <Typography variant="customBody"> 3D Model used for this variant </Typography> */}
                        <Grid item>
                            <CustomDropZone setFile={setThumbnailFile}
                                            fileName={"Thumbnail"}
                                            isMultiple={true}
                                            accept={{'image/png': ['.png']}}
                                            maxFiles={10}
                                            isDisabled={false}
                            />
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Typography variant="customH3" mb={2}> Name </Typography>
                        <TextField value={brandName} onChange={(e) => setBrandName(e.target.value)}
                                   sx={{"& .MuiInputBase-root": {height: '40px', width: '417px'}}}/>
                    </Grid>

                    <Grid item container direction={"column"}>
                        <Grid item>
                            <Typography variant="customH3" mb={2}> Status </Typography>
                        </Grid>
                        <Grid item width={"417px"}>
                            <Select options={model_status} onChange={(selected) => {
                                setSelectedBrandStatus(selected?.value!)
                            }}/>
                        </Grid>
                        {/* <CustomCheckBox text='ARCHIVE' /> */}
                    </Grid>

                    <Grid item sx={{width: '417px'}}>
                        <Typography variant="customH3" mb={2}> Tags </Typography>
                        <Grid item width={'417px'}>
                            <CreatableSelect
                                isMulti
                                options={category}
                                onChange={handleTagsChange}
                            />
                        </Grid>
                    </Grid>

                    <Grid item container direction={"column"}>
                        <Typography variant="customH3" mb={0.5}> Users Access </Typography>
                        <Typography variant="customBodySm" mb={2}> Type users who can have access to this brands items
                            and click on enter to validate </Typography>
                        <Grid item width={"417px"}>
                            <Select isMulti name="users" options={userData} onChange={handleUserAccessChange}/>
                        </Grid>
                    </Grid>

                    <Grid item container direction='row' spacing='16px' mb={2}>
                        <Grid item> <CustomLightButton onClick={handleCreateBrand}> <Typography
                            variant='customBodyStrong'> CREATE </Typography> </CustomLightButton> </Grid>
                        <Grid item> <CustomLightButton disabled> <Typography
                            variant='customBodyStrong'> CANCEL </Typography> </CustomLightButton> </Grid>
                    </Grid>
                    <Grid item container direction='column' mb={10}>
                        {/* {brandCreateIndicator ? (brandCreationStatus === "loading" ?
                (<Typography>loading</Typography>) : brandCreationStatus === "error" ? (<Typography>error</Typography>) : (<Typography>success</Typography>)
                ) : null} */}
                    </Grid>
                </Grid>
                <Grid item xs={1.5}></Grid>

            </Grid>
            <Dialog open={openBrandCreationModal} onClose={handleModalClose}>
                <Grid container justifyContent={"center"} direction={"column"} p={3} spacing={2}>
                    <Grid item container justifyContent={"center"} alignItems={"center"}>
                        {brandCreationStatus === "loading" ?
                            (<>
                                <Typography variant='customBody'>Creating {brandName}</Typography>
                                <CircularProgress color='secondary'/>
                            </>) : brandCreationStatus === "error" ? (<>
                                <Typography variant='customBody'>could not create {brandName}</Typography>
                                <ErrorOutlineOutlinedIcon color='error' fontSize='large'/>
                            </>) : (<>
                                <Typography variant='customBody'>{brandName} creation successful</Typography>
                                <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large'/>
                            </>)}
                    </Grid>
                    {thumbnailFile ? (
                        <Grid item container justifyContent={"center"} alignItems={"center"}>
                        {thumbnailFileUploadStatus === "loading" ?
                            (<>
                                <Typography variant='customBody'>uploading thumbnail image</Typography>
                                <CircularProgress color='secondary'/>
                            </>) : thumbnailFileUploadStatus === "error" ? (<>
                                <Typography variant='customBody'>could not upload thumbnail image</Typography>
                                <ErrorOutlineOutlinedIcon color='error' fontSize='large'/>
                            </>) : thumbnailFileUploadStatus === "success" ? (<>
                                    <Typography variant='customBody'>thumbnail image uploaded successfully</Typography>
                                    <CheckCircleOutlineOutlinedIcon color='secondary' fontSize='large'/>
                                </>) :
                                (<>
                                    <Typography variant="customBody" mr={2}>waiting for thumbnail image
                                        upload...</Typography>
                                </>)}
                    </Grid>
                    ) : null}
                </Grid>
            </Dialog>
        </>
    )
}

export default CreateBrand;
