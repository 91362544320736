export interface Option {
    readonly value: string,
    readonly label: string,
}

export interface ContentType {
    readonly value: string,
}

export const category: readonly Option[] =  [
    {value: "SHOES", label: "Shoes"},
    {value: "EYEWEAR", label: "Eyewear"},
    {value: "HATS", label: "Hats"},
    {value: "WATCHES", label: "Watches"},
    {value: "OTHER", label: "Other"},
]

export const model_status: readonly Option[] = [
    {value: "ACTIVE", label: "Active"},
    {value: "PASSIVE", label: "Passive"},
]

export const categories: string[] = [
    "Shoes",
    "Eyewear",
    "Watches",
    "Hats",
    "Other"
]

export interface NewOption {
    value: string,
    label: string,
}