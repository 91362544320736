import React from 'react'
import {Box, Grid, Modal, Typography} from "@mui/material";
import CustomLightButton from "../../CustomButton/CustomButton";
import {searchModel, getModel} from '../../../shared/query-hooks/MOPUService';
import AsyncSelect from 'react-select/async';
import {Option} from "../../../shared/interfaces/Enums";
import {SingleValue} from "react-select";
import { categoryChanger } from '../../../shared/utils/CategoryChanger';
import { ImportFromMopuProps } from './ImportFromMopuModal.config';

const filterModels = (inputValue: string) => {
    return searchModel(inputValue).then(models => {
        return models.data?.map((model: any) => {
            return {
                value: model._id,
                label: model.model_name
            }
        });
    })
};

const MOPUOptions = (inputValue: string) => {
    return filterModels(inputValue)
}

const ImportFromMopuModal = (props: ImportFromMopuProps) => {

    const handleMopuModelChange = (selectedOption: SingleValue<Option>) => {
        props.setSelectedCategory({value: selectedOption?.value, label: selectedOption?.label})
    }

    const handelImportFromMopu = () => {
        if (props.selectedCategory.value) {
            getModel(props.selectedCategory.value).then(model => {
                props.setVariantDropzoneDisabled(true)
                props.handleClose()
                props.setTabValue(1)
                props.setIsModelUploadedFromMopu(true)

                const modelData = model.data[0]
                if(modelData.poster_file){
                    props.setPosterDropzoneDisabled(true)
                }
                props.setModelData({
                    _id: modelData._id,
                    model_name: modelData.model_name,
                    model_slug: modelData.model_slug,
                    brand_id: modelData.brand_id,
                    category: modelData.category,
                    model_status: modelData.model_status,
                    tags: modelData.tags,
                    model_viewer_config: modelData.model_viewer_config,
                    reference_link: modelData.reference_link,
                    reference_file: modelData.reference_file,
                    glb_file: modelData.glb_file,
                    hdr_file: modelData.hdr_file,
                    usdz_file: modelData.usdz_file,
                    poster_file: modelData.poster_file ? modelData.poster_file : null
                })
                props.setModelName(modelData.model_name)
                //props.setSelectedCategory(modelData.category)
                const new_category = categoryChanger(modelData.category)
                props.setSelectedCategory({
                    value: new_category,
                    label: new_category.charAt(0) + new_category.slice(1).toLowerCase()
                })
                props.setProductLinks([{name: modelData.model_name.split("_")[0], url: modelData.reference_link}])
                props.setModelViewerConfig([modelData.model_viewer_config])
                props.setImportMopuSwitch(!props.importMopuSwitch)
            })
        }
    }

    return (
        <Modal
            open={props.isOpen}
        >
            <Box style={{
                position: 'absolute' as 'absolute',
                top: '50%', left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '768px', height: '800px',
                backgroundColor: '#FFFFFF'
            }}>
                <Grid container>
                    <Grid item xs={1}/>

                    <Grid item container xs={10} direction={'column'} marginTop={'50px'} spacing='20px'>
                        <Grid item>
                            <Typography variant="customH3"> IMPORT FROM MOPU </Typography>
                        </Grid>
                        <Grid item container direction='row'>
                            <Grid item width={'500px'}>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={MOPUOptions}
                                    onChange={handleMopuModelChange}
                                /> </Grid>
                        </Grid>

                        <Grid item container direction='row' spacing='16px'>
                            <Grid item>
                                <CustomLightButton onClick={handelImportFromMopu}>
                                    <Typography variant='customBodyStrong'> IMPORT </Typography>
                                </CustomLightButton>
                            </Grid>
                            <Grid item>
                                <CustomLightButton onClick={() => {
                                    props.handleClose()
                                }}>
                                    <Typography variant='customBodyStrong'> CANCEL </Typography>
                                </CustomLightButton>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={1}/>
                </Grid>

            </Box>
        </Modal>
    )
}

export default ImportFromMopuModal