import React, { useEffect, useState } from 'react'
import { Box, Typography, Grid, Button } from '@mui/material';
import CustomLightButton from '../components/CustomButton/CustomButton';
import Header from '../components/Header/Header';
import CustomChip from '../components/CustomChip';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetBaseModelById } from '../shared/query-hooks/ModelService';
import SortVariantModels from '../components/SortVariantModels/SortVariantModels';

function ViewBaseModel(){
  let navigate = useNavigate();

  let params = useParams();
  
  const {data: baseModel, isFetched: baseModelIsFetched, status: baseModelStatus} = useGetBaseModelById(params.baseModelId!);

  const [baseModelId, setBaseModelId] = useState("")
  const [baseModelName, setBaseModelName] = useState("")
  const [brandId, setBrandId] = useState("")
  const [brandName, setBrandName] = useState("")
  const [updateDate, setUpdateDate] = useState("")
  const [thumbnailFile, setThumbnailFile] = useState("")
  const [tags, setTags] = useState([""])
  const [modelStatus, setModelStatus] = useState("")

  useEffect(() => {
    if (baseModelStatus === "success") {
      setBaseModelId(baseModel.data[0]._id)
      setBaseModelName(baseModel.data[0].model_name)
      setBrandId(baseModel.data[0].brand_detail[0]._id)
      setBrandName(baseModel.data[0].brand_detail[0].brand_name)
      setUpdateDate(baseModel.data[0].updated_at)
      setThumbnailFile(baseModel.data[0].thumbnail_file[0].url)
      setTags(baseModel.data[0].tags)
      setModelStatus(baseModel.data[0].model_status)
    }
  }, [baseModelStatus])

  const handleEditItemClick = () => {
    navigate(`/base-models/${params.baseModelId}/edit`)
  }

  return (
    <>
      <Header header={ brandName } itemModel= { baseModelName } status={ modelStatus } />

      <Grid container marginTop={'84px'} marginBottom={'75px'}>
        <Grid item xs={1.5} />

        <Grid item container direction={'column'} xs={9} spacing={'128px'}>
          <Grid item container direction={'row'}>
            <Grid item container xs={8.8} direction={'column'} spacing={'24px'}>
              <Grid item container justifyContent={'center'}>
                <img width={300} height={"auto"} src={thumbnailFile}/>
                </Grid>
              {/* <Grid item container direction={'row'} spacing={'8px'}>
                <Grid item> <CustomLightButton> SHARE </CustomLightButton> </Grid>
                <Grid item> <CustomLightButton> EMBED </CustomLightButton> </Grid>
                <Grid item> <CustomLightButton> QR CODE </CustomLightButton> </Grid>
              </Grid> */}
              {/* <Grid item> <Typography variant='customBody'> Updated { updateDate } </Typography> </Grid> */}
              <Grid item container spacing={'8px'}>
                {
                  tags.map(
                    (tag) => ( <Grid item key={tag}> <CustomChip title={tag} color='#FFFFFF' bgColor='#000000' isFilled={true} /> </Grid> )
                  )
                }
              </Grid>
              {/* <Grid item> <CustomLightButton> DOWNLOAD ALL RESOURCES </CustomLightButton> </Grid> */}
              <Grid item>
                <SortVariantModels/>
              </Grid>
            </Grid>

            <Grid item xs={1} />

            <Grid item container xs={2.2} direction={'column'} spacing={'24px'}>
              <Grid item> <CustomLightButton fullWidth onClick={ handleEditItemClick }> EDIT BASE MODEL </CustomLightButton> </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={1.5} />
      </Grid>
    </>
  )
}

export default ViewBaseModel;
