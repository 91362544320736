import { Box, Grid, Typography } from '@mui/material'
import CustomChip from '../CustomChip'
import { Link } from 'react-router-dom';
import LazyLoad from "react-lazyload";

import styles from './CardContainer.module.scss'
import { Props } from './CardContainer.config'

const CardContainer = ({ link, thumbnail_url, children, customHeight }: Props) => {

    return (
        <Link to={link} className={styles.linkContainer}>
            <Box
                className={styles.CardContainer}
                sx={{
                    boxShadow: '0px 3px 4px rgba(0, 0, 0, 0.15), 0px 5px 6px rgba(0, 0, 0, 0.15)',
                    "&:hover": { boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.2), 0px 10px 12px rgba(0, 0, 0, 0.2)' },
                    "&:active": { boxShadow: '0px 0px 0px rgba(0, 0, 0, 0)' },
                    height: customHeight || "200px",
                }}>
                {/* <Typography>{props.baseModel?.model_name}</Typography> */}
                <Grid container direction={"column"} height={"100%"} sx={{ borderRadius: "5px" }} flexWrap='nowrap'>
                    <Grid item container justifyContent={"center"} alignItems={"center"} height={"50%"} maxHeight={'138px'} className={styles.imageContainer}>
                        {thumbnail_url ?
                            <LazyLoad height={250} once>
                                <object data={thumbnail_url} style={{ width: "100px", height: "100px" }}>
                                    <img src="../../qreal_logo.jpeg" style={{ width: "100px", height: "100px" }} />
                                </object>
                            </LazyLoad>
                            : null}
                    </Grid>
                    {children}
                </Grid>
            </Box>
        </Link>
    )
}

export default CardContainer;
