import { useEffect, useState } from 'react'
import "@google/model-viewer/dist/model-viewer";
//import { Category } from "../shared/utils/enums";

const CustomModelViewer = (props) => {

  let min_camera_orbit;
  let min_field_of_view;
  let max_field_of_view;

  const [style, setStyle] = useState({ zIndex: 10, width: "100%", height: "100%" });

  if (props.selectedVariantModel.category.toLowerCase() === "watches") {
    min_camera_orbit = "auto auto 9.5096m"
    min_field_of_view = "40deg"
    max_field_of_view = "40deg"
  } else if (props.selectedVariantModel.category.toLowerCase() === "shoes") {
    min_camera_orbit = "auto auto 0.6069m"
    min_field_of_view = "21.81deg"
    max_field_of_view = "auto"
  } else { // zoom level for eyewear and hats
    min_camera_orbit = 'auto auto 0.5206m'
    min_field_of_view = '23.95deg'
    max_field_of_view = "auto"
  }

  const modelViewerElement = customElements.get('model-viewer');
  modelViewerElement.modelCacheSize = 0;

  useEffect(() => {
    const modelViewer = document.querySelector('model-viewer');
    modelViewer.cameraOrbit = props.selectedVariantModel.category.toLowerCase() === "watches" ? "auto auto auto" : "43.62deg 71.51deg auto";
    const progress = document.querySelector('#progress');
    const bar = progress.querySelector('.bar');
    modelViewer.addEventListener('progress', (event) => {
      const { totalProgress } = event.detail;
      progress.classList.toggle('show', totalProgress < 1);
      bar.style.transform = `scaleX(${totalProgress})`;
      if (modelViewer.modelIsVisible) {
        modelViewer.cameraOrbit = props.selectedVariantModel.category.toLowerCase() === "watches" ? "auto auto auto" : "43.62deg 71.51deg auto";
        modelViewer.dismissPoster();
      } else {
        modelViewer.showPoster();
        modelViewer.cameraOrbit = props.selectedVariantModel.category.toLowerCase() === "watches" ? "auto auto auto" : "43.62deg 71.51deg auto";
      }
    });
  }, [props.selectedVariantModel])

  //console.log(props.selectedVariantModel.environment_detail[0].hdr_file[0].url)


  return (
    <model-viewer
      oncontextmenu="return false;"
      poster={props.selectedVariantModel.thumbnail_file[0].url}
      id="model-viewer"
      style={style}
      environment-image={props.hdr}
      src={`${props.selectedVariantModel.glb_file[0].url}`}
      shadow-intensity={props.shadowIntensity}
      shadow-softness={props.shadowSoftness}
      exposure={props.exposure}
      touch-action="none"
      camera-controls
      min-camera-orbit={min_camera_orbit}
      min-field-of-view={min_field_of_view}
      max-camera-orbit='auto auto 100%'
      max-field-of-view={max_field_of_view}
      disable-pan={true}
    >
      <div id="progress" slot="progress-bar">
        <div className="bar"></div>
      </div>
    </model-viewer>
  )
}

export default CustomModelViewer;