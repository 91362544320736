import { Box, Button, Modal, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import React from 'react'
import { Version } from '../../shared/interfaces/Version';
import VersionForm from './VersionForm';

interface CreateVersionModalProps {
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
const CreateVersionModal = (props: CreateVersionModalProps) => {
    const closeCreateModal = () => {
        props.setOpen(false);
      }
      const handleCreate = () => {
        console.log("version Created")
        closeCreateModal();
      }
      return (
       <Modal open={props.open} onClose={closeCreateModal}>
        <Box sx={style}>
            <Grid2 container direction={"column"}>
                <Grid2>
                    <Typography variant='customH2'>Create Version</Typography>
                </Grid2>
                <Grid2>
                    <VersionForm/>
                </Grid2>
            </Grid2>
        </Box>
       </Modal>
      )
}

export default CreateVersionModal