import { Grid, Stack, Typography } from '@mui/material';
import CustomChip from '../CustomChip'
import CardContainer from '../CardContainer/CardContainer';
import { Properties } from './ProductBox.config';
import styles from './ProductBox.module.scss';

function ProductBox(props: Properties) {
    return (
        <CardContainer link={`/variant-models/${props.model._id}`} thumbnail_url={props.model.thumbnail_file[0].url} customHeight={'280px'}>
            <Grid item container
                direction={"column"}
                className={styles.productBox}
            >
                <Grid item container direction={"column"} spacing={0.5}>
                    <Grid item>
                        <Typography noWrap variant="customBodyStrongCondensed">{props.model.brand_detail[0].brand_name}</Typography>
                    </Grid>
                    <Grid item>
                        <Stack direction={"row"} alignItems={"center"} spacing={1} whiteSpace={'normal'} className={styles.descriptionContainer}>
                            <Typography whiteSpace={'break-spaces'} variant="customBodyXsCondensed">{`${props.model.base_model_detail[0].model_name + '  ' + props.model.variant_model_name}`}</Typography>
                        </Stack>
                    </Grid>
                </Grid>

                <Grid item container direction='row' spacing={'8px'} pt={1}>
                    <Grid item>
                        <CustomChip title={props.model.category} color='#FFFFFF' bgColor='#000000' isFilled={true}></CustomChip>
                    </Grid>
                    <Grid item>
                        {
                            props.model?.variant_model_status &&
                            <CustomChip title={props.model?.variant_model_status} color='#000000' isFilled={false} status={props.model?.variant_model_status.toLowerCase() as "no-status" | "active" | "passive"} />
                        }
                    </Grid>
                </Grid>
            </Grid>
        </CardContainer>
    );
}

export default ProductBox;
