import { MenuItem, Select, TextField, Typography } from '@mui/material'
import Grid2 from '@mui/material/Unstable_Grid2/Grid2'
import { useEffect } from 'react'
import { useForm, Controller } from "react-hook-form";
import type {
    SubmitHandler,
    DefaultValues
  } from "react-hook-form";
import { Version } from '../../shared/interfaces/Version';
import CustomLightButton from '../CustomButton/CustomButton';

enum TypeEnum {
    major = "MAJOR",
    minor = "MINOR",
    none = "NONE"
}

enum StateEnum {
    app_review = "APP_REVIEW",
    rejected = "REJECTED",
    production = "PRODUCTION" 
}

export const defaultValues: DefaultValues<Version> = {
    build_number: undefined,
    description: "",
    state: "",
    type: "",
    version: "",
}

interface VersionFormProps{
    versionInfo?: Version
}

const VersionForm = (props: VersionFormProps) => {
    
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: {errors},
    setValue
  } = useForm<Version>({
    defaultValues
  })

  useEffect(() => {
    if (props.versionInfo) {
        setValue("build_number",props.versionInfo?.build_number!);
        setValue("description",props.versionInfo?.description!);
        setValue("state",props.versionInfo?.state!);
        setValue("type",props.versionInfo?.type!);
        setValue("version",props.versionInfo?.version!);
    }
  }, [props.versionInfo])
  
  const onSubmit: SubmitHandler<Version> = (data) =>
    alert(JSON.stringify(data));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
    <Grid2 container direction={"column"} p={2}>
        <Grid2 container direction={"column"} spacing={1}>
            <Grid2>
                <Typography variant='customH3'>Build Number</Typography>
            </Grid2>
            <Grid2>
                <Grid2 container direction={"column"}>
                    <Grid2>
                        <Controller
                            render={(({field}) => <TextField fullWidth {...field} size='small' type={"number"} error={errors.build_number ? true : false} />)}
                            name="build_number"
                            control={control}
                            rules={{required: true}}
                        />
                    </Grid2>
                    <Grid2>
                        {errors.build_number ? <Typography variant='customBodyXsCondensed' color={"error"}>build number cannot be empty</Typography> : ""}
                    </Grid2>
                </Grid2>
            </Grid2>
        </Grid2>
        <Grid2 container direction={"column"} spacing={1}>
            <Grid2>
                <Typography variant='customH3'>Version</Typography>
            </Grid2>
            <Grid2>
                <Grid2 container direction={"column"}>
                    <Grid2>
                        <Controller
                            render={(({field}) => <TextField fullWidth {...field} size='small' error={errors.version ? true : false} />)}
                            name="version"
                            control={control}
                            rules={{required: true}}
                        />
                    </Grid2>
                    <Grid2>
                        {errors.version ? <Typography variant='customBodyXsCondensed' color={"error"}>version cannot be empty</Typography> : ""}
                    </Grid2>
                </Grid2>
            </Grid2>
        </Grid2>
        <Grid2 container direction={"column"} spacing={1}>
            <Grid2>
                <Typography variant='customH3'>Type</Typography>
            </Grid2>
            <Grid2>
                <Grid2 container direction={"column"}>
                    <Grid2>
                        <Controller
                            render={({field}) => (
                                <Select fullWidth {...field} size="small" error={errors.type ? true : false}>
                                    <MenuItem value={TypeEnum.major}>{TypeEnum.major}</MenuItem>
                                    <MenuItem value={TypeEnum.minor}>{TypeEnum.minor}</MenuItem>
                                    <MenuItem value={TypeEnum.none}>{TypeEnum.none}</MenuItem>
                                </Select>
                            )}
                            name="type"
                            control={control}
                            rules={{required: true}}
                        />
                    </Grid2>
                    <Grid2>
                        {errors.type ? <Typography variant='customBodyXsCondensed' color={"error"}>type cannot be empty</Typography> : ""}
                    </Grid2>
                </Grid2>
            </Grid2>
        </Grid2>
        <Grid2 container direction={"column"} spacing={1}>
            <Grid2>
                <Typography variant='customH3'>Description</Typography>
            </Grid2>
            <Grid2>
                <Grid2 container direction={"column"}>
                    <Grid2>
                        <Controller
                            render={(({field}) => <TextField fullWidth error={errors.description ? true : false} {...field} size='small' />)}
                            name="description"
                            control={control}
                            rules={{required: true, minLength:60}}
                        />
                    </Grid2>
                    <Grid2>
                        {errors.description ? <Typography variant='customBodyXsCondensed' color={"error"}>Description should be at least 60 characters long</Typography> : ""}
                    </Grid2>
                </Grid2>
            </Grid2>
        </Grid2>
        <Grid2 container direction={"column"} spacing={1}>
            <Grid2>
                <Typography variant='customH3'>State</Typography>
            </Grid2>
            <Grid2>
                <Grid2 container direction={"column"}>
                    <Grid2>
                        <Controller
                            render={({field}) => (
                                <Select fullWidth {...field} size="small" error={errors.state ? true : false}>
                                    <MenuItem value={StateEnum.app_review}>{StateEnum.app_review}</MenuItem>
                                    <MenuItem value={StateEnum.production}>{StateEnum.production}</MenuItem>
                                    <MenuItem value={StateEnum.rejected}>{StateEnum.rejected}</MenuItem>
                                </Select>
                            )}
                            name="state"
                            control={control}
                            rules={{required: true}}
                        />
                    </Grid2>
                    <Grid2>
                        {errors.state ? <Typography variant='customBodyXsCondensed' color={"error"}>state cannot be empty</Typography> : ""}
                    </Grid2>
                </Grid2>
            </Grid2>
        </Grid2>
        <Grid2 container direction={"column"} spacing={1}>
            <Grid2>
                <CustomLightButton onClick={handleSubmit(onSubmit)}>SAVE</CustomLightButton>
            </Grid2>
        </Grid2>
    </Grid2>
    </form>
  )
}

export default VersionForm