import { Button} from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomLightButton = styled(Button) ({
    paddingLeft: '16px', paddingRight:'16px',
    paddingBottom: '5.5px', paddingTop:'5.5px',
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    color: "black",
    background:"white !important",
    '&:active': {
        boxShadow: "none",
    },
    '&:focus': {
    },
})

export default CustomLightButton;