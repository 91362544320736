import { AppStates } from "./app";

export const authKeys = {
    get_brand: "TRYO_BRAND_GET",
    create_brand: "TRYO_BRAND_CREATE",
    list_brand: "TRYO_BRAND_LIST",
    push_notification: "TRYO_NOTIFICATION_CREATE",
    edit_brand: "TRYO_BRAND_UPDATE",
    delete_brand: "TRYO_BRAND_DELETE",
    create_model: "TRYO_MODEL_CREATE",
    search_model: "TRYO_SEARCH",
    delete_model: "TRYO_MODEL_DELETE",
    edit_model: "TRYO_MODEL_UPDATE",
    list_model: "TRYO_MODEL_LIST",
    list_lens: "TRYO_LENS_LIST",
    delete_lens: "TRYO_VARIANT_MODEL_LENS_DELETE"
}

export const authRoles ={
    ADMIN: "ADMIN",
    EDITOR: "EDITOR",
    READ_ONLY: "READ_ONLY",
    CUSTOMER: "CUSTOMER"
}

export const checkAuth = (authList: Array<string>, key: string) => {
    if ((authList.length > 0 && authList.includes(key))
        || process.env.REACT_APP_APP_STATE === AppStates.DEV) {
        return true;
    }
    return false;
}
